import { ReactNode, useState } from "react";
import Dialogotrocarsenha from "../../../components/Dialogotrocarsenha";
import Dialogoalterardados from "../../../components/Dialogoalterardados";
import { mask } from "remask";
import Dialogonewuser from "../../../components/Dialogonewuser";

import { Container, Page, Card } from "./styles";

// icones
import { BsSortAlphaDown, BsSortAlphaUpAlt } from "react-icons/bs";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

// confirmacao
import Dialogoconfirmacao from "../../../components/Dialogoconfirmacao";
import { toast } from "react-toastify";
// render props
import { WithAuthenticatedUser } from "../../../components/WithAuthenticatedUser";

function Users(props) {
  const [showdialogpass, setShowdialogpass] = useState({
    open: false,
    dados: "",
  });
  const [newuser, setNewuser] = useState({
    open: false,
    edit: false,
    dados: "",
  });
  const [showaltdados, setShowaltdados] = useState(false);
  const [editaccessdata, setEditaccessdata] = useState(false);
  const [order, setOrder] = useState("");
  const [showconf, setShowconf] = useState({
    status: false,
    width: "sm",
    title: "Confirmar",
    description: "Deseja realmente suspender este usuário?",
    btn_conf: "Sim",
    btn_cancel: "Não",
    delete: true,
    dados: "",
  });

  const [filter, setFilter] = useState({
    type: "",
    value: "",
    dig: "",
  });
  const [listusers, setListusers] = useState([
    {
      id: 1,
      full_name: "Werlley Ponte",
      cpf: "033.455.855-65",
      email: "werlle@hotmail.com",
      role: "Colaborador",
      company_id: 1,
      telephone: "(88) 99452-6526",
    },
    {
      id: 2,
      full_name: "Alan Martins",
      cpf: "033.455.855-65",
      email: "werlle@hotmail.com",
      role: "Engenheiro",
      company_id: 1,
      telephone: "(88) 99452-6526",
    },
  ]);

  const handleFilter = (dado) => {
    if (filter.type === "full_name") {
      if (dado.full_name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
        return true;
    } else if (filter.type === "cpf") {
      if (
        dado.cpf
          .replace(/[^0-9]/g, "")
          .indexOf(filter.value.replace(/[^0-9]/g, "")) > -1
      )
        return true;
    } else if (filter.type === "cnpj") {
      if (
        dado?.cnpj
          .replace(/[^0-9]/g, "")
          .indexOf(filter.value.replace(/[^0-9]/g, "")) > -1
      )
        return true;
    } else if (filter.type === "suspended_projects") {
      if (dado.suspended) return true;
    } else if (filter.type === "state") {
      if (dado.state.toLowerCase().indexOf(filter.value.toLowerCase()) > -1)
        return true;
    } else if (filter.type === "delayed_projects") {
      if (dado.process >= dado.max_process && dado.suspended === false) {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleFilterValue = (event) => {
    if (filter.type === "cpf") {
      setFilter({
        ...filter,
        value: mask(event.target.value, "999.999.999-99"),
      });
    } else if (filter.type === "cnpj") {
      setFilter({
        ...filter,
        value: mask(event.target.value, "99.999.999/9999-99"),
      });
    } else {
      setFilter({ ...filter, value: event.target.value });
    }
  };

  const trocarSenha = (e) => {
    alert(`Sua nova senha é: ${e}`);
    setShowdialogpass({
      open: false,
      dados: "",
    });
  };

  let listusers2 = listusers.filter(handleFilter).sort((a, b) => {
    if (order === "AZ") {
      return a.full_name > b.full_name ? 1 : b.full_name > a.full_name ? -1 : 0;
    } else if (order === "ZA") {
      return a.full_name < b.full_name ? 1 : b.full_name < a.full_name ? -1 : 0;
    } else if (order === "primeiros") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (order === "ultimos") {
      return new Date(a.created_at) - new Date(b.created_at);
    }
  });

  return (
    <Container>
      <Page>
        <header>
          <h1>Usuários</h1>
          <span>
            Gerencie os usuários que terão acesso aos projetos de sua conta.
          </span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              <select
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    type: event.target.value,
                    value: "",
                    dig: "",
                  })
                }
                value={filter.type}
                style={{ maxWidth: 200 }}
              >
                <option value="" selected>
                  {" "}
                  Filtro{" "}
                </option>
                <option value="full_name">Nome</option>
                <option value="cpf">CPF</option>
              </select>

              {/* autocomplete */}

              <input
                placeholder={
                  filter.type === "full_name"
                    ? "Digite o nome"
                    : filter.type === "cpf"
                    ? "Digite o CPF"
                    : filter.type === "cnpj"
                    ? "Digite o CNPJ"
                    : " - "
                }
                value={filter.value}
                disabled={!filter.type ? true : false}
                onChange={handleFilterValue}
                style={{
                  display:
                    filter.type === "full_name" ||
                    filter.type === "cpf" ||
                    filter.type === "cnpj"
                      ? "block"
                      : "none",
                }}
              />

              <select
                required
                name="state"
                style={{
                  flex: 1,
                  display: filter.type === "state" ? "block" : "none",
                }}
                value={filter.value}
                onChange={handleFilterValue}
              >
                <option value="" disabled selected>
                  Escolha o estado
                </option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>

              {/* fim de autocomplete */}
            </div>
            <div className="div__order">
              <button
                className={order === "AZ" ? "btn__esq btn__ativ" : "btn__esq"}
                onClick={() => (order === "AZ" ? setOrder("") : setOrder("AZ"))}
              >
                <BsSortAlphaDown style={{ fontSize: "1.4rem" }} />
              </button>
              <button
                className={order === "ZA" ? "btn_meio btn__ativ" : "btn_meio"}
                onClick={() => (order === "ZA" ? setOrder("") : setOrder("ZA"))}
              >
                <BsSortAlphaUpAlt style={{ fontSize: "1.4rem" }} />
              </button>

              <button
                className={
                  order === "primeiros" ? "btn_meio btn__ativ" : "btn_meio"
                }
                onClick={() =>
                  order === "primeiros" ? setOrder("") : setOrder("primeiros")
                }
              >
                Recentes
              </button>
              <button
                className={
                  order === "ultimos" ? "btn__dir btn__ativ" : "btn__dir"
                }
                onClick={() =>
                  order === "ultimos" ? setOrder("") : setOrder("ultimos")
                }
              >
                Antigos
              </button>
            </div>
          </div>

          <div className="div__field_actions">
            <div className="div__fiel_filter"></div>
            <div className="conteudo">
              <button
                type="submit"
                className="btn__finish"
                onClick={() => setNewuser({ ...newuser, open: true })}
              >
                + Novo Usuário
              </button>
            </div>
          </div>

          <div className="div__deck">
            <div className="head_list">
              <div className="div__name" style={{ flex: 2 }}>
                Nome
              </div>
              <div className="div__email">CPF</div>
              <div className="div__email">E-mail</div>
              <div className="div__role">Cargo</div>
              <div className="div__password">Senha</div>
              <div className="div__options">Opções</div>
            </div>
            {listusers2.map((usuario, idx) => (
              <Card key={idx}>
                <div className="div__name" style={{ flex: 2 }}>
                  {usuario.full_name}
                </div>
                <div className="div__email">{usuario.cpf}</div>
                <div className="div__email">{usuario.email}</div>
                <div className="div__role">{usuario.role}</div>
                <div className="div__password">
                  (
                  <a
                    href="#password"
                    onClick={() =>
                      setShowdialogpass({ open: true, dados: usuario })
                    }
                  >
                    trocar senha
                  </a>
                  )
                </div>
                <div className="div__options">
                  {/* <IconButton>
                    <VisibilityIcon />
                  </IconButton> */}
                  <IconButton
                    onClick={() =>
                      setNewuser({
                        ...newuser,
                        open: true,
                        edit: true,
                        dados: usuario,
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    className="btn_delete"
                    onClick={() =>
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: usuario,
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Card>
            ))}

            {listusers2.length == 0 && filter.type ? (
              <div style={{ padding: "1rem" }}>Nenhum usuário encontrado</div>
            ) : listusers2.length > 0 ? (
              ""
            ) : (
              <div style={{ padding: "1rem" }}>Nenhum usuario cadastrado</div>
            )}
          </div>
          <br />
        </content>
      </Page>
      {showaltdados && (
        <Dialogoalterardados
          // dados={dados}
          fechar={() => setShowaltdados(false)}
        />
      )}
      {showdialogpass.open && (
        <Dialogotrocarsenha
          negar={() => setShowdialogpass({ open: false, dados: "" })}
          confirmar={(a) => trocarSenha(a)}
          dados={showdialogpass.dados}
        />
      )}

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: "sm",
              title: "Confirmar",
              description: "Deseja realmente suspender este projeto?",
              btn_conf: "Sim",
              btn_cancel: "Não",
              delete: true,
              dados: "",
            })
          }
          confirmar={() => {
            toast.success(
              `Usuário ${showconf.dados.name} deletado com sucesso`,
            );
          }}
        />
      )}
      {newuser.open && (
        <Dialogonewuser
          fechar={() => setNewuser({ open: false, edit: false, dados: "" })}
          edit={newuser.edit}
          dados={newuser.dados}
          editar={() => true}
        />
      )}
    </Container>
  );
}

function UsersLogged() {
  return <WithAuthenticatedUser render={() => <Users />} />;
}

export default UsersLogged;
