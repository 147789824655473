import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import api from "../services/api";
import { TOKEN_KEY } from "../services/auth";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // função de login
  const signin = async (newUser, remember) => {
    await api
      .post("/session", newUser)
      .then((response) => {
        setUser({ ...response.data });
        remember &&
          localStorage.setItem(TOKEN_KEY, JSON.stringify({ ...response.data }));

        api.defaults.headers["Authorization"] = `Bearer ${response.data.token}`;

        return navigate("/meusprojetos");
      })
      .catch((err) => {
        console.log(err.message);
        if (err.message === "Network Error")
          return toast.warn(
            "Não foi possível ter acesso ao servidor. Verifique sua internet.",
          );
        return toast.error(err.response.data.message);
      });
  };

  // função de logout
  const signout = () => {
    setUser(null);
    localStorage.removeItem(TOKEN_KEY);

    return navigate("/login");
  };

  const isAuthenticated = async () => {
    let data =
      localStorage.getItem(TOKEN_KEY) !== null
        ? localStorage.getItem(TOKEN_KEY)
        : false;
    data &&
      (await api
        .post("/validate-token", { token: JSON.parse(data).token })
        .then((res) => {
          data && setUser(JSON.parse(data));
        })
        .catch(async (error) => {
          console.log("[ERROR] => ", error.data);
          localStorage.removeItem(TOKEN_KEY);
          signout();
        }));

    // este foi comentado para dar certo pagina de recuperar-senha
    // !data && (await navigate('/login'));
  };

  /**
   * Method to redirect private pages
   *
   * @param {boolean} validation - Logical validation
   * @param {string} redirectPage - Redirect page if validation is true
   * @returns {void} - Without return
   */
  const pageGuard = useCallback(
    (validation, redirectPage) => {
      if (!validation) return;
      window.location.href = redirectPage ?? `/login`;
    },
    [navigate],
  );

  let value = { user, signin, signout, isAuthenticated, pageGuard };

  useEffect(() => {
    isAuthenticated();
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const auth = useContext(AuthContext);

  return auth;
}

export function useGuard() {
  const { pageGuard } = useContext(AuthContext);

  return { pageGuard };
}
