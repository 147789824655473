import React, { useEffect, useState } from "react";

import { Container, Page, Card } from "./styles";

// dica
import Tooltip from "@material-ui/core/Tooltip";

// mascara
import { mask } from "remask";
// icones
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import StopIcon from "@material-ui/icons/Stop";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

// dialogo para verificar alterações
import Dialogoverificaralteracao from "../../../components/Dialogoverificaralteracao";

import { BsSortAlphaDown, BsSortAlphaUpAlt } from "react-icons/bs";

// fucoes
import Dialogoverprojeto from "../../../components/Dialogoverprojeto";

// confirmacao
import Dialogoconfirmacao from "../../../components/Dialogoconfirmacao";
import { toast } from "react-toastify";

// timeline
import Timelineproject from "../../../components/Timelineproject";

// chatbox
import Chatbox from "../../../components/Chatbox";
import api from "../../../services/api";

// backdrop
import Backdrop from "../../../components/Backdrop";

// contexto
import { useAuth } from "../../../context/AuthContext";
import { useUserData } from "../../../context/UserDataContext";
import { differenceInDays } from "date-fns";

import { Badge } from "@material-ui/core";

import { listetapas } from "../../../config";
// render props
import { WithAuthenticatedUser } from "../../../components/WithAuthenticatedUser";

function Projects() {
  const { user } = useAuth();
  const { company, completedData } = useUserData();

  const [showbackdrop, setShowbackdrop] = useState(false);
  const [showconf, setShowconf] = useState({
    status: false,
    width: "sm",
    title: "Confirmar",
    description: "Deseja realmente suspender este projeto?",
    btn_conf: "Sim",
    btn_cancel: "Não",
    delete: true,
    dados: "",
  });

  const [listConcess, setListConcess] = useState([]);

  const [filter, setFilter] = useState({
    type: "",
    value: "",
    dig: "",
  });
  const [listidx, setListidx] = useState(3);
  const [order, setOrder] = useState("primeiros");
  const [solicitarmud, setSolicitarmud] = useState({
    status: false,
    dados: "",
  });

  const [viewedit, setViewedit] = useState({
    status: false,
    dados: "",
    dados_proj: "",
  });

  const [viewtimeline, setViewtimeline] = useState({
    open: false,
    dados: "",
  });

  const [viewproject, setViewproject] = useState({
    status: false,
    dados: "",
  });
  const [listprojects, setListprojects] = useState([]);

  const handleFilter = (dado) => {
    if (filter.type === "client_name") {
      if (
        dado.client_name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
      )
        return true;
    } else if (filter.type === "client_cpf") {
      if (dado.client_cpf) {
        if (
          dado.client_cpf
            .replace(/[^0-9]/g, "")
            .indexOf(filter.value.replace(/[^0-9]/g, "")) > -1
        )
          return true;
      } else {
        return false;
      }
    } else if (filter.type === "client_cnpj") {
      if (dado.client_cnpj) {
        if (
          dado?.client_cnpj
            .replace(/[^0-9]/g, "")
            .indexOf(filter.value.replace(/[^0-9]/g, "")) > -1
        )
          return true;
      } else {
        return false;
      }
    } else if (filter.type === "suspended_projects") {
      if (dado.suspended) return true;
    } else if (filter.type === "state") {
      if (
        dado.dealer.state.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
      )
        return true;
    } else if (filter.type === "delayed_projects") {
      if (dado.process >= dado.max_process && dado.suspended === false) {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleFilterValue = (event) => {
    if (filter.type === "client_cpf") {
      setFilter({
        ...filter,
        value: mask(event.target.value, "999.999.999-99"),
      });
    } else if (filter.type === "client_cnpj") {
      setFilter({
        ...filter,
        value: mask(event.target.value, "99.999.999/9999-99"),
      });
    } else {
      setFilter({ ...filter, value: event.target.value });
    }
  };

  let listprojects2 = listprojects.filter(handleFilter).sort((a, b) => {
    if (order === "AZ") {
      return a.client_name > b.client_name
        ? 1
        : b.client_name > a.client_name
        ? -1
        : 0;
    } else if (order === "ZA") {
      return a.client_name < b.client_name
        ? 1
        : b.client_name < a.client_name
        ? -1
        : 0;
    } else if (order === "primeiros") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (order === "ultimos") {
      return new Date(a.created_at) - new Date(b.created_at);
    }
  });

  const getProjects = () => {
    setShowbackdrop(true);
    api
      .get(`projects/company/${company?.id}`)
      .then((res) => {
        setListprojects(res.data);
        setShowbackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        setShowbackdrop(false);
      });
  };

  const maisatual = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  };

  const getConcess = () => {
    setShowbackdrop(true);
    api
      .get(`/dealers`)
      .then((res) => {
        // console.log(res.data);
        setListConcess(res.data);
        setShowbackdrop(false);
      })
      .catch((error) => {
        console.log("Error: ", error.response.data);
        setShowbackdrop(false);
      });
  };

  const solicitarSuspencao = (dados_project) => {
    setShowbackdrop(true);

    let info_project = {
      project_id: dados_project.id,
      title: "",
      description: "",
    };

    if (dados_project.suspended) {
      info_project = {
        project_id: dados_project.id,
        title: "Retomar projeto",
        description: "Efetuada solicitação para retomar o projeto.",
      };
    } else {
      info_project = {
        project_id: dados_project.id,
        title: "Suspender projeto",
        description: "Efetuada solicitação para suspensão do projeto.",
      };
    }

    const config = {
      headers: {
        // Authorization: 'Bearer ' + props.user.token,
        "Content-Type": "application/json",
      },
    };

    api
      .post(`/project-change-requests`, info_project, config)
      .then((res) => {
        getProjects();
        setShowbackdrop(false);
        toast.success("Solicitação enviada com sucesso!");
      })
      .catch((error) => {
        console.log("Error: ", error.response.data);
        toast.success(error.response.data.message);
        getProjects();
        setShowbackdrop(false);
      });
  };

  function changePercentproject(value) {
    switch (value) {
      case listetapas[0]:
        return (1 / 5).toFixed(2);
      case listetapas[1]:
        return (2 / 5).toFixed(2);
      case listetapas[2]:
        return (3 / 5).toFixed(2);
      case listetapas[3]:
        return (4 / 5).toFixed(2);
      case listetapas[4]:
        return (5 / 5).toFixed(2);
      case listetapas[5]:
        return (5 / 5).toFixed(2);

      default:
        return 0;
    }
  }

  function finterRecentReceipt(a, b) {
    return new Date(a.created_at) - new Date(b.created_at);
  }

  useEffect(() => {
    if (company) getProjects();
  }, [company]);

  useEffect(() => {
    getConcess();
  }, []);

  return (
    <Container>
      <Page>
        <header>
          <h1>Meus projetos</h1>
          <span>Confira o andamento dos projetos cadastrados no sistema.</span>
        </header>
        <content>
          <div className="div__fieldFilter">
            <div className="div__filters">
              <select
                onChange={(event) =>
                  setFilter({
                    ...filter,
                    type: event.target.value,
                    value: "",
                    dig: "",
                  })
                }
                value={filter.type}
                style={{ maxWidth: 200 }}
              >
                <option value="" selected>
                  {" "}
                  Filtro{" "}
                </option>
                <option value="client_name">Nome</option>
                <option value="client_cpf">CPF</option>
                <option value="client_cnpj">CNPJ</option>
                <option value="state">Estado</option>
                {/* <option value="dealer_id">Concessionária</option> */}
                {/* <option value="open_projects">Projetos em aberto</option> */}
                {/* <option value="delayed_projects">Projetos atrasados</option> */}
                {/* <option value="completed_projects">Projetos finalizados</option> */}
                {/* <option value="suspended_projects">Projetos suspensos</option> */}
              </select>

              <input
                placeholder={
                  filter.type === "client_name"
                    ? "Digite o nome"
                    : filter.type === "client_cpf"
                    ? "Digite o CPF"
                    : filter.type === "client_cnpj"
                    ? "Digite o CNPJ"
                    : " - "
                }
                value={filter.value}
                disabled={!filter.type ? true : false}
                onChange={handleFilterValue}
                style={{
                  display:
                    filter.type === "client_name" ||
                    filter.type === "client_cpf" ||
                    filter.type === "client_cnpj"
                      ? "block"
                      : "none",
                }}
              />

              <select
                required
                name="state"
                style={{
                  flex: 1,
                  display: filter.type === "state" ? "block" : "none",
                }}
                value={filter.value}
                onChange={handleFilterValue}
              >
                <option value="" disabled selected>
                  Escolha o estado
                </option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>
            </div>
            <div className="div__order">
              <button
                className={order === "AZ" ? "btn__esq btn__ativ" : "btn__esq"}
                onClick={() => (order === "AZ" ? setOrder("") : setOrder("AZ"))}
              >
                <BsSortAlphaDown style={{ fontSize: "1.4rem" }} />
              </button>
              <button
                className={order === "ZA" ? "btn_meio btn__ativ" : "btn_meio"}
                onClick={() => (order === "ZA" ? setOrder("") : setOrder("ZA"))}
              >
                <BsSortAlphaUpAlt style={{ fontSize: "1.4rem" }} />
              </button>

              <button
                className={
                  order === "primeiros" ? "btn_meio btn__ativ" : "btn_meio"
                }
                onClick={() =>
                  order === "primeiros" ? setOrder("") : setOrder("primeiros")
                }
              >
                Recentes
              </button>
              <button
                className={
                  order === "ultimos" ? "btn__dir btn__ativ" : "btn__dir"
                }
                onClick={() =>
                  order === "ultimos" ? setOrder("") : setOrder("ultimos")
                }
              >
                Antigos
              </button>
            </div>
          </div>
          <div className="div__deck">
            {listprojects2.slice(0, listidx).map((proj, idx) => (
              <Card
                key={idx}
                progress={differenceInDays(
                  new Date(),
                  new Date(proj?.stages.sort(maisatual)[0]?.created_at),
                )}
                projetct_process={changePercentproject(
                  proj?.stages?.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                  })[0]?.title,
                )}
                max_process={differenceInDays(
                  new Date(proj?.stages.sort(maisatual)[0]?.deadline),
                  new Date(proj?.stages.sort(maisatual)[0]?.created_at),
                )}
                suspended={proj.suspended}
                finished={proj?.finished_at ? true : false}
                refused={true}
              >
                <div className="div__name">
                  {!proj.finished_at ? (
                    proj.suspended ? (
                      <span
                        className="project_status"
                        style={{
                          color: "gray",
                        }}
                      >
                        Projeto suspenso
                      </span>
                    ) : proj.refused ? (
                      <span
                        className="project_status"
                        style={{
                          color: "var(--delete)",
                        }}
                      >
                        Projeto recusado
                      </span>
                    ) : (
                      <span
                        className="project_status"
                        style={{
                          color: "var(--primary2)",
                        }}
                      >
                        {proj?.receipts.sort(finterRecentReceipt)[0]?.is_paid
                          ? "Projeto em aberto"
                          : "Aguardando Pagamento"}
                      </span>
                    )
                  ) : (
                    <span
                      className="project_status"
                      style={{
                        color: "gray",
                      }}
                    >
                      {proj?.stages.sort(maisatual)[0]?.title ===
                      "6 - Projeto Aprovado"
                        ? "Projeto aprovado"
                        : proj?.stages.sort(maisatual)[0]?.title ===
                          "7 - Projeto Reprovado"
                        ? "Projeto reprovado"
                        : "Projeto finalizado"}
                    </span>
                  )}

                  <h4>
                    <AssignmentOutlinedIcon /> {proj.client_name}
                  </h4>
                  {proj?.client_cpf && <span>CPF: {proj.client_cpf}</span>}
                  {proj?.client_cnpj && <span>CNPJ: {proj.client_cnpj}</span>}
                  <span>
                    {listConcess?.find((a) => a?.id === proj?.dealer_id)?.name}{" "}
                    -{" "}
                    {listConcess?.find((a) => a?.id === proj?.dealer_id)?.state}
                  </span>
                  <div className="div__options">
                    {!proj?.finished_at && !proj.suspended && !proj.refused && (
                      <Tooltip
                        placement="bottom-start"
                        title="Solicitar edição"
                      >
                        <button
                          onClick={() =>
                            setSolicitarmud({ status: true, dados: proj })
                          }
                        >
                          <EditOutlinedIcon />
                        </button>
                      </Tooltip>
                    )}

                    <Tooltip
                      placement="bottom-start"
                      title="Visualizar informações de projeto"
                    >
                      <Badge
                        overlap="circular"
                        badgeContent={
                          proj?.pendencies?.filter(
                            (change) =>
                              !change.customer_comment &&
                              change.resolved === false,
                          ).length
                        }
                        // showZero
                        color="secondary"
                      >
                        <button
                          onClick={() =>
                            setViewproject({ status: true, dados: proj })
                          }
                        >
                          <VisibilityOutlinedIcon />
                        </button>
                      </Badge>
                    </Tooltip>

                    {!proj?.finished_at && !proj.refused && (
                      <Tooltip
                        placement="bottom-start"
                        title="Solicitações pendentes"
                      >
                        <Badge
                          overlap="circular"
                          badgeContent={
                            proj?.change_requests?.filter(
                              (change) => change.resolved === false,
                            ).length
                          }
                          // showZero
                          color="secondary"
                        >
                          <button
                            onClick={() =>
                              setViewedit({
                                status: true,
                                dados: proj.change_requests,
                                dados_proj: proj,
                              })
                            }
                          >
                            <AssignmentTurnedInIcon />
                          </button>
                        </Badge>
                      </Tooltip>
                    )}

                    {!proj.suspended && !proj.refused && (
                      <Tooltip placement="bottom-start" title="Ver timeline">
                        <button
                          onClick={() =>
                            setViewtimeline({ open: true, dados: proj })
                          }
                        >
                          <AccessTimeIcon />
                        </button>
                      </Tooltip>
                    )}

                    {!proj?.finished_at && proj.suspended && (
                      <Tooltip
                        placeholder="bottom-start"
                        title="Solicitar retomada do projeto"
                      >
                        <button
                          onClick={() =>
                            setShowconf({
                              ...showconf,
                              status: true,
                              width: "sm",
                              title: "Confirmar",
                              description: `Deseja realmente retomar o projeto de ${proj.client_name}?`,
                              btn_conf: "Sim",
                              btn_cancel: "Não",
                              delete: false,
                              dados: proj,
                            })
                          }
                        >
                          <PlayArrowIcon />
                        </button>
                      </Tooltip>
                    )}

                    {!proj?.finished_at && !proj.suspended && !proj.refused && (
                      <Tooltip
                        placement="bottom-start"
                        title="Solicitar suspensão do projeto"
                      >
                        <button
                          className="button_delete"
                          onClick={() =>
                            setShowconf({
                              ...showconf,
                              status: true,
                              dados: proj,
                            })
                          }
                        >
                          <StopIcon />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="div__total_project_power">
                  <h4>{proj.total_project_power}kWp</h4>
                  <div className="div__sub_total_project_power">
                    <span>{proj.refused ? "Solicitado em" : "Início"}:</span>{" "}
                    <p style={{ margin: "0rem" }}>
                      {new Date(proj.created_at).toLocaleDateString("pt-BR")}
                    </p>
                  </div>

                  <div className="div__sub_total_project_power">
                    <span>
                      {proj.refused
                        ? "-"
                        : !proj?.finished_at
                        ? "Previsão de Conclusão:"
                        : "Finalizado em:"}
                    </span>{" "}
                    {!proj.suspended && !proj.refused ? (
                      proj.deadline || proj.finished_at ? (
                        <p style={{ margin: "0rem" }}>
                          {new Date(
                            proj.finished_at ? proj.finished_at : proj.deadline,
                          ).toLocaleDateString("pt-BR")}
                        </p>
                      ) : (
                        <p>--/--/----</p>
                      )
                    ) : (
                      <p>--/--/----</p>
                    )}
                  </div>
                </div>
                <div className="div__status">
                  <div className="barra_progresso">
                    <span />{" "}
                    <div>
                      <h4>Status do Projeto</h4>
                    </div>
                  </div>
                  <div className="barra_progresso">
                    <span>
                      PROJETO (
                      {proj.refused
                        ? "0"
                        : (
                            changePercentproject(
                              proj?.stages?.sort((a, b) => {
                                return (
                                  new Date(b.created_at) -
                                  new Date(a.created_at)
                                );
                              })[0]?.title,
                            ) * 100
                          ).toFixed(2)}
                      %)
                    </span>
                    <div className="div__status_project" />
                  </div>

                  {proj.suspended ||
                  proj.finished_at ||
                  proj.stages.length === 0 ? (
                    <div className="barra_progresso">
                      <span style={{ marginTop: "1rem" }}>Situação</span>
                    </div>
                  ) : (
                    <div className="barra_progresso">
                      {proj?.stages && (
                        <span style={{ marginTop: "1rem" }}>
                          {proj?.stages.sort(maisatual)[0]?.title} <br />
                          (há{" "}
                          {differenceInDays(
                            new Date(),
                            new Date(
                              proj?.stages.sort(maisatual)[0]?.created_at,
                            ),
                          )}{" "}
                          dias)
                          <br />
                          Estimativa:{" "}
                          {differenceInDays(
                            new Date(proj?.stages.sort(maisatual)[0]?.deadline),
                            new Date(
                              proj?.stages.sort(maisatual)[0]?.created_at,
                            ),
                          )}{" "}
                          dias
                          {}
                        </span>
                      )}
                      <div className="div__status_process" />
                    </div>
                  )}

                  <div className="barra_progresso">
                    <span />
                    {!proj.refused ? (
                      proj?.finished_at ? (
                        <div className="div__description">
                          {proj?.stages.sort(maisatual)[0]?.title ===
                          "6 - Projeto Aprovado"
                            ? "PROJETO APROVADO"
                            : proj?.stages.sort(maisatual)[0]?.title ===
                              "7 - Projeto Reprovado"
                            ? "PROJETO REPROVADO"
                            : "PROJETO FINALIZADO"}
                        </div>
                      ) : proj.suspended ? (
                        <div className="div__description">PROJETO SUSPENSO</div>
                      ) : !proj.deadline || proj.stages.length === 0 ? (
                        <div className="div__description">
                          {proj?.receipts.sort(finterRecentReceipt)[0]?.is_paid
                            ? "PROJETO AGUARDANDO APROVAÇÃO"
                            : "PROJETO AGUARDANDO PAGAMENTO"}
                        </div>
                      ) : (
                        <div className="div__description">
                          DESCRIÇÃO:{" "}
                          {
                            proj.stages
                              .sort(maisatual)[0]
                              ?.status.sort(maisatual)[0]?.description
                          }
                        </div>
                      )
                    ) : (
                      <div className="div__description">
                        ESTE PROJETO NÃO FOI ACEITO
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            ))}
            {listprojects2?.filter(
              (proj) => proj.deadline && proj.stages.length !== 0,
              // && proj.responsibles.length !== 0,
            ).length > listidx ? (
              <div
                className="div__viewMore"
                onClick={() => setListidx(listidx + 3)}
              >
                Ver mais
              </div>
            ) : listprojects2?.length === 0 && filter.type ? (
              <div style={{ margin: "2rem 1rem" }}>
                Nenhum projeto encontrado
              </div>
            ) : listprojects2?.length === 0 && !filter.type ? (
              <div style={{ margin: "2rem 1rem" }}>
                Nenhum projeto cadastrado
              </div>
            ) : (
              <div style={{ margin: "2rem 1rem" }}>Sem mais Projetos</div>
            )}
          </div>
        </content>
      </Page>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({
              status: false,
              width: "sm",
              title: "Confirmar",
              description: "Deseja realmente suspender este projeto?",
              btn_conf: "Sim",
              btn_cancel: "Não",
              delete: true,
              dados: "",
            })
          }
          confirmar={() => {
            solicitarSuspencao(showconf.dados);
          }}
        />
      )}

      {viewproject.status && (
        <Dialogoverprojeto
          listConcess={listConcess}
          getProjects={() => getProjects()}
          dados={viewproject.dados}
          fechar={() => {
            setViewproject({ status: false, dados: "" });
            getProjects();
          }}
        />
      )}

      {solicitarmud.status && (
        <Chatbox
          fechar={() => {
            setSolicitarmud({ status: false, dados: "" });
            getProjects();
          }}
          dados={solicitarmud.dados}
        />
      )}

      {viewtimeline.open && (
        <Timelineproject
          dados={viewtimeline.dados}
          fechar={() => {
            setViewtimeline({ open: false, dados: "" });
            getProjects();
          }}
        />
      )}

      {viewedit.status && (
        <Dialogoverificaralteracao
          dados={viewedit.dados}
          dados_proj={viewedit.dados_proj}
          fechar={() => {
            setViewedit({ status: false, dados: "", dados_proj: "" });
            getProjects();
          }}
          edit={setViewproject}
        />
      )}

      {showbackdrop && <Backdrop />}
    </Container>
  );
}

function ProjectsLogged() {
  return <WithAuthenticatedUser render={() => <Projects />} />;
}

export default ProjectsLogged;
