import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// icones
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { Container, FielButtons } from './styles';
import { toast } from 'react-toastify';
import Dialogoconfirmacao from '../Dialogoconfirmacao';
import { Tooltip } from '@material-ui/core';

export default function Dialogoverificaralteracao(props) {
  const [open, setOpen] = React.useState(true);
  const [dados, setDados] = React.useState({ ...props.dados });
  const [newArchive, setNewArchive] = React.useState([]);
  const [document, setDocument] = React.useState({
    open: false,
    type: '',
    other_type: '',
    document_additional: '',
  });

  const [showconf, setShowconf] = React.useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente remover este arquivo?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
    temp: '',
  });

  const [editar, setEditar] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const submitNewArchive = (a) => {
    setNewArchive([...newArchive, a.target.files[0]]);
    toast.success('Novo arquivo adicionado com sucesso!');
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 9999999991,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container suspended={false}>
              <header>
                <h1>Alterações do Projeto de {props.dados_proj.client_name}</h1>
                <span>
                  Confira abaixo os pedidos de alterações com mais detalhes.
                </span>
                <div className="div__listpendencias">
                  {props.dados
                    ?.sort((a, b) => {
                      return (
                        new Date(b.created_at).getTime() -
                        new Date(a.created_at).getTime()
                      );
                    })
                    .map((pendencia, idx) => (
                      <div key={idx}>
                        <div className="div__pendencias">
                          {pendencia.title === 'Suspender projeto' ||
                          pendencia.title === 'Retomar projeto' ? (
                            <ReportProblemOutlinedIcon className="icone_suspend" />
                          ) : pendencia.resolved ? (
                            <CheckCircleOutlineIcon className="icone_success" />
                          ) : (
                            <ErrorOutlineIcon className="icone_warn" />
                          )}
                          <div className="div__info" style={{ flex: 10 }}>
                            <h5
                              className={
                                pendencia.title === 'Suspender projeto' ||
                                pendencia.title === 'Retomar projeto'
                                  ? 'title_suspend'
                                  : ''
                              }
                            >
                              {pendencia.title}
                              {/* <span>{pendencia.type}</span> */}
                            </h5>

                            <p>{pendencia.description}</p>
                            <ul>
                              <li>
                                - Solicitado em{' '}
                                {new Date(
                                  pendencia.created_at
                                ).toLocaleDateString('pt-BR', {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                })}
                              </li>
                              {pendencia.resolved && (
                                <li>
                                  - Aceita em{' '}
                                  {new Date(
                                    pendencia.updated_at
                                  ).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                  })}
                                </li>
                              )}
                            </ul>
                          </div>
                          <div
                            className="div__options"
                            style={{
                              flex: 1,
                              display: 'flex',

                              justifyContent: 'flex-end',
                            }}
                          >
                            {/* {pendencia.type === 'arquivo' && (
                            <button>
                              <GetAppIcon />
                            </button>
                          )}
                          {pendencia.type === 'informação' && (
                            <button>
                              <ChatOutlinedIcon />
                            </button>
                          )} */}
                            {/* <Tooltip
                            placement="bottom-start"
                            title="Área de Edição"
                          > */}

                            <br />

                            {/* </Tooltip> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  {!props.dados.length && <h4>Sem alterações em aberto</h4>}
                </div>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
