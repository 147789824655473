import { useState } from "react";
import Dialogotrocarsenha from "../../../components/Dialogotrocarsenha";
import Dialogoalterardados from "../../../components/Dialogoalterardados";

// alertas
import { Alert } from "@material-ui/lab";
import { RiAlertFill } from "react-icons/ri";
import Tooltip from "@material-ui/core/Tooltip";

import { Container, Page } from "./styles";
import api from "../../../services/api";
import { useUserData } from "../../../context/UserDataContext";
import { useAuth } from "../../../context/AuthContext";
// render props
import { WithAuthenticatedUser } from "../../../components/WithAuthenticatedUser";

function Settings() {
  const {
    loginData,
    person,
    addressPerson,
    company,
    addressCompany,
    completedData,
    loading,
    numbersContact,
  } = useUserData();
  const { user } = useAuth();

  const [showdialogpass, setShowdialogpass] = useState(false);
  const [showaltdados, setShowaltdados] = useState(false);
  const [editaccessdata, setEditaccessdata] = useState(false);

  const trocarSenha = (e) => {
    alert(`Sua nova senha é: ${e}`);
  };

  const updatePassword = async (password) => {
    await api
      .put(`/login-data/change-password/${user.id}`, { password })
      .then((res) => {
        setShowdialogpass(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  return (
    <Container>
      {!loading ? (
        <Page>
          <header>
            <h1>Configurações</h1>
            <span>
              Edite seus dados pessoais e as configurações de acesso de sua
              conta.
            </span>
          </header>
          <content>
            <div className="div__conteudo">
              {!completedData && (
                <Alert severity="warning" className="div__alertas">
                  <h3>Confirmar cadastro</h3>
                  Preencha adequadamente as informações abaixo para completar
                  seu cadastro e solicitar seu primeiro projeto.
                </Alert>
              )}

              <div className="div__row">
                <strong>E-mail: </strong>
                {loginData.email}
              </div>
              <div className="div__row">
                <strong>Senha: </strong>
                ******{" "}
                <small>
                  (
                  <a href="#password" onClick={() => setShowdialogpass(true)}>
                    trocar senha
                  </a>
                  )
                </small>
              </div>

              <h4>Dados pessoais*</h4>
              <div className="div__oneRow">
                <p>
                  <label>Nome: </label> {person.full_name}
                </p>
                <p>
                  <label>CPF: </label> {person.cpf}
                </p>
              </div>

              <h4>
                Dados de contatos*
                {numbersContact?.length === 0 && (
                  <Tooltip title="dados incompletos" placement="right">
                    <RiAlertFill color="#FFB703" size={25} />
                  </Tooltip>
                )}
              </h4>
              <div className="div__oneRow">
                {numbersContact.length > 0 ? (
                  person.numbers_contact.map((number) => (
                    <p>
                      <label
                        title={number.is_valid ? "Verificado" : "inválido"}
                      >
                        {number.type_number === "fixo"
                          ? "Telefone fixo"
                          : number.is_whatsapp
                          ? "Whatsapp"
                          : "Celular"}
                        :{" "}
                      </label>{" "}
                      {number.number}
                    </p>
                  ))
                ) : (
                  <p>
                    <label>Celular/Fixo/whatsapp: </label> Não informado.
                  </p>
                )}
              </div>

              <h4>
                Endereço*
                {(!addressPerson?.zip_code ||
                  !addressPerson?.state ||
                  !addressPerson?.city ||
                  !addressPerson?.street ||
                  !addressPerson?.number ||
                  !addressPerson?.district) && (
                  <Tooltip title="dados incompletos" placement="right">
                    <RiAlertFill color="#FFB703" size={25} />
                  </Tooltip>
                )}
              </h4>
              <div className="div__oneRow">
                <p>
                  <label>CEP: </label> {addressPerson?.zip_code || " - "}
                </p>
              </div>
              <div className="div__oneRow">
                <p>
                  <label>Estado: </label> {addressPerson?.state || " - "}
                </p>
                <p>
                  <label>Cidade: </label> {addressPerson?.city || " - "}
                </p>
              </div>
              <div className="div__oneRow">
                <p>
                  <label>Rua: </label> {addressPerson?.street || " - "}
                </p>
                <p>
                  <label>Nº: </label> {addressPerson?.number || " - "}
                </p>
              </div>
              <div className="div__oneRow">
                <p>
                  <label>Bairro: </label> {addressPerson?.district || " - "}
                </p>
                <p>
                  <label>Complemento: </label>{" "}
                  {addressPerson?.complement || " - "}
                </p>
              </div>

              <h4>
                Dados da empresa*
                {!company.fantasy_name && (
                  <Tooltip title="dados incompletos" placement="right">
                    <RiAlertFill color="#FFB703" size={25} />
                  </Tooltip>
                )}
              </h4>
              <div className="div__oneRow">
                <p>
                  <label>CNPJ: </label> {company.cnpj || " - "}
                </p>
              </div>
              <div className="div__oneRow">
                <p>
                  <label>Nome fantasia: </label> {company.fantasy_name || " - "}
                </p>
              </div>
              <div className="div__oneRow">
                <p>
                  <label>Razão social: </label> {company.social_reason || " - "}
                </p>
              </div>
              <div className="div__oneRow">
                <p>
                  <label>Registro Estadual: </label>{" "}
                  {company.state_registration
                    ? company.state_registration
                    : " - "}
                </p>
                <p>
                  <label>Registro Municipal: </label>{" "}
                  {company.municipal_registration
                    ? company.municipal_registration
                    : " - "}
                </p>
              </div>

              <h4>
                Endereço da empresa*
                {(!addressCompany?.zip_code ||
                  !addressCompany?.state ||
                  !addressCompany?.city ||
                  !addressCompany?.street ||
                  !addressCompany?.number ||
                  !addressCompany?.district) && (
                  <Tooltip title="dados incompletos" placement="right">
                    <RiAlertFill color="#FFB703" size={25} />
                  </Tooltip>
                )}
              </h4>
              <div className="div__oneRow">
                <p>
                  <label>CEP: </label> {addressCompany?.zip_code || " - "}
                </p>
              </div>
              <div className="div__oneRow">
                <p>
                  <label>Estado: </label> {addressCompany?.state || " - "}
                </p>
                <p>
                  <label>Cidade: </label> {addressCompany?.city || " - "}
                </p>
              </div>
              <div className="div__oneRow">
                <p>
                  <label>Rua: </label> {addressCompany?.street || " - "}
                </p>
                <p>
                  <label>Nº: </label> {addressCompany?.number || " - "}
                </p>
              </div>
              <div className="div__oneRow">
                <p>
                  <label>Bairro: </label> {addressCompany?.district || " - "}
                </p>
                <p>
                  <label>Complemento: </label>{" "}
                  {addressCompany?.complement
                    ? addressCompany?.complement
                    : " - "}
                </p>
              </div>

              <h4>Minhas atribuições na empresa</h4>
              <div className="div__oneRow">
                <p>
                  <label>Cargo: </label> {person.role || " - "}
                </p>
              </div>

              <br />
              {/* botoes */}
              <button
                type="submit"
                className="btn__finish"
                onClick={() => setShowaltdados(true)}
              >
                Editar
              </button>
            </div>
          </content>
        </Page>
      ) : (
        <h1></h1>
      )}

      {showdialogpass && (
        <Dialogotrocarsenha
          negar={() => setShowdialogpass(false)}
          confirmar={(password) => updatePassword(password)}
          loginData={loginData}
        />
      )}
      {showaltdados && (
        <Dialogoalterardados fechar={() => setShowaltdados(false)} />
      )}
    </Container>
  );
}

function SettingsLogged() {
  return <WithAuthenticatedUser render={() => <Settings />} />;
}

export default SettingsLogged;
