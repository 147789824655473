import styled from 'styled-components';
import descontoImage1 from '../../../assets/images/prints/desconto_acumulado.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
  padding-right: 2rem;
`;

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  /* border-radius: 0.5rem; */
  min-height: 200px;

  content {
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    .div__image_solar {
      display: flex;
      justify-content: flex-end;
      margin-top: -6rem;

      img {
        width: 250px;
        margin: 0rem 2rem;
        margin-bottom: -4rem;
      }
    }

    .div__field_actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0rem 2rem;
    }

    .div__orcamento_field {
      overflow: hidden;
      padding: 4rem 1rem;
      flex: 1;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px dashed rgba(var(--black_rgb), 0.3);
      margin: 2rem 1rem;
      transition: max-height 0.2s ease-out;

      p {
        margin: 0rem;
        max-width: 500px;
      }
      h1,
      h2 {
        margin: 0rem;
      }
      h1 {
        margin: 0.75rem;
        color: var(--primary2);
        font-size: 2.5rem;
      }
    }

    input,
    select {
      outline-color: var(--primary2);
      padding: 0.75rem 0.5rem;
      border: 1px solid rgba(var(--black_rgb), 0.3);
      border-radius: 0.5rem;
      margin: 0rem 0.25rem;
      width: 100%;
      z-index: 0;
    }
    .div__multiColumn {
      display: flex;
      padding: 0rem 2rem;
      margin-top: 0.5rem;

      .img_orcamento {
        max-width: 250px;
        height: 200px;
      }
      .div__desconto_tag {
        width: 270px;
        /* width: 100%; */
        height: 180px;
        /* background-color: red; */
        background-image: url(${descontoImage1});
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        padding-right: 0rem;

        p {
          transform: rotate(-14deg);
          text-align: left;
          color: var(--white);
        }
        span {
          font-size: 4rem;
          color: #f9d40a;
          font-weight: 800;
          margin-bottom: -1rem;
          margin-right: -1.5rem;
          /* -webkit-text-stroke: 2px #f85e00; */
          /* -webkit-text-fill-color: yellow; */
          text-shadow: -2px -2px 5px rgba(var(--primary2_rgb), 0.5),
            2px 2px 5px rgba(var(--primary2_rgb), 0.5),
            -2px 2px 5px rgba(var(--primary2_rgb), 0.5),
            2px -2px 5px rgba(var(--primary2_rgb), 0.5);
        }
      }

      .btn__search_coord {
        display: flex;
        align-items: center;
      }
    }

    .pos_field {
      background-color: #e1e1e1;
      z-index: 1;
      padding: 0rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0rem 0.5rem 0.5rem 0rem;
      margin-left: -1rem;
      border: 1px solid rgba(var(--black_rgb), 0.3);
    }
    .div__fieldFilter {
      margin: 0rem 2rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      .div__filters {
        display: flex;
        justify-content: flex-start;
        margin: auto 0px;
        width: 100%;
        input,
        select {
          outline-color: var(--primary2);
          padding: 0.75rem 0.5rem;
          border: 1px solid rgba(var(--black_rgb), 0.3);
          border-radius: 0.5rem;
          margin: 0rem 0.25rem;
          width: 100%;
          /* margin: auto; */
          max-width: 350px;
          z-index: 0;
        }
      }

      .div__order {
        /* background: red; */
        margin: auto;
        display: flex;
        button {
          border: 1px solid rgba(var(--black_rgb), 0.2);
          padding: 0.2rem 0.5rem;
          color: var(--primary2);
          cursor: pointer;

          :hover {
            background-color: var(--primary2) !important;
            color: var(--white);
          }
        }
        .btn__esq {
          background-color: transparent !important;
          border-radius: 0.5rem 0rem 0rem 0.5rem;
        }
        .btn__dir {
          background-color: transparent !important;
          border-radius: 0rem 0.5rem 0.5rem 0rem;
          border-left: 0px;
        }

        .btn__ativ {
          background-color: var(--primary2) !important;
          color: var(--white);
        }
        .btn_meio {
          background: var(--white);
          border-left: 0px;
        }
      }
    }
  }

  header {
    padding: 1rem 2rem;
    text-align: left;
    font-family: 'Poppins', sans-serif;

    h1 {
      font-weight: 800;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    span {
      opacity: 0.9;
    }

    ::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    }
  }

  .btn__finish_disabled {
    background-image: linear-gradient(
      to bottom right,
      rgb(var(--black_rgb), 0.3),
      rgb(var(--black_rgb), 0.1)
    );
    pointer-events: none;
    padding: 1rem 3rem;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    border: 0rem;
    font-size: 0.9rem;
    float: right;
    margin: 2rem 2rem;
    cursor: pointer;
    border-radius: 2rem;
  }

  .btn__finish {
    background-image: linear-gradient(
      to bottom right,
      var(--primary2),
      var(--primary)
    );
    padding: 1rem 3rem;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    border: 0rem;
    font-size: 0.9rem;
    float: right;
    margin: 2rem 2rem;
    cursor: pointer;
    border-radius: 2rem;

    :hover {
      background-image: linear-gradient(
        to bottom left,
        var(--primary2),
        var(--primary)
      );
    }
  }

  .conteudo {
    margin: 0rem 2rem;
    margin-bottom: 2rem;
    /* background: red; */

    .div__row {
      display: flex;
      flex-direction: row;

      strong {
        margin-right: 0.5rem;
      }
      small {
        margin: 0rem 0.5rem;
      }
    }

    h4 {
      /* background-color: red; */
      text-transform: uppercase;
      margin: 1rem 0rem;
      font-size: 0.8rem;
      color: rgba(var(--black_rgb), 0.5);
      font-weight: 400;
      width: 100%;
      /* padding: 0rem 2rem; */
      flex: 1;
      /* margin-left: 0rem; */
      text-align: left;

      ::after {
        display: block;
        content: '';
        margin: 0.5rem 0rem;
        border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
      }
    }

    p {
      flex: 1;
      margin: 0rem;
      font-size: 0.9rem;
    }
    label {
      font-weight: 600;
    }
    .div__oneRow {
      display: flex;
      text-align: left;
      justify-content: space-between;
      /* background: red; */
    }

    .btn__finish {
      background-image: linear-gradient(
        to bottom right,
        var(--primary2),
        var(--primary)
      );
      padding: 0.5rem 2rem;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      border: 0rem;
      font-size: 0.8rem;
      float: left;
      margin: 1rem 0rem;
      cursor: pointer;
      border-radius: 2rem;

      :hover {
        background-image: linear-gradient(
          to bottom left,
          var(--primary2),
          var(--primary)
        );
      }
    }
  }
  @media screen and (max-width: 675px) {
    select {
      height: 50px !important;
      font-size: 22px;

      width: 100%;
      margin: 0rem;
    }

    .div__multiColumn {
      display: flex;
      padding: 0rem 1rem !important;
      margin: 0rem;
      margin-top: 0.5rem;

      img {
        max-width: 250px;
        height: 150px !important;
      }

      .btn__search_coord {
        display: flex;
        align-items: center;
      }
    }

    .div__oneRow {
      display: flex;
      flex-direction: column;
    }
    .div__multiColumn {
      flex-direction: column;

      img {
        max-width: 150px !important;
        margin: auto;
        margin-bottom: 1.5rem;
      }
    }
    .btn__finish {
      float: initial;
    }
  }
  .div__deck {
    .head_list {
      display: flex;
      margin: 0.1rem 1rem;
      padding: 0.5rem 1rem;
      justify-content: space-between;
      gap: 1rem;
      div {
        color: rgba(var(--black_rgb), 0.5);
        text-align: left;
        flex: 1;
        font-weight: 600;
      }
    }
  }
`;

export const Card = styled.div`
  display: flex;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  margin: 0.1rem 1rem;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  div {
    flex: 1;
    text-align: left;
  }

  .div__password {
    font-size: 12px;
  }

  .div__options {
    display: flex;
    gap: 0.5rem;
    button {
      padding: 0.5rem;
    }
    .btn_delete {
      :hover {
        background: var(--delete);
        color: var(--white);
      }
    }
  }
`;

export const FieldButtons = styled.div`
  display: flex;
  /* background: red; */
  align-items: center;

  .btn__retornar {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 45px;
    background-color: transparent;
    border: ${(props) =>
      props.delete ? '1px solid var(--delete)' : '1px solid var(--primary2)'};
    border-radius: 1.4rem;
    color: ${(props) => (props.delete ? 'var(--delete)' : 'var(--primary2)')};
    /* margin-top: 1rem; */
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  .btn__register {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;

    width: 320px;
    height: 45px;
    background-color: ${(props) =>
      props.delete ? 'var(--delete)' : 'var(--primary2)'};
    border: 0px;
    border-radius: 1.4rem;
    color: var(--white);
    /* margin-top: 1rem; */
    /* margin-bottom: 0.75rem; */
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
      filter: brightness(1.4);
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn__retornar,
    .btn__register {
      /* margin: auto; */
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;
