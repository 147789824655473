import { useEffect, useState } from "react";
// custom hooks
import { useAuth, useGuard } from "../../context/AuthContext";
// components
import Backdrop from "../Backdrop";

// settings
const REDIRECT_PAGE = "/login";

function WithAuthenticatedUser({ render }) {
  const [loading, setLoading] = useState(true);

  const auth = useAuth();
  const { pageGuard } = useGuard();

  useEffect(() => {
    if (auth.user) return setLoading(false);
    pageGuard(!auth?.user, REDIRECT_PAGE);
  }, [auth, pageGuard, loading]);

  return loading ? <Backdrop /> : render();
}

export { WithAuthenticatedUser };
