import { useEffect, useState } from 'react';

import { Container } from './styles';

// images
import Logo1 from '../../../assets/images/logo/logo1.png';
import Logo2 from '../../../assets/images/logo/logo2.png';
import Bg1 from '../../../assets/images/bgs/bg1.png';

// mensagem
// import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

import ModalRecoverPassword from '../../../components/ModalRecoverPassword';
import { toast } from 'react-toastify';

function Login(props) {
  const [recoverPassword, setRecoverPassword] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const [qmessage] = useState(query.get('message'));

  const { signin } = useAuth();

  const [dados, setDados] = useState({
    email: '',
    password: '',
    remember: true,
  });

  function sendLogin(event) {
    event.preventDefault();

    // para produção
    const requestBody = {
      email: dados.email.trim(),
      password: dados.password,
    };

    signin(requestBody, dados.remember);
  }

  useEffect(() => {
    toast.success(qmessage);
  }, [qmessage]);

  return (
    <Container bg1={Bg1}>
      <div className="div__gradient">
        <div className="div__corpo">
          <div className="div__part1">
            {/* <h3>Seja bem vindo!</h3> */}
            {/* <img src={Logo1} title="Logo Tellar" alt="Logo Tellar" /> */}
            {/* <p>
              Especialista em elaboração e homologação de projeto de energia
              solar fotovoltaica.
            </p> */}
          </div>
          <div className="div__part2">
            <form onSubmit={sendLogin}>
              <img
                src={Logo2}
                title="Logo Tellar"
                className="img__logo_mobile"
                alt="Logo Tellar"
              />
              <h1>Painel do Usuário</h1>
              <span>
                Realize login para acompanhar o processo de seus projetos
                cadastrados
              </span>
              <input
                type="email"
                placeholder="E-mail"
                value={dados.email}
                autoFocus
                onChange={(e) =>
                  setDados({ ...dados, email: e.target.value.toLowerCase() })
                }
              />
              <input
                type="password"
                placeholder="Senha"
                value={dados.password}
                onChange={(e) =>
                  setDados({ ...dados, password: e.target.value })
                }
              />
              <div className="div__functions">
                <label>
                  <input
                    type="checkbox"
                    checked={dados.remember}
                    onChange={() =>
                      setDados({ ...dados, remember: !dados.remember })
                    }
                  />
                  Lembrar-me
                </label>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => setRecoverPassword(true)}
                >
                  Recuperar senha
                </a>
              </div>
              <button className="btn__login" type="submit">
                Login
              </button>
              <div className="div__line" />
              <div className="div__subtext">
                <label>Novo usuário? Realize seu cadastro</label>
              </div>
              <Link to="/cadastro" style={{ width: '100%' }}>
                <button className="btn__register" type="button">
                  Cadastrar
                </button>
              </Link>
            </form>
          </div>
        </div>
      </div>
      {recoverPassword && (
        <ModalRecoverPassword fechar={() => setRecoverPassword(false)} />
      )}
    </Container>
  );
}

export default Login;
