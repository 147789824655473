import { ReactNode, useEffect, useState } from "react";
import Dialogotrocarsenha from "../../../components/Dialogotrocarsenha";
import Dialogoalterardados from "../../../components/Dialogoalterardados";
import { mask } from "remask";
import Dialogonewuser from "../../../components/Dialogonewuser";

import { Container, Page, Card, FieldButtons } from "./styles";

import Image03 from "../../../assets/images/prints/money_03.png";
import solarImage2 from "../../../assets/images/prints/solar-image2.png";

// icones
import { BsSortAlphaDown, BsSortAlphaUpAlt } from "react-icons/bs";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

// confirmacao
import Dialogoconfirmacao from "../../../components/Dialogoconfirmacao";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import Backdrop from "../../../components/Backdrop";

// contexto
import { useUserData } from "../../../context/UserDataContext";
// render props
import { WithAuthenticatedUser } from "../../../components/WithAuthenticatedUser";

function Budget(props) {
  const navigate = useNavigate();
  let { points, loginData } = useUserData();

  const [listConcess, setListConcess] = useState([]);
  const [showprice, setShowprice] = useState(false);
  const [showbackdrop, setShowbackdrop] = useState(false);

  const [dados, setDados] = useState({
    state: "",
    concessionaria: "",
    total_project_power: "",
    price: "",
    descont: 0,
  });

  const getConcess = async (value) => {
    setShowbackdrop(true);
    // const config = {
    //   headers: {
    //     Authorization: 'Bearer ' + props.user.token,
    //     'Content-Type': 'application/json',
    //   },
    // };

    await api
      .get(`/dealers/state/${value}`)
      .then((res) => {
        // console.log(res.data);
        setListConcess(res.data);
        setShowbackdrop(false);
      })
      .catch((error) => {
        console.log("Error: ", error.response.data);
        setShowbackdrop(false);
      });
  };

  const submitInfor = async (event) => {
    event.preventDefault();
    if (dados.total_project_power == 0)
      return toast.warn("Potência total do projeto não pode ser 0kWp");

    let valor = await getValuePrice(dados.total_project_power);

    let budget = {
      dealer_id: listConcess.find((a) => a.name === dados.concessionaria).id,
      power: dados.total_project_power,
      price: parseFloat(valor),
      user_id: loginData.user_id,
      full_name: null,
      email: null,
      // phone_number: null,
    };

    // configurações
    // const config = {
    //   headers: {
    //     Authorization: 'Bearer ' + props.user.token,
    //     'Content-Type': 'application/json',
    //   },
    // };

    api
      .post("budgeting", budget)
      .then((res) => {
        // console.log('Enviado: ', res.data);
      })
      .catch((error) => console.log(error.response.data));
  };

  const getValuePrice = (total_project_power) => {
    let power = total_project_power;
    let price = 0;

    if (power > 0 && power <= 5) {
      price = 750.0;
    } else if (power > 5 && power <= 10) {
      price = 900.0;
    } else if (power > 10 && power <= 20) {
      price = 1200.0;
    } else if (power > 20 && power <= 40) {
      price = 1500.0;
    } else if (power > 40 && power <= 75) {
      price = 2000.0;
    } else if (power > 75 && power <= 100) {
      price = power * 40;
    } else if (power > 100 && power <= 200) {
      price = power * 37;
    } else if (power > 200 && power <= 300) {
      price = power * 33;
    } else if (power > 300 && power <= 400) {
      price = power * 28;
    } else if (power > 400 && power <= 500) {
      price = power * 25;
    } else if (power > 500 && power <= 1000) {
      price = power * 24;
    }

    setDados({ ...dados, price: parseFloat(price) });
    setShowprice(true);
    return price;
  };
  const onInputChange = (e) => {
    if (e.target.name == "cpf") {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "999.999.999-99"),
      });
    } else if (e.target.name == "cnpj") {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "99.999.999/9999-­99"),
      });
    } else if (
      e.target.name === "qtd_panel" ||
      e.target.name === "qtd_inverters"
    ) {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "99999999"),
      });
    } else if (e.target.name === "client_count" && e.target.value === "está") {
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
        document_procuration: "",
      });
    } else if (e.target.name === "have_list_materials") {
      if (e.target.value === "have") {
        setDados({
          ...dados,
          [e.target.name]: e.target.value,
          document_panel_datasheet: "",
          document_inverters_datasheet: "",
          qtd_panel: "",
          qtd_inverters: "",
          panel_model: "",
          inverters_model: "",
        });
      } else if (e.target.value === "not_have") {
        setDados({
          ...dados,
          [e.target.name]: e.target.value,
          document_materials: "",
        });
      }
    } else if (e.target.name === "total_project_power") {
      if (e.target.value >= 0) {
        if (e.target.value > 1000) {
          return toast.warn("Valor máximo de 1000kWp");
        }

        setDados({
          ...dados,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "other_value_disjuntor") {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "999"),
      });
    } else if (
      e.target.name === "power_panel" ||
      e.target.name === "power_inverters"
    ) {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "9999"),
      });
    } else if (e.target.name === "latitude" || e.target.name === "longitude") {
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "state") {
      getConcess(e.target.value);
      setDados({ ...dados, [e.target.name]: e.target.value });
    } else {
      setDados({ ...dados, [e.target.name]: e.target.value });
    }
  };

  return (
    <Container>
      <Page>
        <header>
          <h1>Orçamento</h1>
          <span>
            Faça um orçamento online instantaneamente e solicite seu projeto.
          </span>
        </header>
        <content>
          <div
            className="div__multiColumn"
            style={{ margin: "auto", maxWidth: "1100px", flexWrap: "wrap" }}
          >
            <div className="div__multiColumn">
              <img
                src={Image03}
                alt="img_orcamento"
                className="img_orcamento"
              />
            </div>
            <div className="div__oneRow" style={{ flex: 1 }}>
              <form onSubmit={submitInfor}>
                <div style={{ maxWidth: "400px" }}>
                  <div className="div__multiColumn">
                    <select
                      required
                      name="state"
                      style={{ flex: 1 }}
                      value={dados.state}
                      onChange={onInputChange}
                      disabled={showprice ? true : false}
                    >
                      <option value="" disabled selected>
                        Estado
                      </option>
                      <option value="AC">AC</option>
                      <option value="AL">AL</option>
                      <option value="AP">AP</option>
                      <option value="AM">AM</option>
                      <option value="BA">BA</option>
                      <option value="CE">CE</option>
                      <option value="DF">DF</option>
                      <option value="ES">ES</option>
                      <option value="GO">GO</option>
                      <option value="MA">MA</option>
                      <option value="MT">MT</option>
                      <option value="MS">MS</option>
                      <option value="MG">MG</option>
                      <option value="PA">PA</option>
                      <option value="PB">PB</option>
                      <option value="PR">PR</option>
                      <option value="PE">PE</option>
                      <option value="PI">PI</option>
                      <option value="RJ">RJ</option>
                      <option value="RN">RN</option>
                      <option value="RS">RS</option>
                      <option value="RO">RO</option>
                      <option value="RR">RR</option>
                      <option value="SC">SC</option>
                      <option value="SP">SP</option>
                      <option value="SE">SE</option>
                      <option value="TO">TO</option>
                    </select>
                  </div>
                  <div className="div__multiColumn">
                    <select
                      required
                      disabled={showprice ? true : false}
                      name="concessionaria"
                      style={{ flex: 2 }}
                      value={dados.concessionaria}
                      onChange={onInputChange}
                    >
                      {!dados.concessionaria && (
                        <option value="" disabled selected>
                          Nome da concessionária
                        </option>
                      )}
                      {dados.concessionaria && (
                        <option
                          value={dados.concessionaria}
                          disabled
                          selected
                          style={{ textTransform: "cap" }}
                        >
                          {dados.concessionaria}
                        </option>
                      )}
                      {listConcess.length > 0 &&
                        listConcess.map((concess, idx) => (
                          <option key={idx} value={concess.name}>
                            {concess.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="div__multiColumn">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        type="number"
                        min="0"
                        name="total_project_power"
                        value={dados.total_project_power}
                        placeholder="Potência total do projeto"
                        className="input__number"
                        step="0.01"
                        style={{
                          flex: 1,
                          width: "100%",
                          // maxWidth: window.innerWidth > 675 && '280px',
                        }}
                        onChange={onInputChange}
                        disabled={showprice ? true : false}
                        required
                      />
                      <div className="pos_field">kWp</div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={
                      showprice ? "btn__finish_disabled" : "btn__finish"
                    }
                  >
                    Realizar Orçamento
                  </button>
                </div>
              </form>
            </div>
            {points > 0 && (
              <div className="div__multiColumn" style={{ flex: 1 }}>
                <div className="div__desconto_tag">
                  <p> Desconto acumulado para utilizar em seus projetos!</p>
                  <span>{points}%</span>
                </div>
              </div>
            )}
          </div>
          {showprice && (
            <div className="div__image_solar">
              <img src={solarImage2} alt="panel_solar_image" />
            </div>
          )}
          {showprice &&
            (dados.total_project_power > 1000 ? (
              <div className="div__orcamento_field">
                <p>
                  Nosso sistema viabiliza orçamento online para projetos de até
                  1000kWp, para potências superiores sugerimos que{" "}
                  <b>entre em contato</b> e solicite seu orçamento
                  personalizado.
                </p>
              </div>
            ) : (
              <div className="div__orcamento_field">
                <p>Seu projeto custará</p>
                {points > 0 && <h2>R$ {dados.price}</h2>}
                {points === 0 && <h1>R$ {dados.price}</h1>}
                {points === 0 && (
                  <p>
                    Solicite seu projeto e receba 1% de desconto de forma
                    acumulativa nos próximos projetos.
                  </p>
                )}
                <br />
                {points > 0 && (
                  <p>Com desconto acumulado de {points}% o preço final será </p>
                )}
                {points > 0 && (
                  <h1>
                    R${" "}
                    {(
                      "" +
                      (dados.price - (dados.price * points) / 100).toFixed(2)
                    ).replace(".", ",")}
                  </h1>
                )}

                <FieldButtons delete={props.delete}>
                  <button
                    onClick={() => setShowprice(false)}
                    className="btn__retornar"
                    color="primary"
                  >
                    Refazer orçamento
                  </button>
                  <button
                    onClick={() =>
                      navigate(
                        `/novoprojeto?state=${dados.state}&concessionaria=${dados.concessionaria}&total_project_power=${dados.total_project_power}`,
                      )
                    }
                    className="btn__register"
                  >
                    Solicitar projeto
                  </button>
                </FieldButtons>
              </div>
            ))}
        </content>
      </Page>
      {showbackdrop && <Backdrop />}
    </Container>
  );
}

function BudgetLogged() {
  return <WithAuthenticatedUser render={() => <Budget />} />;
}

export default BudgetLogged;
