// api's de teste
// export const API_URL = 'https://api-tellar.luandeveloper.online';
export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = 'http://25.56.136.32:3333';

// api principal
// export const API_URL = 'https://api.tellarsolucoes.com.br';

export const listetapas = [
  '1 - Análise de documentos pela equipe Tellar',
  '2 - Projeto em elaboração',
  '3 - Análise de documentação',
  '4 - Análise técnica',
  '5 - Projeto Aprovado',
  '6 - Projeto Reprovado',
];

// configurações de sistema
export const SITE_NAME = 'Portal Tellar Soluções';
