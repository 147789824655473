import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { Container, FielButtons } from './styles';
import api from '../../services/api';
import Backdrop from '../Backdrop';
import { toast } from 'react-toastify';

export default function Dialogoverificaralteracao(props) {
  const [open, setOpen] = React.useState(true);
  const [email, setEmail] = React.useState('');
  const [showbackdrop, setShowbackdrop] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const setNewPassword = (event) => {
    event.preventDefault();
    setShowbackdrop(true);

    api
      .patch(`login-data/forgot-password`, { email: email })
      .then((res) => {
        setShowbackdrop(false);
        toast.success('E-mail para recuperar senha foi enviado com sucesso!');
        handleClose();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
        setShowbackdrop(false);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 999991,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>Recuperação de Senha</h1>
                <span>
                  Digite seu email cadastrado para que se possa realizar a
                  recuperação.
                </span>
              </header>
              <form className="div__oneRow" onSubmit={setNewPassword}>
                <input
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  autoFocus
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                />
                <button className="btn__register" type="submit">
                  Enviar
                </button>
              </form>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
      {showbackdrop && <Backdrop />}
    </div>
  );
}
