import React, { useState, useMemo, useCallback, useEffect } from 'react';

// busca por geolocalização
import { geosearch, arcgisOnlineProvider } from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

import { BsFillPinFill, BsPinAngleFill } from 'react-icons/bs';

import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import { Container, DivButtons, Conteudo } from './styles';
import { red } from '@material-ui/core/colors';

const positionProvider = { latitude: -3.6884, longitude: -40.3551, zoom: 13 };

// alterar icone no mapa
var locationMartkerSolidIcon = L.icon({
  iconUrl:
    'https://www.alanaraujo.eng.br/static/media/seguranca-trabalho.c1024fe1.png',
  // shadowUrl: BsPinAngleFill,

  iconSize: [25, 25], // size of the icon
  iconAnchor: [0, 20], // point of the icon which will correspond to marker's location
  popupAnchor: [13, -30], // point from which the popup should open relative to the iconAnchor
});

function DisplayPosition({ map, provider, setLocaleSelected }) {
  const [position, setPosition] = useState(map.getCenter());

  // console.log('ZOOM: ', map.getZoom());

  const onClick = useCallback(
    ({ latitude, longitude, zoom }) => {
      map.setView([latitude, longitude], zoom);
    },
    [map]
  );

  const onMove = useCallback(() => {
    setPosition(map.getCenter());
  }, [map]);

  useEffect(() => {
    map.on('move', onMove);

    return () => {
      map.off('move', onMove);
    };
  }, [map, onMove]);

  useEffect(() => {
    // console.log('Centro: ', map.getCenter(), '- zoom:', map.getZoom());
  }, [position]);

  useEffect(() => {
    if (!map) return;

    const control = geosearch({
      position: 'topright',
      placeholder: 'Buscar localização',
      useMapBounds: false,
      providers: [
        arcgisOnlineProvider({
          apikey:
            'AAPK64e86b8a7b0345da8f94014704c5bddb4kWKfa94A0zSZhJxzOImphcHIjnmSOjcWttyYZ_jH5EjUvoz9NgxB3IhXduA7B2Q', // replace with your api key - https://developers.arcgis.com
        }),
      ],
    });
    control.addTo(map);
  }, []);

  return (
    <DivButtons>
      {/* latitude: {position.lat.toFixed(4)}, longitude: {position.lng.toFixed(4)}{' '} */}

      {/* <button
        onClick={() => {
          onClick({
            latitude: provider.latitude,
            longitude: provider.longitude,
            zoom: provider.zoom,
          });
          setLocaleSelected(null);
        }}
      >
        reset
      </button>
      {provider.locales.map((locale) => (
        <button
          onClick={() => {
            onClick({
              latitude: locale.latitude,
              longitude: locale.longitude,
              zoom: 15,
            });
            setLocaleSelected(locale.id);
          }}
        >
          {locale.type}
        </button>
      ))} */}
    </DivButtons>
  );
}

function Mapa() {
  const [map, setMap] = useState(null);
  const [localeSelected, setLocaleSelected] = useState(null);

  const [provider, setProvider] = useState({
    id: 1,
    latitude: -3.693239488115575,
    longitude: -40.35466074943543,
    zoom: 13,
    state: 'CE',
    city: 'Sobral',
    locales: [
      {
        id: 1,
        latitude: -3.6871101,
        longitude: -40.3549084,
        zoom: 16,
        principal: true,
        type: 'Brisanet Centro',
        street: 'R. Conselheiro José Júlio',
        number: '877',
        district: 'Centro',
        zip_code: '62010-820',
      },
      {
        id: 2,
        latitude: -3.6814711,
        longitude: -40.3397704,
        zoom: 16,
        principal: false,
        type: 'Container',
        street: 'Av. Dr. Guarani',
        number: '231',
        district: 'Derby Clube',
        zip_code: '62042-030',
      },
      {
        id: 3,
        latitude: -3.6976312,
        longitude: -40.3481958,
        zoom: 16,
        principal: false,
        type: 'Brisanet Shopping',
        street: 'Shopping - Av. Monsenhor Aloísio Pinto',
        number: '300',
        district: 'Dom Expedito',
        zip_code: '62050-255',
      },
      {
        id: 4,
        latitude: -3.7040957,
        longitude: -40.3334114,
        zoom: 16,
        principal: false,
        type: 'Casa do Luan',
        street: 'Rua Professora Artemísia',
        number: '550',
        district: 'Sinhá Saboia',
        zip_code: '62050-410',
      },
    ],
  });

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={[provider.latitude, provider.longitude]}
        zoom={provider.zoom}
        scrollWheelZoom={true}
        whenCreated={setMap}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {provider.locales.map((locale) => (
          <Marker
            icon={locationMartkerSolidIcon}
            position={[locale.latitude, locale.longitude]}
          >
            <Popup>
              {locale.type} <br /> {locale.principal ? 'Principal' : 'Filial'}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    ),
    []
  );

  return (
    <Container BsPin={BsFillPinFill}>
      {map ? (
        <DisplayPosition
          map={map}
          provider={provider}
          setLocaleSelected={setLocaleSelected}
        />
      ) : null}
      {displayMap}
      <div className="div__mapa">
        <BsFillPinFill className="icon__marker" />
        <BsPinAngleFill className="icon__shadow" />
      </div>
    </Container>
  );
}

export default Mapa;
