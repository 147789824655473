import { ReactNode, useState } from 'react';

import { Container, FielButtons } from './styles';

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SimpleBackdrop from '../Backdrop';
import { toast } from 'react-toastify';
import api from '../../services/api';

function Chatbox(props) {
  const [enviado, setEnviado] = useState(false);
  const [showbackdrop, setShowbackdrop] = useState(false);
  const [dados, setDados] = useState({
    project_id: props.dados.id,
    title: '',
    description: '',
  });

  const handleInput = (event) => {
    setDados({ ...dados, [event.target.name]: event.target.value });
  };

  const enviarSolicitacao = (event) => {
    event.preventDefault();
    setShowbackdrop(true);

    const config = {
      headers: {
        // Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
      },
    };

    api
      .post(`/project-change-requests`, dados, config)
      .then((res) => {
        setShowbackdrop(false);
        toast.success('Solicitação enviada com sucesso!');
        props.fechar();
      })
      .catch((error) => {
        console.log('Error: ', error.response.data);
        toast.error(error.response.data.message);
        setShowbackdrop(false);
      });
  };
  return (
    <Container>
      <h3>Solicitar alteração</h3>
      <form onSubmit={enviarSolicitacao}>
        <div className="div__corpo">
          <label for="name_project">Projeto</label>
          <span>
            {' '}
            <AssignmentOutlinedIcon /> {props.dados.client_name}
          </span>
          <label for="title">O que deseja alterar*</label>
          <input
            id="title"
            name="title"
            required
            onChange={handleInput}
          ></input>
          <label for="description">Detalhes da alteração*</label>
          <textarea
            id="description"
            name="description"
            onChange={handleInput}
            required
          ></textarea>

          <FielButtons>
            <button
              type="button"
              className="btn__retornar"
              onClick={() => props.fechar()}
            >
              Cancelar
            </button>
            <button type="submit" className="btn__register">
              Solicitar
            </button>
          </FielButtons>
        </div>
      </form>

      {showbackdrop && <SimpleBackdrop />}
    </Container>
  );
}

export default Chatbox;
