import React, { useEffect, useState } from 'react';

// bibliotecas
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

// dialogo de carrinho de compras
import Dialogoshoppingcart from '../Dialogoshoppingcart';

// styles
import { Container, Divdesconto } from './styles';
import { withStyles } from '@material-ui/core/styles';

// icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// contexts
import { useAuth } from '../../context/AuthContext';
import { useUserData } from '../../context/UserDataContext';

// config
import { SITE_NAME } from '../../config';
import Dialogocompletardados from '../Dialogocompletardados';

// style of badge
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 15,
    top: 20,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0px',
    margin: '0px',
  },
}))(Badge);

export default function Header({ open }) {
  const { user, signout } = useAuth();
  const { person, points, getShoppingcart, listshopping, completedData } =
    useUserData();
  const [showShoppingCart, setShowShoppingCart] = useState(false);

  // useEffect(() => {
  //   console.log(user?.user?.person?.company_id);
  // }, []);

  useEffect(() => {
    getShoppingcart(user?.user?.person?.company_id);
  }, [user]);

  return (
    <Container open={open}>
      <div className="texts">
        <h2>{SITE_NAME}</h2>
        <div className="data">
          <Divdesconto>
            <small>Desconto</small> {points}%
          </Divdesconto>

          {person && person.full_name}
          <br />
          {user && user.email}
        </div>
      </div>
      <div className="div__payment_field">
        <IconButton aria-label="cart" onClick={() => setShowShoppingCart(true)}>
          <StyledBadge badgeContent={listshopping?.length} color="secondary">
            <ShoppingCartIcon />
          </StyledBadge>
        </IconButton>
      </div>
      <button className="buttonSignout" onClick={() => signout()}>
        <ExitToAppIcon />
      </button>
      {showShoppingCart &&
        (completedData ? (
          <Dialogoshoppingcart
            listshopping={listshopping}
            fechar={() => setShowShoppingCart(false)}
          />
        ) : (
          <Dialogocompletardados fechar={() => setShowShoppingCart(false)} />
        ))}
    </Container>
  );
}
