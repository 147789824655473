import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-family: 'Poppins', sans-serif;

  h1 {
    /* text-transform: uppercase; */
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0rem;
    color: var(--primary2);
  }
  span {
    font-size: 0.9rem;
  }
  h4 {
    /* background-color: red; */
    text-transform: uppercase;
    margin: 1rem 0rem;
    font-size: 0.8rem;
    color: rgba(var(--black_rgb), 0.5);
    font-weight: 400;
    width: 100%;
    /* padding: 0rem 2rem; */
    flex: 1;
    /* margin-left: 0rem; */
    text-align: left;

    ::after {
      display: block;
      content: '';
      margin: 0.5rem 0rem;
      border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
    }
  }

  p {
    flex: 1;
    margin: 0rem;
    font-size: 0.9rem;
  }
  label {
    font-weight: 600;
  }
  .div__oneRow {
    display: flex;

    .tableContact {
      width: 100%;
      display: flex;
      flex-direction: column;

      thead {
        display: flex;
        width: 100%;
        
        tr {
          display: flex;
          width: 100%;

          th {
            &.title_type {
              flex: 1;
            }
            
            &.title_number {
              flex: 1.5;
            }
            
            &.title_wpp {
              flex: 1;
            }

            &.title_actions {
              flex: 0.5;
              text-align: right;
            }

            + th {
              margin-left: 1rem;
            }
          }
        }
      }

      tbody {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        tr {
          display: flex;
          width: 100%;     

          td {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &.item_type {
              flex: 1;

              select {
                height: 40px;
                padding: .5rem 1rem;
                margin: 1rem 0;
              }
            }
            
            &.item_number {
              flex: 1.5;

              input {
                margin: 0;
                padding: 0;

                width: 75%;
                height: 23px;
                padding: .5rem 1rem;
                margin: 1rem 0;
              }
            }
            
            &.item_wpp {
              flex: 1;

              input {
                width: 10%;
                margin: .5rem 0;
              }
            }

            &.item_actions {
              flex: 0.5;
              justify-content: flex-end;

              svg {
                cursor: pointer;
                margin-right: 1rem;
              }
            }

            + td {
              margin-left: 1rem;
            }
          }
        }
      }

      .containerTableButton {

        button {
          cursor: pointer;
          border: 0;
          outline: none;
          margin-top: 1rem;
          margin-left: .25rem;
          padding: .4rem .75rem;
          background: none;

          border: 1px solid #545b64;
          color: #545b64;
          font-weight: bold;
          font-size: .8rem;

          :hover {
            border: 1px solid #000;
            color: #000;
          }
        }
      }
    }
  }

  input,
    select {
      outline-color: var(--primary2);
      padding: 0.75rem 0.5rem;
      border: 1px solid rgba(var(--black_rgb), 0.3);
      border-radius: 0.5rem;
      margin: 0.5rem 0.25rem;
      width: 100%;
      z-index: 0;
    }
  }

  @media screen and (max-width: 675px) {
    .div__oneRow {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const FielButtons = styled.div`
  display: flex;
  /* background: red; */
  align-items: center;

  .btn__retornar {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    width: 100%;
    max-width: 180px;
    height: 35px;
    background-color: transparent;
    border: 1px solid var(--primary2);
    border-radius: 1.4rem;
    color: var(--primary2);
    /* margin-top: 1rem; */
    margin-right: 0.75rem;
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
    }
  }
  .btn__register {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;

    width: 320px;
    height: 45px;
    background-color: var(--primary2);
    border: 0px;
    border-radius: 1.4rem;
    color: var(--white);
    /* margin-top: 1rem; */
    /* margin-bottom: 0.75rem; */
    cursor: pointer;
    :hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
      filter: brightness(1.4);
    }
  }

  @media screen and (max-width: 675px) {
    flex-direction: column-reverse;
    flex: 1;

    .btn__retornar,
    .btn__register {
      /* margin: auto; */
      width: 100% !important;
      max-width: none;
      margin: 0.5rem;
      font-size: 1rem;
    }
  }
`;
