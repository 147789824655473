import axios from 'axios';
import { addYears, eachYearOfInterval } from 'date-fns';
import { ReactNode, useRef, useState } from 'react';
import { mask } from 'remask';
// rotas
import { Prompt } from 'react-router-dom';
import Copycomponent from '../Copycomponent';

import { Container } from './styles';

// images
import Qrcodeexample from '../../assets/images/prints/qrcodeexample.png';
import QRCode from 'qrcode.react';

// icones
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import CropFreeIcon from '@material-ui/icons/CropFree';
import CheckIcon from '@material-ui/icons/Check';
import api from '../../services/api';
import { useUserData } from '../../context/UserDataContext';
import { toast } from 'react-toastify';
import Backdrop from '../Backdrop';

function Pixpaymentmethod(props) {
  const idinterval = useRef();
  const {
    addressPerson,
    loginData,
    numbersContact,
    person,
    company,
    getShoppingcart,
    getPoints,
  } = useUserData();
  const [dataPix, setDataPix] = useState({
    imageUrl: '',
    idPayment: '',
  });
  const [showbackdrop, setShowbackdrop] = useState(false); //backdrop

  // consulta periódica
  const [synchronize, setSynchronize] = useState(false);

  const handleSubmitPayment = async (event) => {
    setShowbackdrop(true); //backdrop

    const paymentData = {
      receipts: props.selectedProjects,
      payment_type: 'pix',
      installments: '1',
      customer_name: person.full_name,
      customer_email: loginData.email,
      customer_mobile: numbersContact[0].number,
      customer_document: company.cnpj ? company.cnpj : person.cpf,
      billing_address: addressPerson.street,
      billing_number: addressPerson.number,
      billing_neighborhood: addressPerson.district,
      billing_state: addressPerson.state,
      billing_city: addressPerson.city,
      billing_zip_code: addressPerson.zip_code,
      credits_discount: props.credits.value,
      coupon_code: props.cupons.code,
    };

    await api
      .post('/transactions', paymentData)
      .then(async (res) => {
        let auxData = JSON.parse(res.data.processor_response);
        setDataPix({
          ...dataPix,
          imageUrl: auxData.pix_qr_code,
          idPayment: res.data.id,
        });

        props.setPaymentInitiated(true);
        getDataEventSync(res.data.id);
        // getShoppingcart(company?.id);
        // getPoints(company?.id);
        // props.setPaymentSuccess(true);
        // setTimeout(() => (window.location = '/meusprojetos'), 5000);

        // setShowbackdrop(false);
        // id_project = res.data.id;
        // toast.success('Projeto cadastrado com sucesso!');
        // navigate('/meusprojetos');
      })
      .catch((error) => {
        console.log('Erro: ', error.response.data.message);
        // setShowbackdrop(false);
        toast.warn(error.response.data.message);
      })
      .finally(() => setShowbackdrop(false));
  };

  // buscar dados de forma periódica
  const getDataEventSync = (paymentID) => {
    var aux = setInterval(async () => {
      await api
        .get(`/transactions/${paymentID}`)
        .then((res) => {
          if (res.data.status === 'approved') {
            cancelDataEventSync();

            getShoppingcart(company?.id);
            getPoints(company?.id);
            props.setPaymentSuccess(true);
            setTimeout(() => (window.location = '/meusprojetos'), 5000);
          } else if (
            res.data.status === 'refused' ||
            res.data.status === 'refunded'
          ) {
            cancelDataEventSync();

            getShoppingcart(company?.id);
            getPoints(company?.id);
            toast.error('Pagamento recusado, tente novamente');
            setTimeout(() => (window.location = '/comprovantes'), 10 * 1000);
          }
        })
        .catch((error) => {
          console.log('erro recibos:', error.response);
        });
    }, 30 * 1000);

    setSynchronize(true);
    idinterval.current = aux;
  };
  // cancela busca periódica
  const cancelDataEventSync = () => {
    setSynchronize(false);
    clearInterval(idinterval.current);
  };

  return (
    <Container>
      <div className="div__page_body_method_slip">
        <label
          style={{ textAlign: 'justify' }}
          onClick={() => cancelDataEventSync()}
        >
          {' '}
          Pague com pix a qualquer momento e lugar utilizando apenas o seu
          celular. Escaneie o QR Code e efetue o a transação em poucos segundos.
        </label>
        <div className="div__field_qrcode_image">
          {dataPix.imageUrl ? (
            <div className="div__oneColumn">
              <QRCode
                value={dataPix.imageUrl}
                renderAs="canvas"
                imageSettings={{
                  height: 100,
                  width: 100,
                }}
              />
              <div className="div__data_copy">
                <label>
                  <Copycomponent name="código PIX" data={dataPix.imageUrl} />
                </label>
                <input type="text" value={dataPix.imageUrl} disabled />
              </div>
            </div>
          ) : (
            <div className="div__await_qrcode">
              Feche a compra para gerar QR Code
            </div>
          )}
        </div>
        <label>
          <SmartphoneIcon /> Pegue o celular e abra o aplicativo de sua
          preferência para efetuar o pagamento
        </label>
        <label>
          <CropFreeIcon /> Abra o ambiente PIX, selecione a opção de efetuar
          pagamento com QR Code e escaneie o código apresentado acima
        </label>
        <label>
          <CheckIcon /> Confirme as informações e prossiga para finalizar o
          pagamento
        </label>

        <button
          type="submit"
          className={
            dataPix.imageUrl
              ? 'btn__confirm_payment btn__confirm_payment_disabled'
              : 'btn__confirm_payment'
          }
          onClick={() => handleSubmitPayment()}
          disabled={dataPix.imageUrl ? true : false}
        >
          Fechar compra
        </button>
      </div>
      {showbackdrop && (
        <Backdrop
          message={'Sua solicitação está sendo processada, por favor aguarde.'}
        />
      )}
    </Container>
  );
}

export default Pixpaymentmethod;
