import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Container, FielButtons, ConfirmacaoCadastro } from './styles';

// mensagem
import { toast } from 'react-toastify';

// ICONES
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

// api
import api from '../../../../services/api';

import imgMessage from '../../../../assets/images/message.png';

import Backdrop from '../../../../components/Backdrop';

export default function AlertDialog({
  fechar,
  personData,
  addressDataPerson,
  companyData,
  addressDataCompany,
  loginData,
}) {
  const [open, setOpen] = React.useState(true);
  const [cadastroEfetuado, setCadastroEfetuado] = React.useState(false);
  const [showbackdrop, setShowbackdrop] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => fechar(), 100);
  };

  const handleRegisterUser = async () => {
    setShowbackdrop(true);
    companyData.cnpj = companyData.cnpj ? companyData.cnpj : null;

    let dadosReq = {
      loginData,
      addressDataPerson: addressDataPerson?.zip_code ? addressDataPerson : null,
      personData,
      companyData,
      addressDataCompany: addressDataCompany?.zip_code
        ? addressDataCompany
        : null,
      is_tellar: false,
      is_tellar_admin: false,
    };

    await api
      .post('/user-created', dadosReq)
      .then((res) => {
        setShowbackdrop(false);
        setCadastroEfetuado(true);
      })
      .catch((error) => {
        return toast.warn(error.response.data.error);
        setShowbackdrop(false);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!cadastroEfetuado ? (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Container>
                  <header>
                    <h1>Confirme seus dados</h1>
                    <span>
                      Confira atentamente os dados abaixo e finalize sua
                      inscrição.
                    </span>
                    <content>
                      <h4>Dados pessoais</h4>
                      <div className="div__oneRow">
                        <p>
                          <label>Nome: </label> {personData.full_name}
                        </p>
                        <p>
                          <label>CPF: </label> {personData.cpf}
                        </p>
                      </div>
                      <div className="div__oneRow">
                        <p>
                          <label>E-mail: </label> {loginData.email}
                        </p>
                      </div>

                      <h4>Endereço</h4>
                      {addressDataPerson?.zip_code ? (
                        <>
                          <div className="div__oneRow">
                            <p>
                              <label>Estado: </label> {addressDataPerson.state}
                            </p>
                            <p>
                              <label>Cidade: </label> {addressDataPerson.city}
                            </p>
                          </div>
                          <div className="div__oneRow">
                            <p>
                              <label>Rua: </label> {addressDataPerson.street}
                            </p>
                            <p>
                              <label>Nº: </label> {addressDataPerson.number}
                            </p>
                          </div>
                          <div className="div__oneRow">
                            <p>
                              <label>Bairro: </label>{' '}
                              {addressDataPerson.district}
                            </p>
                            <p>
                              <label>Complemento: </label>{' '}
                              {addressDataPerson.complement
                                ? addressDataPerson.complement
                                : ' - '}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="div__oneRow">
                          <p>Dados de endereço não informado.</p>
                        </div>
                      )}

                      <h4>Dados da empresa</h4>
                      {companyData?.cnpj && (
                        <div className="div__oneRow">
                          <p>
                            <label>CNPJ: </label> {companyData.cnpj}
                          </p>
                        </div>
                      )}
                      {companyData?.fantasy_name && (
                        <div className="div__oneRow">
                          <p>
                            <label>Nome fantasia: </label>{' '}
                            {companyData.fantasy_name}
                          </p>
                        </div>
                      )}
                      {companyData?.social_reason && (
                        <div className="div__oneRow">
                          <p>
                            <label>Razão social: </label>{' '}
                            {companyData.social_reason}
                          </p>
                        </div>
                      )}
                      <div className="div__oneRow">
                        <p>
                          <label>Cargo: </label> {personData?.role}
                        </p>
                      </div>
                      {companyData?.cnpj && (
                        <div className="div__oneRow">
                          <p>
                            <label>Registro Estadual: </label>{' '}
                            {companyData.state_registration
                              ? companyData.state_registration
                              : ' - '}
                          </p>
                          <p>
                            <label>Registro Municipal: </label>{' '}
                            {companyData.municipal_registration
                              ? companyData.municipal_registration
                              : ' - '}
                          </p>
                        </div>
                      )}

                      <h4>Endereço da empresa</h4>
                      {addressDataCompany?.zip_code ? (
                        <>
                          <div className="div__oneRow">
                            <p>
                              <label>Estado: </label> {addressDataCompany.state}
                            </p>
                            <p>
                              <label>Cidade: </label> {addressDataCompany.city}
                            </p>
                          </div>
                          <div className="div__oneRow">
                            <p>
                              <label>Rua: </label> {addressDataCompany.street}
                            </p>
                            <p>
                              <label>Nº: </label> {addressDataCompany.number}
                            </p>
                          </div>
                          <div className="div__oneRow">
                            <p>
                              <label>Bairro: </label>{' '}
                              {addressDataCompany.district}
                            </p>
                            <p>
                              <label>Complemento: </label>{' '}
                              {addressDataCompany.complement
                                ? addressDataCompany.complement
                                : ' - '}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="div__oneRow">
                          <p>Dados de endereço não informado.</p>
                        </div>
                      )}
                    </content>
                  </header>
                </Container>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <FielButtons>
                <button
                  onClick={handleClose}
                  className="btn__retornar"
                  color="primary"
                >
                  Retornar
                </button>
                <button onClick={handleRegisterUser} className="btn__register">
                  Efetuar cadastro
                </button>
              </FielButtons>
            </DialogActions>
          </>
        ) : (
          <ConfirmacaoCadastro>
            <h2>Confirme sua conta atráves do email!</h2>
            <div className="content">
              <div className="containerImg">
                <img src={imgMessage} alt="Icone de mensagem" />
              </div>

              <p>
                Recebemos o seu cadastro,
                <br />
                agora você precisa confirmar sua conta <br />
                atráves do link que enviamos para seu email!
              </p>
            </div>

            <Link to="/login">Ir para login</Link>
          </ConfirmacaoCadastro>
        )}
        {showbackdrop && <Backdrop />}
      </Dialog>
    </div>
  );
}
