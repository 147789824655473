import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// backdrop
import Backdrop from '../../components/Backdrop';

// icones
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import BackupIcon from '@material-ui/icons/Backup';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import LabelIcon from '@material-ui/icons/Label';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { Container, FielButtons } from './styles';
import { toast } from 'react-toastify';
import Dialogoconfirmacao from '../Dialogoconfirmacao';

import api from '../../services/api';

// modal pdf
import Modalpdf from '../Modalpdf';

export default function Alterardados(props) {
  const [open, setOpen] = React.useState(true);
  const [showPDF, setShowPDF] = React.useState({
    open: false,
    file_pdf: '',
  });
  const [showbackdrop, setShowbackdrop] = React.useState(false);
  const [dados, setDados] = React.useState({ ...props.dados });
  const [document, setDocument] = React.useState({
    open: false,
    type: '',
    other_type: '',
    document_additional: '',
  });

  const [responsependent, setResponsependent] = React.useState([]);
  const [showconf, setShowconf] = React.useState({
    status: false,
    width: 'sm',
    title: 'Confirmar',
    description: 'Deseja realmente remover este arquivo?',
    btn_conf: 'Sim',
    btn_cancel: 'Não',
    delete: true,
    dados: '',
    temp: '',
  });

  const [listidx, setListidx] = React.useState(3);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const submitNewArchive = async (a) => {
    setShowbackdrop(true);

    // configurações;
    const config = {
      headers: {
        // Authorization: 'Bearer ' + props.user.token,
        'Content-type': 'multipart/form-data',
      },
    };

    let formData = new FormData();
    formData.append('file', document.document_additional);
    formData.append(
      'title',
      document.other_type ? document.other_type : document.type
    );
    formData.append('project_id', dados.id);

    await api
      .post(`documents`, formData, config)
      .then((res) => {
        let aux = dados;
        aux.documents.push(res.data);

        setDados({ ...aux });

        props.getProjects();
        setDocument({
          open: false,
          type: '',
          other_type: '',
          document_additional: '',
        });
        setShowbackdrop(false);
        toast.success('Novo arquivo adicionado com sucesso!');
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(error.response.data.message);
        setShowbackdrop(false);
      });
  };

  const submitNewComment = async (idPendenc, idx) => {
    setShowbackdrop(true);

    await api
      .patch(`/pendencies/comment/${idPendenc}`, {
        customer_comment: responsependent[idx].description,
      })
      .then((res) => {
        let aux = dados;
        aux.pendencies.find((a) => a.id === idPendenc).customer_comment =
          responsependent[idx].description;

        setDados({ ...dados, aux });

        let aux2 = responsependent;
        aux2[idx].show = false;
        aux2[idx].description = '';
        setResponsependent([...aux2]);

        props.getProjects();
        setShowbackdrop(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(error.response.data.message);
        setShowbackdrop(false);
      });
  };

  const contadorPendencia = (e) => {
    let aux = props.dados.pendencies.map((a) => ({
      show: false,
      description: '',
    }));
    setResponsependent([...aux]);
  };

  const removeArchive = async (archive_data) => {
    setShowbackdrop(true);

    // configurações;
    const config = {
      headers: {
        // Authorization: 'Bearer ' + props.user.token,
        // 'Content-type': 'multipart/form-data',
      },
    };
    await api
      .delete(`documents/${archive_data.id}`, config)
      .then((res) => {
        let aux = dados;
        aux.documents.splice(
          aux.documents.indexOf((a) => archive_data),
          1
        );
        setDados({ ...aux });

        props.getProjects();

        setShowbackdrop(false);

        setTimeout(() => {
          toast.success(`Arquivo ${showconf.dados.name} removido com sucesso`);

          setShowconf({
            ...showconf,
            dados: '',
            status: false,
            temp: '',
          });
        }, 100);
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(error.response.data.message);
        setShowbackdrop(false);
      });
  };

  React.useEffect(() => {
    contadorPendencia();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 99999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container
              suspended={props.dados.suspended}
              finished={props.dados.finished_at ? true : false}
            >
              <header>
                <h1>Dados do Projeto</h1>
                <span>
                  Confira abaixo os dados do projeto com mais detalhes.
                </span>
                <content>
                  <h4>Informações da Concessionária</h4>
                  <div className="div__oneRow">
                    <p>
                      <label>Estado: </label>{' '}
                      {
                        props.listConcess.find((a) => a.id === dados.dealer_id)
                          .state
                      }
                    </p>
                    <p>
                      <label>Concessionária: </label>{' '}
                      {
                        props.listConcess.find((a) => a.id === dados.dealer_id)
                          .name
                      }
                    </p>
                  </div>
                  <h4>Informações do Cliente</h4>
                  <div className="div__oneRow">
                    {dados.client_cpf && (
                      <p>
                        <label>CPF: </label> {dados.client_cpf}
                      </p>
                    )}
                    {dados.client_cnpj && (
                      <p>
                        <label>CNPJ: </label> {dados.client_cnpj}
                      </p>
                    )}
                    <p>
                      <label>Nome: </label> {dados.client_name}
                    </p>
                  </div>
                  <h4>Informações da Instalação - Unidade Geradora</h4>
                  <div className="div__oneRow">
                    <p>
                      <label>Disjuntor: </label> {dados.breaker_type}
                    </p>
                    <p>
                      <label>Valor: </label> {dados.breaker_value}
                    </p>
                    <p>
                      <label>Coordenadas: </label> {dados.latitude.slice(0, 10)}
                      , {dados.longitude.slice(0, 10)}
                    </p>
                  </div>
                  <h4>Especificações do Projeto</h4>
                  <div className="div__oneRow">
                    <p>
                      <label>Potência Total do Projeto: </label>{' '}
                      {dados.total_project_power}kW
                    </p>
                  </div>
                  <div className="div__oneRow">
                    <p>
                      <label>Qtd. de Placas: </label> {dados.amount_panels}
                    </p>
                    <p>
                      <label>Potência: </label> {dados.panel_power}
                    </p>
                    <p>
                      <label>Modelo: </label> {dados.panel_model}
                    </p>
                  </div>
                  {dados.inverters.map((invert, idx) => (
                    <div className="div__oneRow">
                      <p>
                        <label>Qtd. de Inversores:</label> {invert.amount} unid.
                      </p>
                      <p>
                        <label>Potência:</label> {invert.power_kwp} kWp
                      </p>
                      <p>
                        <label>Modelo:</label> {invert.model}
                      </p>
                    </div>
                  ))}
                  <h4>Pendências</h4>
                  <div className="div__listpendencias">
                    {dados.pendencies.length > 0 ? (
                      dados.pendencies.map((pendencia, idx) => (
                        <>
                          <div className="div__pendencias">
                            {pendencia.resolved ? (
                              <CheckCircleOutlineIcon className="icone_success" />
                            ) : (
                              <ErrorOutlineIcon className="icone_warn" />
                            )}
                            <div className="div__info" style={{ flex: 10 }}>
                              <h5>
                                {pendencia.title}{' '}
                                <span>
                                  {pendencia.file ? 'Arquivo' : 'Informação'}
                                </span>
                              </h5>

                              <p>{pendencia.description}</p>
                              {pendencia.customer_comment && (
                                <div className="div__respost_pendence">
                                  <div className="div__comment">
                                    <strong>R:</strong>{' '}
                                    {pendencia.customer_comment}
                                  </div>
                                  {/* <span className="span__edit">
                                    <EditOutlinedIcon />
                                  </span> */}
                                </div>
                              )}
                            </div>
                            <div
                              className="div__options_pendencias"
                              style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {' '}
                              {/* <button>
                              <VisibilityOutlinedIcon />
                            </button> */}
                              {pendencia.file && (
                                <a
                                  href={pendencia.file_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {' '}
                                  <button>
                                    <GetAppIcon />
                                  </button>
                                </a>
                              )}
                              <button
                                onClick={() => {
                                  let aux = responsependent;
                                  aux[idx].show = !responsependent[idx]?.show;
                                  aux[idx].description = '';
                                  setResponsependent([...aux]);
                                }}
                              >
                                <ChatOutlinedIcon />
                              </button>
                            </div>
                          </div>
                          {responsependent[idx]?.show && (
                            <div
                              className="div__oneRow"
                              style={{ alignItems: 'center' }}
                            >
                              {/* <label for="note">Nota do processo</label> */}
                              <textarea
                                id="note"
                                autoFocus
                                name="note"
                                placeholder="Resposta à pendência"
                                value={responsependent[idx]?.description}
                                onChange={(a) => {
                                  let aux = responsependent;
                                  aux[idx].description = a.target.value;
                                  setResponsependent([...aux]);
                                }}
                              />
                            </div>
                          )}
                          {responsependent[idx]?.show && (
                            <div className="div__moreFiles">
                              <button
                                type="button"
                                disabled={
                                  responsependent[idx]?.description
                                    ? false
                                    : true
                                }
                                className="btn__newFile"
                                style={{
                                  marginTop: '1rem',
                                  width: 'auto',
                                  filter:
                                    !responsependent[idx]?.description &&
                                    'grayscale(1.1)',
                                  pointerEvents:
                                    !responsependent[idx]?.description &&
                                    'none',
                                }}
                                onClick={() =>
                                  submitNewComment(pendencia.id, idx)
                                }
                              >
                                {pendencia.customer_comment
                                  ? 'Substituir resposta'
                                  : 'Enviar resposta'}
                              </button>
                            </div>
                          )}
                        </>
                      ))
                    ) : (
                      <div className="div__nada">
                        Nenhuma pendência encontrada
                      </div>
                    )}
                  </div>
                  <h4>Arquivos vinculados</h4>
                  {!props.dados.finished_at && !props.dados.suspended && (
                    <div className="div__moreFiles">
                      <button
                        type="button"
                        className="btn__newFile"
                        style={{ display: document.open ? 'none' : 'initial' }}
                        onClick={() => setDocument({ ...document, open: true })}
                      >
                        + Novo arquivo
                      </button>
                    </div>
                  )}

                  {document.open && (
                    <div className="div__field_moreFiles">
                      <div
                        style={{
                          // flex: 1,
                          // width: '100%',
                          textAlign: 'left',
                          margin: '0rem 2rem',
                        }}
                      >
                        Novo documento
                      </div>

                      <div
                        className="div__multiColumn"
                        style={{ maxWidth: '505px' }}
                      >
                        <select
                          required
                          name="type_additional"
                          style={{
                            flex: 1,
                            maxHeight: '2.6rem',
                            marginBottom: '-3rem',
                          }}
                          value={document.type}
                          onChange={(a) => {
                            if (a.target.value !== 'outro') {
                              setDocument({
                                ...document,
                                type: a.target.value,
                                other_type: '',
                              });
                            } else {
                              setDocument({
                                ...document,
                                type: a.target.value,
                              });
                            }
                          }}
                        >
                          <option value="" disabled selected>
                            Tipo de documento
                          </option>
                          <option value="Procuração">Procuração</option>
                          <option value="Documento com foto">
                            Documento com foto
                          </option>
                          <option value="outro">Outro</option>
                        </select>

                        <input
                          type="text"
                          name="other_type"
                          value={document.other_type}
                          placeholder="Qual?"
                          style={{
                            flex: 1,
                            maxWidth: window.innerWidth > 675 && '202px',
                            visibility:
                              document.type === 'outro' ? 'visible' : 'hidden',
                          }}
                          onChange={(a) =>
                            setDocument({
                              ...document,
                              other_type: a.target.value,
                            })
                          }
                          required={document.type === 'outro' ? true : false}
                        />
                      </div>
                    </div>
                  )}
                  {document.open && (
                    <input
                      type="file"
                      id={`document_additional`}
                      name={`document_additional`}
                      required
                      onChange={(event) => {
                        if (event.target.files[0]) {
                          if (
                            event.target.files[0].type === 'application/pdf' ||
                            event.target.files[0].type === 'image/png' ||
                            event.target.files[0].type === 'image/jpeg'
                          ) {
                            setDocument({
                              ...document,
                              document_additional: event.target.files[0],
                            });
                          } else {
                            toast.error(
                              'Tipo de arquivo inválido, insira .pdf, .png ou .jpeg'
                            );
                          }
                        }
                      }}
                    />
                  )}
                  {document.type && (
                    <div className="div__remove_field_arch">
                      <button
                        type="button"
                        className={
                          document.document_additional
                            ? 'btn__remove_field btn__remove_field_disabled'
                            : 'btn__remove_field'
                        }
                        onClick={() =>
                          setDocument({
                            open: false,
                            type: '',
                            other_type: '',
                            document_additional: '',
                          })
                        }
                      >
                        Remover campo
                      </button>
                    </div>
                  )}

                  {document.type && (
                    <label
                      className={
                        document.document_additional
                          ? 'div__newfieldarchive div__selectedFieldArchive'
                          : 'div__newfieldarchive'
                      }
                      htmlFor={
                        document.document_additional
                          ? ''
                          : `document_additional`
                      }
                      onClick={(event) => {
                        document.document_additional &&
                          setDocument({
                            ...document,
                            open: false,
                          });

                        document.document_additional &&
                          setTimeout(
                            () =>
                              setDocument({
                                ...document,
                                open: true,
                                document_additional: '',
                              }),
                            100
                          );
                      }}
                    >
                      <div className="div__newfieldarchive_in">
                        <div className="div__dataArchive">
                          <AttachFileIcon />
                          <div className="div__info">
                            <p style={{ textTransform: 'uppercase' }}>
                              <LabelIcon
                                style={{
                                  fontSize: '1rem',
                                  marginBottom: '-0.2rem',
                                }}
                              />{' '}
                              {document.type ? document.type : ' . . . '}{' '}
                              {document.other_type
                                ? `(${document.other_type})`
                                : ''}
                            </p>
                            <p>
                              <b>Nome: </b>
                              {document.document_additional
                                ? document.document_additional.name
                                : '...'}
                            </p>
                            <p>
                              <b>Tamanho: </b>
                              {document.document_additional
                                ? (
                                    document.document_additional.size /
                                    (1024 * 1024)
                                  ).toFixed(2) + 'Mb'
                                : '...'}
                            </p>
                            <p>
                              <b>Tipo de arquivo: </b>
                              {document.document_additional
                                ? document.document_additional.type.split(
                                    '/'
                                  )[1]
                                : '...'}
                            </p>
                          </div>
                        </div>

                        <div className="div__env_archive">
                          {document.document_additional ? (
                            <div>
                              <DeleteIcon style={{ fontSize: '1.5rem' }} />{' '}
                              Remover arquivo
                              <br /> selecionado
                            </div>
                          ) : (
                            <div>
                              <BackupIcon /> Clique para anexar a conta de
                              energia
                              <br />
                              (.png .jpeg .pdf)
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  )}
                  {document.type && (
                    <div className="div__moreFiles">
                      <button
                        type="button"
                        className="btn__newFile"
                        style={{
                          pointerEvents:
                            !document.document_additional && 'none',
                          filter:
                            !document.document_additional && 'grayscale(1)',
                        }}
                        onClick={() => submitNewArchive()}
                      >
                        Enviar novo arquivo
                      </button>
                    </div>
                  )}

                  <div className="div__listArchives">
                    {dados?.documents
                      ?.slice(0, listidx)
                      ?.map((archive, idx) => (
                        <div className="div__rowArchive">
                          <div className="div__icon">
                            <DescriptionIcon />
                          </div>
                          <div className="div__data" style={{ flex: 4 }}>
                            <p>
                              <strong>Título: </strong> {archive.title}
                            </p>
                            <p>
                              <strong>Arquivo: </strong> {archive.file}
                            </p>
                            <p>
                              Envio em{' '}
                              {new Date(archive.created_at).toLocaleDateString(
                                'pt-br',
                                {
                                  day: '2-digit',
                                  month: 'long',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                }
                              )}
                            </p>
                          </div>
                          <div className="div__options" style={{ flex: 2 }}>
                            {' '}
                            <button
                              onClick={() => {
                                let aux = archive.file.split('.');
                                if (aux[1] === 'pdf') {
                                  setShowPDF({
                                    ...showPDF,
                                    open: true,
                                    file_pdf: archive.file_url,
                                  });
                                } else {
                                  window.open(archive.file_url);
                                }
                              }}
                            >
                              <VisibilityOutlinedIcon />
                            </button>
                            {!archive.is_approved && (
                              <button
                                className="button_delete"
                                onClick={() => {
                                  setShowconf({
                                    ...showconf,
                                    status: true,
                                    dados: archive,
                                  });
                                }}
                              >
                                <DeleteOutlineOutlined />
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    {dados?.documents?.length > listidx && (
                      <div
                        className="div__viewMore"
                        onClick={() => setListidx(listidx + 3)}
                      >
                        Ver mais
                      </div>
                    )}
                  </div>
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
              style={{
                minWidth: '100px',
              }}
            >
              Voltar
            </button>
            {/* <button onClick={handleClose} className="btn__register">
              Aplicar alterações
            </button> */}
          </FielButtons>
        </DialogActions>
      </Dialog>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({ ...showconf, dados: '', status: false, temp: '' })
          }
          confirmar={() => {
            if (showconf.dados) {
              removeArchive(showconf.dados);
            }
          }}
        />
      )}
      {showbackdrop && <Backdrop />}
      {showPDF.open && (
        <Modalpdf
          fechar={() => setShowPDF({ ...showPDF, open: false, file_pdf: '' })}
          fileUrl={showPDF.file_pdf}
        />
      )}
    </div>
  );
}
