import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mask } from 'remask';

// payment methods
import Creditcardpaymentmethod from '../Creditcardpaymentmethod';
import Boletopaymentmethod from '../Boletopaymentmethod';
import Pixpaymentmethod from '../Pixpaymentmethod';

// icons
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import Pixsvg from '../Pixicon';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { toast } from 'react-toastify';

import { Container, FielButtons, CardProjects } from './styles';

import Backdrop from '../Backdrop';

// imagem
import Money01 from '../../assets/images/prints/money_02.png';

// api
import API from '../../services/api';
// contexto;
import { useUserData } from '../../context/UserDataContext';
import axios from 'axios';
import { addYears, eachYearOfInterval } from 'date-fns';
import Animation from '../../utils/Animationsuccess';

import { useNavigate } from 'react-router-dom';

export default function Dialogoshoppingcart(props) {
  let navigate = useNavigate();
  const {
    cupons,
    points,
    credits,
    selectedProjects,
    projectsAwaitingPayment,
    totalProjectPrice,
  } = props.data;
  const [open, setOpen] = React.useState(true);
  const [controlColorIcon, setControlColorIcon] = React.useState('#000');
  const [selectedMethod, setSelectedMethod] = React.useState('credit_card');
  const [dados, setDados] = React.useState(props.dados);
  const [showbackdrop, setShowbackdrop] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentInitiated, setPaymentInitiated] = useState(false);
  const [installments, setInstallments] = useState('1');

  // controle para boleto
  const [paymentBoleto, setPaymentBoleto] = useState(false);

  const [descontos, setDescontos] = React.useState({
    pontos: 0,
    cupons: 0,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.setClose(), 100);
  };

  const getValuePrice = (power) => {
    // let power = dados.total_project_power;
    let price = 0;

    if (power > 0 && power <= 5) {
      price = 750.0;
    } else if (power > 5 && power <= 10) {
      price = 900.0;
    } else if (power > 10 && power <= 20) {
      price = 1200.0;
    } else if (power > 20 && power <= 40) {
      price = 1500.0;
    } else if (power > 40 && power <= 75) {
      price = 2000.0;
    } else if (power > 75 && power <= 100) {
      price = power * 40;
    } else if (power > 100 && power <= 200) {
      price = power * 37;
    } else if (power > 200 && power <= 300) {
      price = power * 33;
    } else if (power > 300 && power <= 400) {
      price = power * 28;
    } else if (power > 400 && power <= 500) {
      price = power * 25;
    } else if (power > 500 && power <= 1000) {
      price = power * 24;
    }

    return price;
  };

  const currency = function (number) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(number);
  };

  function aditionalPayment(final_price, payment_type, installments) {
    let flat_fee = 0;
    let additional_interest = 0;

    if (payment_type === 'payment_slip') flat_fee = 3.49;

    if (payment_type === 'credit_card') {
      switch (installments) {
        case '1':
          flat_fee = 0;
          additional_interest = 0;
          break;
        case '2':
          flat_fee = 0.99;
          additional_interest = 6.47;
          break;
        case '3':
          flat_fee = 0.99;
          additional_interest = 7.46;
          break;
        case '4':
          flat_fee = 0.99;
          additional_interest = 8.45;
          break;
        case '5':
          flat_fee = 0.99;
          additional_interest = 9.44;
          break;
        case '6':
          flat_fee = 0.99;
          additional_interest = 10.43;
          break;
        case '7':
          flat_fee = 0.99;
          additional_interest = 11.42;
          break;
        case '8':
          flat_fee = 0.99;
          additional_interest = 12.41;
          break;
        case '9':
          flat_fee = 0.99;
          additional_interest = 13.4;
          break;
        case '10':
          flat_fee = 0.99;
          additional_interest = 14.39;
          break;
        case '11':
          flat_fee = 0.99;
          additional_interest = 15.38;
          break;
        case '12':
          flat_fee = 0.99;
          additional_interest = 16.37;
          break;
        default:
          return '(Erro)';
      }
    }

    const final_price_transaction =
      final_price + flat_fee + final_price * (additional_interest / 100);
    const final_increment =
      flat_fee + final_price * (additional_interest / 100);

    const price_divider =
      final_price_transaction /
      (selectedMethod === 'credit_card' ? installments : 1);

    return [currency(price_divider), currency(final_increment)];
  }

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 999991,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>
                  <ShoppingCartIcon /> Método de pagamento
                </h1>
                <span>
                  Verifique atentamente seus projetos selecionados, o valor
                  total e escolha o método de pagamento desejado.
                </span>
                {!paymentSuccess ? (
                  <content>
                    <h4>Prosseguir com pagamento</h4>
                    <div className="div__oneRow">
                      {/* <p style={{ display: 'flex', flexDirection: 'column' }}>
                      <img src={Money01} alt="Money Image" />
                      <p
                        style={{
                          margin:
                            window.innerWidth > 675 ? '0rem 1rem' : '0rem',
                        }}
                      >
                        Acumule 1% de desconto a cada projeto realizado com
                        nossa empresa. Além de 1%, também, a cada mês
                        consecutivo de projetos submetidos. Não fique de fora
                        dessa!
                      </p>
                    </p> */}
                      <div style={{ flex: 1.25, flexDirection: 'column' }}>
                        <small>
                          Selecione abaixo: <br /> <br />
                        </small>
                        <div className="div__paymentMethodBody">
                          <div className="div__methodPaymentSelect">
                            <button
                              className={
                                selectedMethod === 'credit_card' && 'selected'
                              }
                              id="creditcardpayment"
                              onClick={() => {
                                setSelectedMethod('credit_card');
                                setControlColorIcon('#000');
                                setTimeout(
                                  () =>
                                    (window.location = '#creditcardpayment'),
                                  1
                                );
                              }}
                              disabled={paymentInitiated}
                            >
                              <CreditCardIcon /> Cartão de crédito
                            </button>
                            <button
                              id="slippayment"
                              onClick={() => {
                                setInstallments('1');
                                setSelectedMethod('payment_slip');
                                setControlColorIcon('#000');
                                setTimeout(
                                  () => (window.location = '#slippayment'),
                                  1
                                );
                              }}
                              className={
                                selectedMethod === 'payment_slip' && 'selected'
                              }
                              disabled={paymentInitiated}
                            >
                              {' '}
                              <div>
                                <HorizontalSplitIcon className="icon_boleto" />
                                <HorizontalSplitIcon className="icon_boleto2" />
                              </div>
                              Boleto
                            </button>
                            <button
                              id="pixpayment"
                              onMouseMove={() =>
                                selectedMethod === 'pix'
                                  ? setControlColorIcon('#fff')
                                  : setControlColorIcon('var(--primary2)')
                              }
                              onMouseOut={() =>
                                selectedMethod === 'pix'
                                  ? setControlColorIcon('#fff')
                                  : setControlColorIcon('#000')
                              }
                              onClick={() => {
                                setInstallments('1');
                                setSelectedMethod('pix');
                                setControlColorIcon('#fff');
                                setTimeout(
                                  () => (window.location = '#pixpayment'),
                                  1
                                );
                              }}
                              className={selectedMethod === 'pix' && 'selected'}
                              disabled={paymentInitiated}
                            >
                              <Pixsvg color={controlColorIcon} /> PIX
                            </button>
                          </div>
                          <div className="div__payment_body_in">
                            {selectedMethod === 'credit_card' && (
                              <Creditcardpaymentmethod
                                selectedProjects={selectedProjects}
                                setPaymentSuccess={setPaymentSuccess}
                                credits={credits}
                                cupons={cupons}
                                setPaymentInitiated={setPaymentInitiated}
                                setInstallments={setInstallments}
                              />
                            )}
                            {selectedMethod === 'payment_slip' && (
                              <Boletopaymentmethod
                                selectedProjects={selectedProjects}
                                credits={credits}
                                cupons={cupons}
                                setPaymentInitiated={setPaymentInitiated}
                                setPaymentBoleto={setPaymentBoleto}
                              />
                            )}
                            {selectedMethod === 'pix' && (
                              <Pixpaymentmethod
                                selectedProjects={selectedProjects}
                                setPaymentSuccess={setPaymentSuccess}
                                credits={credits}
                                cupons={cupons}
                                setPaymentInitiated={setPaymentInitiated}
                              />
                            )}
                          </div>
                        </div>
                        {/* <div className="div__deck"></div> */}
                      </div>
                      <div
                        style={{
                          flex: 1,
                          flexDirection: 'column',
                          padding: '0.5rem 1.25rem',
                          marginTop: '4rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="div__oneColumn">
                          <div
                            className="div__table_price"
                            style={{ marginTop: '1rem' }}
                          >
                            <b>Resumo da compra</b>
                            <b>Preço</b>
                          </div>
                          {selectedProjects.length === 0 ? (
                            <div style={{ textAlign: 'center', width: '100%' }}>
                              <small>Selecione o(s) projeto(s)</small>
                            </div>
                          ) : (
                            selectedProjects.map((id_project, idx) =>
                              projectsAwaitingPayment.find(
                                (proj) => proj.id === id_project
                              ).project ? (
                                <div className="div__table_price">
                                  <span>
                                    - Projeto fotovoltáico{' '}
                                    {
                                      projectsAwaitingPayment.find(
                                        (proj) => proj.id === id_project
                                      ).project.total_project_power
                                    }{' '}
                                    kWp
                                  </span>
                                  <span>
                                    {currency(
                                      projectsAwaitingPayment.find(
                                        (proj) => proj.id === id_project
                                      ).original_price
                                    )}
                                  </span>
                                </div>
                              ) : (
                                <div className="div__table_price">
                                  <span>- Crédito</span>
                                  <span>
                                    {currency(
                                      projectsAwaitingPayment.find(
                                        (proj) => proj.id === id_project
                                      ).original_price
                                    )}
                                  </span>
                                </div>
                              )
                            )
                          )}
                          {credits?.value > 0 && credits?.show === false && (
                            <div className="div__table_price">
                              <span>- Créditos </span>
                              <span>-{currency(credits?.value)}</span>
                            </div>
                          )}
                          {points > 0 && (
                            <div className="div__table_price">
                              <span>- Desconto ({points}%)</span>
                              <span>
                                -
                                {currency(
                                  (totalProjectPrice -
                                    (credits?.show === false &&
                                      credits?.value)) *
                                    (points / 100)
                                )}
                              </span>
                            </div>
                          )}
                          {cupons.percentage > 0 && (
                            <div className="div__table_price">
                              <span>
                                - Cupom (
                                {cupons.code + ' ' + cupons.percentage + '%'})
                              </span>
                              <span>
                                -
                                {currency(
                                  (cupons.percentage *
                                    (totalProjectPrice -
                                      (points *
                                        (totalProjectPrice -
                                          (credits?.show === false
                                            ? parseFloat(credits?.value)
                                            : 0))) /
                                        100 -
                                      (credits?.show === false &&
                                        credits?.value))) /
                                    100
                                )}
                              </span>
                            </div>
                          )}
                          <div className="div__table_price">
                            <span>- Adicional método de pagamento: </span>
                            <span>
                              {
                                // final_price, payment_type, installments
                                aditionalPayment(
                                  totalProjectPrice -
                                    (credits?.show === false
                                      ? parseFloat(credits.value)
                                      : 0) -
                                    (cupons?.percentage *
                                      (totalProjectPrice -
                                        (points *
                                          (totalProjectPrice -
                                            (credits?.show === false
                                              ? parseFloat(credits?.value)
                                              : 0))) /
                                          100 -
                                        (credits?.show === false
                                          ? parseFloat(credits?.value)
                                          : 0))) /
                                      100 -
                                    (points *
                                      (totalProjectPrice -
                                        (credits?.show === false
                                          ? parseFloat(credits?.value)
                                          : 0))) /
                                      100,
                                  selectedMethod,
                                  installments
                                )[1]
                              }
                            </span>
                          </div>
                          {/* <div className="div__table_price">
                            <div className="div__table_sub_total">
                              <small>Subtotal: </small>
                              
                              <small>
                                <b>
                                
                                  {currency(
                                    totalProjectPrice -
                                      (credits?.show === false
                                        ? parseFloat(credits.value)
                                        : 0) -
                                      (cupons?.percentage *
                                        (totalProjectPrice -
                                          (credits?.show === false
                                            ? parseFloat(credits?.value)
                                            : 0))) /
                                        100 -
                                      (points *
                                        (totalProjectPrice -
                                          (credits?.show === false
                                            ? parseFloat(credits?.value)
                                            : 0))) /
                                        100
                                  )}
                                </b>
                              </small>
                            </div>
                          </div> */}

                          <div className="div__table_price">
                            <div className="div__table_sub_total">
                              Total{' '}
                              {selectedMethod === 'credit_card'
                                ? installments
                                : 1}
                              x{' '}
                              <b>
                                {
                                  aditionalPayment(
                                    totalProjectPrice -
                                      (credits?.show === false
                                        ? parseFloat(credits.value)
                                        : 0) -
                                      (cupons?.percentage *
                                        (totalProjectPrice -
                                          (points *
                                            (totalProjectPrice -
                                              (credits?.show === false
                                                ? parseFloat(credits?.value)
                                                : 0))) /
                                            100 -
                                          (credits?.show === false
                                            ? parseFloat(credits?.value)
                                            : 0))) /
                                        100 -
                                      (points *
                                        (totalProjectPrice -
                                          (credits?.show === false
                                            ? parseFloat(credits?.value)
                                            : 0))) /
                                        100,
                                    selectedMethod,
                                    installments
                                  )[0]
                                }
                              </b>
                            </div>
                          </div>
                        </div>
                        {/* ----------------------  */}
                      </div>
                    </div>
                  </content>
                ) : (
                  <content className="content__success">
                    <Animation
                      animationUrl="./assets/animations/success.json"
                      // @ts-ignore
                      width={'200px'}
                      // @ts-ignore
                      height={'auto'}
                      position=""
                    />
                    <h2>Pagamento confirmado com sucesso!</h2>
                  </content>
                )}
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            {!paymentSuccess && !paymentBoleto && (
              <button
                onClick={handleClose}
                className="btn__retornar"
                color="primary"
                disabled={paymentInitiated}
                style={{
                  pointerEvents: paymentInitiated && 'none',
                }}
              >
                Voltar página
              </button>
            )}
            {paymentBoleto && (
              <button
                onClick={() => (window.location = '/comprovantes')}
                className="btn__retornar"
                color="primary"
              >
                Comprovantes
              </button>
            )}
            {/* <button
              onClick={() => props.confirmar()}
              className="btn__register"
              disabled={!totalProjectPrice}
            >
              Seguir para pagamento
            </button> */}
          </FielButtons>
        </DialogActions>
        {showbackdrop && <Backdrop />}
      </Dialog>
    </div>
  );
}
