import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
  padding-right: 2rem;
`;

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  min-height: 200px;

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  header {
    padding: 1rem 2rem;
    text-align: left;
    font-family: 'Poppins', sans-serif;

    h1 {
      font-weight: 800;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    span {
      opacity: 0.9;
    }

    ::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    }
  }

  content {
    input[type='file'] {
      display: none;
    }

    .btn__remove_field {
      background: var(--delete);
      color: var(--white);
      border: 0rem;
      max-width: 250px;
      float: right;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem 0.5rem 0rem 0rem;
      margin: 0rem 0.5rem;
      margin-bottom: -2rem;
      cursor: pointer;

      :hover {
        filter: brightness(1.1);
      }
    }

    .btn__remove_field_disabled {
      pointer-events: none;
      background: lightgray;
      color: gray;
    }

    .div__newfieldarchive {
      /* height: 5rem; */
      display: flex;
      background: rgba(var(--black_rgb), 0.05);
      margin: 2rem 0rem;
      padding: 0.5rem;

      .div__newfieldarchive_in {
        display: flex;
        flex-direction: row;
        flex: 1;
        padding: 1.4rem 1rem;
        justify-content: space-between;
        border: 2px dotted rgba(var(--black_rgb), 0.25);
        cursor: pointer;

        .div__dataArchive {
          margin-top: 0.4rem;
          color: rgba(var(--black_rgb), 0.8);
          svg {
            color: rgba(var(--black_rgb), 0.8);
          }
          .div__info {
          }
        }

        .div__env_archive {
          margin: auto 1rem;
          div {
            width: 240px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
              margin: auto;
              /* margin-right: 0rem; */
              font-size: 2rem;
            }
          }
        }

        :hover {
          border: 2px dotted var(--primary2);
          color: var(--primary2) !important;
          svg,
          p {
            color: var(--primary2);
          }
        }
      }
    }

    .div__selectedFieldArchive {
      background-color: rgba(var(--primary_rgb), 0.15);
      color: var(--primary2);
      p {
        color: var(--primary2) !important;
      }
      div {
        color: var(--primary2) !important;
        svg {
          color: var(--primary2) !important;
        }
      }
      .div__newfieldarchive_in {
        border: 2px dotted rgba(var(--primary2_rgb), 0.2);
      }

      :hover {
        background-color: rgba(var(--delete_rgb), 0.05);

        .div__newfieldarchive_in {
          border: 2px dotted var(--delete);
        }

        p {
          color: var(--delete) !important;
        }
        div {
          color: var(--delete) !important;
          svg {
            color: var(--delete) !important;
          }
        }
      }
    }

    .campodefiltro {
      padding: 0rem 1rem;
      width: 100%;
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      input {
        min-height: 25px;
      }
      button {
        margin: 0rem 1rem;
        padding: 0.75rem 1.5rem;
        background-image: linear-gradient(to left top, #082c84, #0aade5);
        color: #fff;
        border: 0px solid rgba(0, 0, 0, 0);
        transition: 0.3s;

        :hover {
          transition: 0.3s;
          background-image: linear-gradient(to right top, #082c84, #0aade5);
        }
      }

      @media only screen and (max-width: 675px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .filtro_aplicado {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-bottom: 2rem;
        }

        button {
          padding: 1.25rem 3rem;
        }
      }
    }

    h4 {
      /* background-color: red; */
      display: flex;
      text-align: left;
      margin: 1rem 2rem;
      padding-bottom: 0.5rem;
      font-weight: 600;
      color: rgba(var(--black_rgb), 0.8);
      border-bottom: 1px dotted rgba(var(--black_rgb), 0.2);
    }
    input,
    select {
      outline-color: var(--primary2);
      padding: 0.75rem 0.5rem;
      border: 1px solid rgba(var(--black_rgb), 0.3);
      border-radius: 0.5rem;
      margin: 0rem 0.25rem;
      width: 100%;
      z-index: 0;
    }
    .div__multiColumn {
      display: flex;
      padding: 0rem 2rem;
      margin-top: 0.5rem;

      .btn__search_coord {
        display: flex;
        align-items: center;
      }
    }

    .div__field_check {
      display: flex;
      align-items: center;
      justify-content: left;
      flex: 2;
      text-align: left;

      .check_panels {
        margin: 0rem;
        width: auto;
        margin: 0rem 0.4rem;
      }
      label {
        text-align: left;
        width: 100%;
        margin: 0rem;
      }
    }

    .div__has_field_archive {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
      padding: 2rem;
      background-color: rgba(var(--black_rgb), 0.05);
    }

    .pos_field {
      background-color: #e1e1e1;
      z-index: 1;
      padding: 0rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0rem 0.5rem 0.5rem 0rem;
      margin-left: -1rem;
      border: 1px solid rgba(var(--black_rgb), 0.3);
    }

    .div__icon_info {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--information);
      cursor: pointer;
      transition: 0.3s;
      :hover {
        transform: scale(1.1);
      }
    }

    .div__del_archive {
      opacity: 0.8;
      div {
        height: 5rem;
        border: 2px dotted var(--delete);
        display: flex;
        cursor: pointer;

        label {
          position: relative;
          /* background: red; */
          padding: 2rem;
          height: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 270px;
          color: var(--delete);
          cursor: pointer;
        }

        svg {
          margin-right: 0.5rem;
        }
      }

      :hover {
        opacity: 1;
      }
    }

    .div__archive {
      cursor: pointer;

      input {
        display: none;
      }

      .div__has_field_archive {
        border: 2px dotted rgba(var(--black_rgb), 0.2);
        :hover {
          border: 2px dotted var(--primary2);
          color: var(--primary2);
        }
      }

      div {
        /* height: 5rem; */
        display: flex;

        .div__addfield_archive {
          /* position: relative; */
          /* background: red; */
          padding: 2rem;
          height: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 270px;

          /* background: red; */

          svg {
            margin: auto;
            margin-right: 0.5rem;
          }
        }

        :hover {
          /* border: 2px dotted var(--primary2); */
          color: var(--primary2);
        }
      }
    }

    .div__dataArchive {
      svg {
        font-size: 4.5rem;
      }

      display: flex;
      flex-direction: row;

      .div__info {
        display: flex;
        flex-direction: column;

        p {
          margin: 0;
          text-align: left;
        }
      }
    }

    .div__select {
      margin: 1rem 2rem;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 260px;
      height: 2.2rem;
      border-radius: 2rem;
      display: flex;
      justify-content: space-between;
      transition: 0.3s;
      /* padding: 0rem 1rem; */
      span {
        flex: 1;
        cursor: pointer;
        transition: 0.3s;
        height: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .s__select {
        cursor: default;
        background-color: var(--primary2);
        border-radius: 2rem;
        color: var(--white);
        transition: 0.3s;
        transform: scale(1.05);
      }
    }

    .div__moreOptions {
      text-align: right;

      .btn__newDocument {
        font-size: 0.8rem;
        cursor: pointer;
        border: 1px solid var(--primary2);
        color: var(--primary2);
        background-color: transparent;
        border-radius: 1rem;
        padding: 0.3rem 0.7rem;
        margin: 0rem 1rem;

        :hover {
          box-shadow: 0px 0px 10px rgba(var(--black_rgb), 0.3);
        }
      }
    }

    .btn__finish {
      background-image: linear-gradient(
        to bottom right,
        var(--primary2),
        var(--primary)
      );
      padding: 1rem 3rem;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      border: 0rem;
      font-size: 0.9rem;
      float: right;
      margin: 2rem 2rem;
      cursor: pointer;
      border-radius: 2rem;

      :hover {
        background-image: linear-gradient(
          to bottom left,
          var(--primary2),
          var(--primary)
        );
      }
    }
  }

  @media screen and (max-width: 675px) {
    .div__newfieldarchive,
    .div__newfieldarchive_in {
      flex-direction: column !important;
      padding: 0.5rem !important;
      .div__env_archive {
        margin: 1rem auto !important;
      }
    }
    .div__multiColumn {
      flex-direction: column;

      input,
      select {
        padding: 0.5rem;
        font-size: 1.3rem;
        margin: 0.2rem 0rem;
      }
    }

    .div__del_archive,
    .div__archive {
      margin: 1rem 0rem;
    }

    .btn__finish {
      margin: 2rem 0.55rem !important;
      clear: both !important;
      width: 95% !important;
    }

    .pos_field {
      display: none !important;
    }
  }
`;

export const Containerdica = styled.div`
  h3 {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: orange;
    }
  }
  h4 {
    margin: 0rem;
  }
  img {
    max-width: 400px;
  }
`;
