import { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';

import { useAuth } from './AuthContext';

const UserDataContext = createContext(null);

export function UserDataProvider({ children }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  const [loginData, setLoginData] = useState(null);
  const [person, setPerson] = useState(null);
  const [addressPerson, setAddressPerson] = useState(null);
  const [company, setCompany] = useState(null);
  const [addressCompany, setAddressCompany] = useState(null);
  const [numbersContact, setNumbersContact] = useState(null);
  const [completedData, setCompletedData] = useState(false);
  const [points, setPoints] = useState(0);
  const [listshopping, setListshopping] = useState([]);

  useEffect(() => {
    user && getDadosUsuario();
  }, [user]);

  const getDadosUsuario = async () => {
    await api
      .get(
        `users/${user.user_id}?relations=["login_data","person","person.address","person.company","person.company.address", "person.numbers_contact"]`
      )
      .then((res) => {
        const data = res.data;

        console.log('[RETURN DATA]=> ', data);

        if (
          data &&
          data.login_data &&
          data.person &&
          data.person?.address &&
          data.person?.address?.zip_code &&
          data.person?.address?.state &&
          data.person?.address?.city &&
          data.person?.address?.street &&
          data.person?.address?.number &&
          data.person?.address?.district &&
          data.person?.company &&
          data.person?.company?.fantasy_name &&
          data.person?.company?.address &&
          data.person?.company?.address?.zip_code &&
          data.person?.company?.address?.state &&
          data.person?.company?.address?.city &&
          data.person?.company?.address?.street &&
          data.person?.company?.address?.number &&
          data.person?.company?.address?.district &&
          data.person?.numbers_contact?.length > 0
        ) {
          setCompletedData(true);
        }

        getPoints(data?.person?.company?.id);

        setAddressCompany(data.person.company.address);
        // delete data.person.company.address;

        setCompany(data.person.company);
        // delete data.person.company;

        setAddressPerson(data.person.address);
        // delete data.person.address;

        setNumbersContact(data.person.numbers_contact);
        // delete data.person.numbers_contract;

        setPerson(data.person);
        setLoginData(data.login_data);

        setLoading(false);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  const getPoints = async (id_company) => {
    await api
      .get(`receipts/discount/${id_company}`)
      .then((res) => {
        setPoints(res.data.percentage_recorrency);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  async function getShoppingcart(id_company) {
    await api
      .get(`receipts/unpaid/${id_company}?page=0&rows=10`)
      .then((res) => {
        setListshopping(res.data.receipts);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  }

  let value = {
    loginData,
    person,
    addressPerson,
    company,
    addressCompany,
    completedData,
    getDadosUsuario,
    numbersContact,
    loading,
    points,
    getPoints,
    // carrinho de compras
    listshopping,
    setListshopping,
    getShoppingcart,
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}

export function useUserData() {
  return useContext(UserDataContext);
}
