import { useState, useEffect } from 'react';

import { Container } from './styles';

// images
import Logo1 from '../../../assets/images/logo/logo1.png';
import Logo2 from '../../../assets/images/logo/logo2.png';
import Bg1 from '../../../assets/images/bgs/bg1.png';

// mensagem
import Backdrop from '../../../components/Backdrop';

import { Link, useNavigate } from 'react-router-dom';

// api
import api from '../../../services/api';
import { toast } from 'react-toastify';

function Recuperarsenha() {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const [qemail] = useState(query.get('email'));
  const [qtoken] = useState(query.get('token'));
  const regex =
    /^(?=.*[@!#$%^&*()/\\])(?=.*[0-9])(?=.*[a-zA-Z])[@!#$%^&*()/\\a-zA-Z0-9]{6,20}$/;

  const [showback, setShowback] = useState(false);
  const [dados, setDados] = useState({
    password: '',
    confirm_password: '',
    is_valid: false,
    message: '',
    strong_pass_1: false,
    strong_pass_2: false,
  });

  function sendLogin(event) {
    event.preventDefault();
    if (dados.password.length === 0 || dados.confirm_password.length === 0)
      return toast.warn('Preencha corretamente todos os campos indicados');
    if (!dados.strong_pass_1)
      return toast.warn('Senha deve conter no mínimo 6 caracteres');
    if (!dados.strong_pass_2)
      return toast.warn(
        'Senha deve conter no mínimo um número e um caractere especial'
      );
    if (dados.password !== dados.confirm_password)
      return toast.warn(
        'As senhas informadas não correspondem, tente novamente'
      );

    setShowback(true);

    api
      .patch(`/login-data/reset-password`, {
        email: qemail,
        token: qtoken,
        password: dados.password,
      })
      .then((res) => {
        // console.log(res.data);
        return navigate('/login?message=Senha substituída com sucesso');
      })
      .catch((error) => {
        console.log('Error: ', error.response.data);
        setShowback(false);
      });
  }

  useEffect(() => {
    setShowback(true);
    api
      .get(`/login-data/check-token-password?email=${qemail}&token=${qtoken}`)
      .then((res) => {
        // console.log(res.data);
        setDados({ ...dados, is_valid: true });
        setShowback(false);
      })
      .catch((error) => {
        console.log('Error: ', error.response.data);
        setDados({
          ...dados,
          is_valid: false,
          message: error.response.data.message,
        });
        setShowback(false);
      });
  }, [qemail, qtoken]);

  return (
    <Container bg1={Bg1}>
      <div className="div__gradient">
        <div className="div__corpo">
          <div className="div__part2">
            <form onSubmit={sendLogin}>
              <img
                src={Logo2}
                title="Logo Tellar"
                className="img__logo_mobile"
                alt="Logo Tellar"
              />
              <h1>Recuperar Senha</h1>
              <span>
                Nesta página é possível recuperar a senha da sua conta.
              </span>
              {qtoken && qemail ? (
                dados.is_valid && !dados.message ? (
                  <div>
                    <h3>
                      Alterar senha da conta <u>{qemail}</u>
                    </h3>
                    <input
                      type="password"
                      placeholder="Nova senha*"
                      onChange={(e) => {
                        let aux1,
                          aux2 = false;

                        if (regex.test(e.target.value)) {
                          aux2 = true;
                        } else {
                          aux2 = false;
                        }

                        if (e.target.value.length > 5) {
                          aux1 = true;
                        } else {
                          aux1 = false;
                        }

                        setDados({
                          ...dados,
                          password: e.target.value,
                          strong_pass_1: aux1,
                          strong_pass_2: aux2,
                        });
                      }}
                    />
                    <input
                      type="password"
                      placeholder="Confirmar senha*"
                      onChange={(e) =>
                        setDados({ ...dados, confirm_password: e.target.value })
                      }
                    />

                    <ul>
                      <li
                        style={{
                          color: dados.strong_pass_1 && 'var(--success)',
                        }}
                      >
                        Senha deve conter no mínimo 6 caracteres
                      </li>
                      <li
                        style={{
                          color: dados.strong_pass_2 && 'var(--success)',
                        }}
                      >
                        Para uma senha forte, é aconselhável que contenha
                        números, letras e caracteres especiais
                      </li>
                      <li
                        style={{
                          color: 'var(--success)',
                        }}
                      >
                        Sua senha e credenciais de acesso são de uso pessoal,
                        evite informar a terceiros
                      </li>
                    </ul>

                    <button className="btn__login" type="submit">
                      Trocar senha
                    </button>
                  </div>
                ) : (
                  <div>{dados.message}</div>
                )
              ) : (
                <div>Link inválido</div>
              )}

              <div className="div__line" />
              <div className="div__subtext">
                <label>O que estou fazendo aqui?</label>
              </div>
              <Link to="/login" style={{ width: '100%' }}>
                <button className="btn__register" type="button">
                  Voltar para login
                </button>
              </Link>
            </form>
          </div>
        </div>
      </div>
      {showback && <Backdrop />}
    </Container>
  );
}

export default Recuperarsenha;
