import { Routes, Route, Navigate } from 'react-router-dom';

//contexts
import { useAuth } from './context/AuthContext';

// Layout Page System
import { Layout } from './pages/Layout';

// public page
import Login from './pages/public/Login';
import Cadastro from './pages/public/Cadastro';
import ConfirmacaoEmail from './pages/public/ConfirmacaoEmail';
import Consumirapi from './pages/public/Consumirapi';
import Testemapa from './pages/public/Testemapa';
import Recuperarsenha from './pages/public/Recuperarsenha';

// private page
import Newproject from './pages/private/Newproject';
import Projects from './pages/private/Projects';
import Receipts from './pages/private/Receipts';
import Users from './pages/private/Users';
import Orcamento from './pages/private/Orcamento';
import Settings from './pages/private/Settings';
import Novocredito from './pages/private/Novocredito';

// function PrivatePage() {
//   const auth = useAuth();

//   return auth.user ? <Layout /> : <Navigate to="/login" />;
// }

function PublicPage({ component: Component }) {
  const auth = useAuth();

  return auth.user ? <Navigate to="/meusprojetos" /> : <Component />;
}

const RoutesApp = () => (
  <Routes>
    <Route exact path="/" element={<Navigate to="/login" replace />} />
    <Route exact path="/login" element={<PublicPage component={Login} />} />
    <Route
      exact
      path="/cadastro"
      element={<PublicPage component={Cadastro} />}
    />
    <Route exact path="/confirmacao-cadastro" element={<ConfirmacaoEmail />} />
    <Route exact path="/recuperar-senha" element={<Recuperarsenha />} />
    <Route exact path="/consumirapi" element={<Consumirapi />} />
    {/* <Route path={'/consumirapi'} children={<Consumirapi />} /> */}
    {/* <Route path={'/testemapa'} children={<Testemapa />} /> */}

    <Route element={<Layout />}>
      <Route exact path="/meusprojetos" element={<Projects />} />
      <Route path="/orcamento" element={<Orcamento />} />
      <Route path="/novoprojeto" element={<Newproject />} />
      <Route path="/comprovantes" element={<Receipts />} />
      <Route path="/novocredito" element={<Novocredito />} />
      <Route path="/usuarios" element={<Users />} />
      <Route path="/configuracoes" element={<Settings />} />
    </Route>

    {/* <Route path={'*'} children={<Login />} /> */}
  </Routes>
);

export { RoutesApp };
