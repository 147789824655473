import { useEffect, useRef, useState } from 'react';
import { LottiePlayer } from 'lottie-web';

function Animation(props) {
  const ref = useRef(null);
  const [lottie, setLottie] = useState(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,

        // path to your animation file, place it inside public folder
        path: props.animationUrl,
      });

      return () => animation.destroy();
    }
  }, [lottie]);

  return (
    <div
      ref={ref}
      style={{
        width: props.width,
        height: props.height,
        //@ts-ignore
        position: props.position,
        zIndex: 30,
      }}
    />
  );
}

export default Animation;
