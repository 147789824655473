import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  /* background: red; */
  background-image: url(${(props) => props.bg1});
  background-size: cover;
  background-position: center;

  .div__gradient {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    /* background-image: linear-gradient(
      to right,
      rgba(var(--white_rgb), 0.9) 20%,
      rgba(var(--white_rgb), 0)
    ); */
    width: 100%;
    height: 100%;
  }

  .div__corpo {
    width: 100%;
    display: flex;
    flex-direction: row;
    max-width: 1920px;
    margin: auto;

    .div__part1 {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h3 {
        color: var(--white);
      }
      img {
        width: 100%;
        max-width: 450px;
        margin: 1rem auto;
      }
      p {
        color: var(--white);
        max-width: 450px;
        padding: 1rem;
      }
    }
    .div__part2 {
      flex: 3;
      /* background-color: var(--gray); */
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    form {
      background-color: var(--white);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 400px;
      width: 100%;
      min-height: 50%;
      border-radius: 0.5rem;
      box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.1);
      padding: 2rem 1rem;
      margin: 2rem 0rem;

      .img__logo_mobile {
        display: none;
      }

      h1 {
        /* text-transform: uppercase; */
        font-weight: 400;
        margin: 0rem;
        color: var(--primary2);
      }
      h3 {
        font-weight: 400;
        margin: 0rem auto;
        color: #3e3e3e;
        margin-bottom: 1rem;
      }
      ul {
        margin: 1rem 2rem;
        /* background: red; */
        font-size: 0.85rem;
        color: rgba(var(--black_rgb), 0.7);
        text-align: left;
      }
      span {
        font-size: 0.8rem;
        max-width: 280px;
        color: rgba(var(--black_rgb), 0.7);
        margin-bottom: 1rem;
      }
      input {
        max-width: 300px;
        width: 100%;
        height: 30px;
        margin: 0.25rem 0rem;
        outline-color: var(--primary);
        padding: 0.5rem 0.7rem;
        border: 1px solid rgba(var(--black_rgb), 0.25);
        border-radius: 0.25rem;
      }
      .div__functions {
        display: flex;
        width: 100%;
        max-width: 320px;
        justify-content: space-between;

        label,
        a {
          display: flex;
          font-size: 0.8rem;
          justify-content: center;
          align-items: center;
          color: rgba(var(--black_rgb), 0.7);

          input {
            width: 15px;
            margin: 0rem;
            margin-right: 0.2rem;
          }
        }

        a {
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        }
      }

      .btn__login {
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        width: 100%;
        max-width: 320px;
        height: 45px;
        background-color: var(--primary2);
        border: 0px;
        border-radius: 1.4rem;
        color: var(--white);
        margin-top: 1rem;
        margin-bottom: 0.75rem;
        cursor: pointer;
        :hover,
        :focus {
          box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
          filter: brightness(1.4);
        }
      }
      .div__subtext {
        display: flex;
        width: 100%;
        max-width: 320px;
        label {
          display: flex;
          font-size: 0.8rem;
          text-align: center;
          margin: 0.5rem auto;
          justify-content: center;
          align-items: center;
          color: rgba(var(--black_rgb), 0.7);
        }
      }
      .div__line {
        width: 100%;
        border-bottom: 1px solid rgba(var(--black_rgb), 0.3);
        max-width: 320px;
        margin: 0.5rem;
      }
      .btn__register {
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        width: 100%;
        max-width: 320px;
        height: 45px;
        background-color: transparent;
        border: 1px solid var(--primary2);
        border-radius: 1.4rem;
        color: var(--primary2);
        /* margin-top: 1rem; */
        margin-bottom: 0.75rem;
        cursor: pointer;
        :hover,
        :focus {
          box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.2);
        }
      }
    }
  }

  @media only screen and (max-width: 675px) {
    button {
      font-size: 1rem;
    }
    .div__corpo {
      flex-direction: column;
      flex: 1;

      .div__part1 {
        display: none;
      }
      .div__part2 {
        flex: 1;
        form {
          flex: 1;
          margin: 0rem;
          max-width: none;

          .img__logo_mobile {
            display: block;
            max-width: 250px;
            height: auto;
          }
          input[type='text'],
          input[type='password'] {
            padding: 1rem;
            font-size: 1rem;
          }
        }
      }
    }
  }
`;
