import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mask } from 'remask';

import { toast } from 'react-toastify';

import { Container, FielButtons } from './styles';

import Backdrop from '../Backdrop';

// imagem
import Money01 from '../../assets/images/prints/money_02.png';

// api
import API from '../../services/api';
// contexto;
import { useUserData } from '../../context/UserDataContext';

// icons
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

export default function Dialogoconfirmarproject(props) {
  let { points, company } = useUserData();
  const [open, setOpen] = React.useState(true);
  const [dados, setDados] = React.useState(props.dados);
  const [showbackdrop, setShowbackdrop] = useState(false);
  const [descontos, setDescontos] = React.useState({
    pontos: 0,
    cupons: 0,
  });

  const [cupons, setCupons] = React.useState({
    valido: false,
    show: false,
    message: '',
    code: '',
    percentage: '',
  });

  const [credits, setCredits] = React.useState({
    value: props.credits ? props.credits : 0,
    show: props.credits ? false : true,
  });
  // console.log(dados);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const handleChange = (e) => {
    if (e.target.name === 'number') {
      setDados({ ...dados, [e.target.name]: mask(e.target.value, '9999999') });
    } else if (e.target.name === 'cpf') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '999.999.999-99'),
      });
    } else if (e.target.name === 'landline') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '(99) 9999-9999'),
      });
    } else if (e.target.name === 'telephone') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '(99) 99999-9999'),
      });
    } else if (e.target.name === 'cnpj') {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, '99.999.999/9999-­99'),
      });
    } else {
      setDados({ ...dados, [e.target.name]: e.target.value });
    }
  };

  const getValuePrice = (power) => {
    // let power = dados.total_project_power;
    let price = 0;

    if (power > 0 && power <= 5) {
      price = 750.0;
    } else if (power > 5 && power <= 10) {
      price = 900.0;
    } else if (power > 10 && power <= 20) {
      price = 1200.0;
    } else if (power > 20 && power <= 40) {
      price = 1500.0;
    } else if (power > 40 && power <= 75) {
      price = 2000.0;
    } else if (power > 75 && power <= 100) {
      price = power * 40;
    } else if (power > 100 && power <= 200) {
      price = power * 37;
    } else if (power > 200 && power <= 300) {
      price = power * 33;
    } else if (power > 300 && power <= 400) {
      price = power * 28;
    } else if (power > 400 && power <= 500) {
      price = power * 25;
    } else if (power > 500 && power <= 1000) {
      price = power * 24;
    }

    return price;
  };

  const validarCupom = async (cupom) => {
    setShowbackdrop(true);
    if (!cupom) {
      setShowbackdrop(false);
      return true;
    }
    await API.get(`/coupons/check/${cupom}`)
      .then((res) => {
        setCupons({
          ...cupons,
          code: res.data.code,
          show: true,
          valido: true,
          message: '',
          percentage: parseInt(res.data.percentage),
        });

        props.setDescontos({
          ...descontos,
          coupon_code: res.data.code,
          coupon_percentage: res.data.percentage,
        });

        setShowbackdrop(false);
      })
      .catch((error) => {
        console.log('Error: ', error.response.data);
        setCupons({
          ...cupons,
          show: true,
          valido: false,
          message: error.response.data.message,
          percentage: 0,
        });
        setShowbackdrop(false);
      });
  };

  const currency = function (number) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>Confirmar dados de projeto</h1>
                <span>
                  Verifique atentamente as informações abaixo e confirme os
                  dados de projeto.
                </span>
                <content>
                  <h4>Informações da Concessionária</h4>
                  <div className="div__oneRow">
                    <p>
                      <label>Estado: </label> {dados.state}
                    </p>
                    <p>
                      <label>Concessionária: </label> {dados.concessionaria}
                    </p>
                  </div>

                  <h4>Informações do Cliente</h4>
                  <div className="div__oneRow">
                    <p>
                      <label>{dados.cpf ? 'CPF' : 'CNPJ'}:</label>{' '}
                      {dados.cpf ? dados.cpf : dados.cnpj}
                    </p>
                    <p>
                      <label>
                        {dados.full_name ? 'Nome' : 'Razão Social'}:
                      </label>{' '}
                      {dados.full_name ? dados.full_name : dados.social_reason}
                    </p>
                  </div>
                  <h4>Informações da Instalação - Unidade Geradora</h4>
                  <div className="div__oneRow">
                    <p>
                      <label>Tipo de disjuntor:</label> {dados.breaker_type}
                    </p>
                    <p>
                      <label>Valor do disjuntor:</label> {dados.breaker_value}
                    </p>
                  </div>
                  <div className="div__oneRow">
                    <p>
                      <label>Latitude:</label>{' '}
                      {('' + dados.latitude).slice(0, 12)}
                    </p>
                    <p>
                      <label>Longitude:</label>{' '}
                      {('' + dados.longitude).slice(0, 12)}
                    </p>
                  </div>
                  <div className="div__oneRow">
                    <p>
                      <label>Troca de titularidade:</label>{' '}
                      {dados?.exchange_ownership === 'false' ? 'Não' : 'Sim'}
                    </p>
                  </div>
                  <h4>Especificações do Projeto</h4>
                  <div className="div__oneRow">
                    <p>
                      <label>Pot. total do projeto:</label>{' '}
                      {dados.total_project_power} kWp
                    </p>
                    <p>
                      <label>Lista de materiais:</label>{' '}
                      {dados.has_order_material === 'true' ? 'Sim' : 'Não'}
                    </p>
                  </div>

                  {dados.amount_panels && dados.panel_power && (
                    <div className="div__oneRow">
                      <p>
                        <label>Qtd. de Placas:</label> {dados.amount_panels}{' '}
                        unid.
                      </p>
                      <p>
                        <label>Potência:</label> {dados.panel_power} Wp
                      </p>
                      <p>
                        <label>Modelo:</label> {dados.panel_model}
                      </p>
                    </div>
                  )}
                  {props.listinverters.map((invert, idx) => (
                    <div className="div__oneRow">
                      <p>
                        <label>Qtd. de Inversores:</label> {invert.amount} unid.
                      </p>
                      <p>
                        <label>Potência:</label> {invert.power_kwp} kWp
                      </p>
                      <p>
                        <label>Modelo:</label> {invert.model}
                      </p>
                    </div>
                  ))}

                  <div className="div__oneRow"></div>

                  <h4>Documentos</h4>

                  {dados.document_archive && (
                    <div className="div__list_archive">
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Documento com foto
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.document_archive.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.document_archive.type}
                      </p>
                    </div>
                  )}

                  {dados.social_contract && (
                    <div className="div__list_archive">
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Contrato social
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.social_contract.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.social_contract.type}
                      </p>
                    </div>
                  )}

                  {dados.document_energy && (
                    <div className="div__list_archive">
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Documento de energia
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.document_energy.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.document_energy.type}
                      </p>
                    </div>
                  )}

                  {dados.document_procuration && (
                    <div className="div__list_archive">
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Procuração para troca de titularidade
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.document_procuration.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.document_procuration.type}
                      </p>
                    </div>
                  )}

                  {dados.document_material && (
                    <div className="div__list_archive">
                      <p style={{ flex: 2 }}>
                        <label>- Documento:</label> <br />
                        Pedido dos materiais
                      </p>
                      <p>
                        <label>Nome:</label> <br />
                        {dados.document_material.name}
                      </p>
                      <p style={{ flex: 1 }}>
                        <label>Tipo:</label> <br />
                        {dados.document_material.type}
                      </p>
                    </div>
                  )}

                  {props.listRateios.length > 0 &&
                    props.listRateios.map((rateio, idx) => (
                      <div className="div__list_archive">
                        <p style={{ flex: 2 }}>
                          <label>- Documento:</label> <br />
                          Documento de rateio {idx + 1}
                        </p>
                        <p>
                          <label>Nome:</label> <br />
                          {rateio.document_rateio.name}
                        </p>
                        <p style={{ flex: 1 }}>
                          <label>Tipo:</label> <br />
                          {rateio.document_rateio.type}
                        </p>
                      </div>
                    ))}

                  {props?.listAdditional?.length > 0 &&
                    props.listAdditional.map((additional, idx) => (
                      <div className="div__list_archive">
                        <p style={{ flex: 2 }}>
                          <label>- Documento:</label> <br />
                          Adicional {idx + 1} : {additional.type} <br />{' '}
                          {additional.other_type &&
                            `(${additional.other_type})`}
                        </p>
                        <p>
                          <label>Nome:</label> <br />
                          {additional.document_additional.name}
                        </p>
                        <p style={{ flex: 1 }}>
                          <label>Tipo:</label> <br />
                          {additional.document_additional.type}
                        </p>
                      </div>
                    ))}

                  <h4>Orçamento</h4>
                  <div className="div__oneRow">
                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                      <img src={Money01} alt="Money Image" />
                      <p
                        style={{
                          margin:
                            window.innerWidth > 675 ? '0rem 1rem' : '0rem',
                        }}
                      >
                        Acumule 1% de desconto a cada projeto realizado com
                        nossa empresa. Além de 1%, também, a cada mês
                        consecutivo de projetos submetidos. Não fique de fora
                        dessa!
                      </p>
                    </p>
                    <div style={{ flex: 1, flexDirection: 'column' }}>
                      <div className="div__table_price">
                        <b>Item</b>
                        <b>Preço</b>
                      </div>
                      <div className="div__table_price">
                        <span>
                          - Projeto fotovoltáico {dados.total_project_power} kWp
                        </span>
                        <span>
                          {currency(
                            getValuePrice(props.dados.total_project_power),
                          )}
                        </span>
                      </div>

                      {credits.value > 0 && credits.show === false && (
                        <div className="div__table_price">
                          <span>- Créditos </span>
                          <span>-{currency(credits.value)}</span>
                        </div>
                      )}

                      {points > 0 && (
                        <div className="div__table_price">
                          <span>- Desconto ({points}%)</span>
                          <span>
                            -
                            {currency(
                              (getValuePrice(props.dados.total_project_power) -
                                (credits.show === false && credits.value)) *
                                (points / 100),
                            )}
                          </span>
                        </div>
                      )}
                      {cupons.percentage > 0 && (
                        <div className="div__table_price">
                          <span>- Cupom </span>
                          <span>
                            -
                            {currency(
                              (cupons.percentage *
                                (getValuePrice(
                                  props.dados.total_project_power,
                                ) -
                                  (credits.show === false && credits.value))) /
                                100,
                            )}
                          </span>
                        </div>
                      )}

                      <div className="div__table_price">
                        <div className="div__table_sub_total">
                          <b>Total:</b>
                          {currency(
                            getValuePrice(props.dados.total_project_power) -
                              (credits.show === false
                                ? parseFloat(credits.value)
                                : 0) -
                              (cupons.percentage *
                                (getValuePrice(
                                  props.dados.total_project_power,
                                ) -
                                  (credits.show === false
                                    ? parseFloat(credits.value)
                                    : 0))) /
                                100 -
                              (points *
                                (getValuePrice(
                                  props.dados.total_project_power,
                                ) -
                                  (credits.show === false
                                    ? parseFloat(credits.value)
                                    : 0))) /
                                100,
                          )}
                        </div>
                      </div>
                      {/* <div className="field__cupom">
                        <label htmlFor="text_cupom">Cupom:</label>
                        <input
                          type="text"
                          id="text_cupom"
                          name="text_cupom"
                          value={cupons.code}
                          disabled={cupons.show ? true : false}
                          onChange={(event) =>
                            setCupons({
                              ...cupons,
                              code: event.target.value.toUpperCase(),
                            })
                          }
                        />
                        <buttom
                          type="buttom"
                          className="btn__inserir"
                          onClick={() =>
                            cupons.show
                              ? setCupons({
                                  ...cupons,
                                  valido: false,
                                  show: false,
                                  message: false,
                                  code: '',
                                  percentage: false,
                                })
                              : validarCupom(cupons.code)
                          }
                        >
                          {cupons.show ? 'Remover' : 'Inserir'}
                        </buttom>

           
                      </div> */}
                      {/* {cupons.show && !cupons.valido && (
                        <span style={{ margin: ' 0rem 1rem' }}>
                          {cupons.message}
                        </span>
                      )} */}
                      {/* <div
                        className="field__cupom"
                        style={{ marginTop: '0.5rem' }}
                      >
                        <label htmlFor="number_credits">Créditos:</label>
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          id="number_credits"
                          name="number_credits"
                          value={credits.value}
                          placeholder="Insira o valor que deseja utilizar"
                          disabled={!credits.show ? true : false}
                          onChange={(event) => {
                            let aux = event.target.value;
                            if (!event.target.value) {
                              setCredits({
                                ...credits,
                                value: 0,
                              });

                              return;
                            }
                            if (aux >= 0) {
                              if (company.credits !== 0) {
                                if (
                                  parseFloat(aux) <= parseFloat(company.credits)
                                ) {
                                  if (
                                    aux <=
                                    getValuePrice(
                                      props.dados.total_project_power
                                    )
                                  ) {
                                    setCredits({
                                      ...credits,
                                      value: event.target.value,
                                    });
                                  } else {
                                    toast.warn(
                                      `Valor máximo do projeto é  ${currency(
                                        getValuePrice(
                                          props.dados.total_project_power
                                        )
                                      )}.`
                                    );
                                  }
                                } else {
                                  toast.warn(
                                    `Seu limite de crédito é ${currency(
                                      company.credits
                                    )}.`
                                  );
                                }
                              } else {
                                toast.warn(
                                  'Você não possui créditos para utilizar no momento.'
                                );
                              }
                            } else {
                              toast.warn('Valor deve ser maior ou igual a 0.');
                            }
                          }}
                        />
                        <buttom
                          type="buttom"
                          className="btn__inserir"
                          onClick={() => {
                            if (credits.show) {
                              props.setCredits(credits.value);
                              setCredits({ ...credits, show: false });
                            } else {
                              props.setCredits(0);
                              setCredits({ value: 0, show: true });
                            }
                          }}
                        >
                          {!credits.show ? 'Remover' : 'Inserir'}
                        </buttom>
                      </div> */}

                      {/* <span style={{ margin: ' 0rem 1rem' }}>
                        Você tem {currency(company.credits)} em créditos
                        disponíveis.
                      </span> */}
                    </div>
                  </div>
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
            >
              Cancelar
            </button>
            <button onClick={() => props.confirmar()} className="btn__register">
              <AddShoppingCartIcon /> Adicionar ao carrinho
            </button>
          </FielButtons>
        </DialogActions>
        {showbackdrop && <Backdrop />}
      </Dialog>
    </div>
  );
}
