import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 100vh;

  .conteudo {
    overflow-Y: scroll;
    width: 100%;

    padding: 0;
    margin: 0;
    padding-top: calc(68px + 0rem);
  }
`;
