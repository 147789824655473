import React, { useState } from 'react';

import { Container } from './styles';
import Logo from '../../assets/images/logo/logo2.png';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

// bibliotecas
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

// dialogo de carrinho de compras
import Dialogoshoppingcart from '../Dialogoshoppingcart';

// icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PeopleIcon from '@material-ui/icons/People';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuIcon from '@material-ui/icons/Menu';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// context
import { useUserData } from '../../context/UserDataContext';
import { useAuth } from '../../context/AuthContext';

// style of badge
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 15,
    top: 20,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0px',
    margin: '0px',
  },
}))(Badge);

const rotas = [
  {
    path: '/orcamento',
    Icon: <MonetizationOnIcon />,
    title: 'Orçamento online',
  },
  {
    path: '/novoprojeto',
    Icon: <PostAddIcon />,
    title: 'Novo Projeto',
  },
  {
    path: '/meusprojetos',
    Icon: <FormatListBulletedIcon />,
    title: 'Meus Projetos',
  },
  {
    path: '/novocredito',
    Icon: <PostAddIcon />,
    title: 'Novo Crédito',
  },
  {
    path: '/comprovantes',
    Icon: <ReceiptIcon />,
    title: 'Comprovantes',
  },

  // {
  //   path: '/usuarios',
  //   Icon: <PeopleIcon />,
  //   title: 'Usuários',
  // },
  {
    path: '/configuracoes',
    Icon: <SettingsIcon />,
    title: 'Configurações',
  },
];

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <Link className={`${match ? 'active' : ''}`} to={to} {...props}>
        {children}
      </Link>
    </div>
  );
}

export default function Menu({ open, handleChangeMenu }) {
  const { person } = useUserData();
  const { signout } = useAuth();
  const [showShoppingCart, setShowShoppingCart] = useState(false);

  const handleChangeMenuIsMobile = () => {
    if (window.innerWidth <= 600) {
      handleChangeMenu();
    }
  };

  return (
    <Container open={open}>
      <button className="seta">
        <div className="containerImg2">
          <img src={Logo} alt="Logo Tellar" />
        </div>
        {open ? (
          <ChevronLeftIcon onClick={() => handleChangeMenu()} />
        ) : (
          <MenuIcon onClick={() => handleChangeMenu()} />
        )}
      </button>

      <div className="contentTexts">
        {/* <div className="div__payment_field">
          <IconButton
            aria-label="cart"
            onClick={() => setShowShoppingCart(true)}
          >
            <StyledBadge badgeContent={4} color="secondary">
              <ShoppingCartIcon />
            </StyledBadge>
          </IconButton>
        </div> */}

        <p>Seja bem-vindo</p>
        <strong>{person && person.full_name}</strong>

        <button className="btn__logout" onClick={signout}>
          Sair
        </button>
      </div>

      <div className="containerImg">
        <img src={Logo} alt="Logo Tellar" />
      </div>

      <h4>Menu</h4>
      <nav>
        {rotas.map((rota) => (
          <CustomLink to={rota.path} onClick={() => handleChangeMenuIsMobile()}>
            {rota.Icon}
            <p>{rota.title}</p>
          </CustomLink>
        ))}
      </nav>
      {showShoppingCart && (
        <Dialogoshoppingcart fechar={() => setShowShoppingCart(false)} />
      )}
    </Container>
  );
}
