import styled from 'styled-components';
import tagBack from '../../assets/images/icons/desconto_acumulado_icon.png';

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  height: 100vh;
  max-width: ${(props) => (props.open ? '240px' : '72px')};
  background: #fff;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  transition: all 0.5s;

  .contentTexts {
    display: none !important;
  }

  .seta {
    z-index: 1100000;
    display: flex;
    justify-content: ${(props) => (props.open ? 'flex-end' : 'center')};
    align-items: center;
    min-height: 69px;
    width: 100%;
    border: 0;

    background: ${(props) => (props.open ? '#fff' : 'rgb(241, 241 ,241)')};
    /* border-right: ${(props) =>
      props.open ? '1px solid black' : '2px solid rgb(0, 0 ,0)'}; */
    border-bottom: ${(props) =>
      props.open ? 'none' : '1px dashed rgba(0, 0, 0, 0.12)'};

    svg {
      cursor: pointer;
      background: ${(props) => (props.open ? 'normal' : 'var(--primary2)')};
      color: ${(props) => (props.open ? 'var(--primary2)' : '#fff')};
      border-radius: ${(props) => (props.open ? '0' : '5rem')};
      padding: 0.75rem;

      :hover {
        background: ${(props) =>
          props.open ? 'rgb(241, 241 ,241)' : 'normal'};
        border-radius: 50%;
      }
    }

    transition: width 1s;
    /* 
    ::after {
      content: '';
      
      position: absolute;
      left: 72px;
      width: ${(props) => (props.open ? '0px' : '1px')};
      height: 70px;
      background: rgb(241, 241 ,241);
    } */

    .containerImg2 {
      display: none;
    }
  }

  .containerImg {
    display: ${(props) => (props.open ? 'block' : 'none')};

    img {
      width: 100%;
      height: 100%;
    }
  }

  h4 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    margin: ${(props) => (props.open ? '0' : '1rem')};
  }

  nav {
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    a {
      display: flex;
      align-items: center;
      text-decoration: none;

      p {
        display: ${(props) => (props.open ? 'block' : 'none')};
        font-size: 0.875rem;
        font-family: Poppins, sans-serif !important;
        color: rgba(0, 0, 0, 0.8);

        transition: display 2s;

        white-space: nowrap !important;
        overflow: hidden  !important;
        text-overflow: ellipsis  !important;
        
      }

      svg {
        padding: 0.75rem 1rem;
        margin: 0 0.75rem 0 0.5rem;
        font-size: 1.4rem;
        color: rgba(0, 0, 0, 0.3);
      }

      transition: all 0.2s;

      &.active {
        color: var(--primary2);
        background: rgba(0, 0, 0, 0.1);
        border-left: 3px solid var(--primary2);

        svg {
          color: var(--primary2);
        }
      }

      :hover {
        color: var(--primary2);
        background: rgba(0, 0, 0, 0.1);
        border-left: 3px solid var(--primary2);

        svg {
          color: var(--primary2);
        }
      }
    }
  }

  /* QUERY MEDIA */
  @media screen and (max-width: 675px) {
    position: fixed !important;
    z-index: 99999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: ${(props) => (props.open ? 'scroll !important' : 'hidden')};

    width: ${(props) => (props.open ? '100vw' : '0px')};
    max-width: 100vw;
    /* height: ${(props) => (props.open ? '100vh' : '70px')}; */

    transition: all 0s !important;

    .seta {
      position: ${(props) => (props.open ? 'absolute' : 'fixed')};
      left: 0;
      top: 0;
      max-width: ${(props) => (props.open ? '100vw' : '5rem')};
      height: 3.5rem;

      svg{
        color: ${(props) => (props.open ? 'var(--primary2)' : 'var(--white)')};
      }
      .containerImg2 {
        display: ${(props) => (props.open ? 'block !important' : 'none')};
        width: 100%;
        margin-top: 3rem;

        img {
          max-width: 300px;
          ma
        }
      }

      ::after {
        display: none;
      }
    }

    .containerImg {
      display: none !important;
    }

    .contentTexts {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      margin-top: 8rem;

      p {
        font-size: 0.875rem !important;
        margin: 0;
        color: rgba(0, 0, 0, 0.8);
      }

      strong {
        font-size: 0.9rem;
      }

      .btn__logout {
        min-width: 50px;
        padding: 0.5rem 3rem;
        font-size: 1rem;
        border: 0;
        outline: none;
        margin-top: 1rem;
        border-radius: 0.25rem;
      }

      .div__payment_field {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          margin: auto;
          margin-right: 0.75rem;
          padding: 0rem;
          width: 45px;
          height: 45px;
          z-index: 99999999999;
          /* cursor: pointer; */
        }
        svg {
          /* cursor: pointer; */
          margin: auto 1rem;
          color: var(--primary2);
        }
      }
    }

    h4 {
      display: none;
    }

    nav {
      margin-top: 2rem;
      padding-bottom: 2rem !important;

      a {
        padding: 1rem 0;

      
      }
    }
  }
`;

export const Conteudo = styled.div`
  /* background-color: red !important; */
`;

export const Divdesconto = styled.div`
  background-image: url(${tagBack});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 90px;
  display: flex;
  flex-direction: column;
  margin: 0rem;
  justify-content: center;
  align-items: center;
  color: var(--white);
  margin: auto;
  small {
    margin-bottom: -0.4rem;
  }
`;
