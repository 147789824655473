import React from 'react';

// dica
import Tooltip from '@material-ui/core/Tooltip';

import { Container } from './styles';

import { ImWhatsapp } from 'react-icons/im';

const FloatingButton = () => {
  const message = '';
  const telNumber = '558893180267';
  return (
    <Container>
      <Tooltip
        placement="left"
        title="Entre em contato."
        enterDelay={100}
        leaveDelay={100}
      >
        <a
          href={`https://wa.me/${telNumber}?text=${message}`}
          target="_blank"
          rel="noreferrer"
        >
          <span
            className="chat"
            // onClick={() =>
            //   window.open(
            //     `https://wa.me/5588996277795?text=Eu%20tenho%20interesse%20no%20seu%20carro%20à%20venda`,
            //   )
            // }
          >
            <ImWhatsapp className="logo" />
          </span>
        </a>
      </Tooltip>
    </Container>
  );
};

export default FloatingButton;
