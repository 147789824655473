import React, { ReactNode } from 'react';

import { Container } from './styles';

import api from '../../../services/api';

function Consumirapi() {
  const [meuDado] = React.useState(['88994939243']);

  const [dadostotais] = React.useState([
   
    '88994939243',
  ]);

  const [mensagem] = React.useState(
    'Boa tarde novamente.\n\nVamos iniciar agora nossa live.\n\nVamos?\n\nEng Alan Araújo e o Dr Breno Jessen estão fazendo uma reunião para orientar nossos clientes de como agir nesse momento de pressão da Enel.\n\n\nhttps://meet.google.com/smi-dxpy-sir'
  );

  const enviarDados = async (e) => {
    let msgCript = window.encodeURIComponent(mensagem);

    await dadostotais.map((pessoa, idx) => {
      api.get(`message?number=${'55' + pessoa}&message=${msgCript}`);
    });
  };
  return (
    <Container>
      <h1>Consumirapi</h1>
      <button onClick={() => enviarDados()}>Enviar</button>
    </Container>
  );
}

export default Consumirapi;
