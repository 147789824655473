import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { mask } from 'remask';

import { Container, FielButtons } from './styles';

import Backdrop from '../Backdrop';

// imagem
import Money01 from '../../assets/images/prints/money_02.png';

// api
import API from '../../services/api';

// contexto;
import { useUserData } from '../../context/UserDataContext';

// icons
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

export default function Dialogonovocredito(props) {
  let { points } = useUserData();
  const [open, setOpen] = React.useState(true);
  const [dados, setDados] = React.useState(props.dados);
  const [showbackdrop, setShowbackdrop] = useState(false);
  // console.log(dados);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const getValuePrice = (power) => {
    // let power = dados.total_project_power;
    let price = 0;

    if (power > 0 && power <= 5) {
      price = 750.0;
    } else if (power > 5 && power <= 10) {
      price = 900.0;
    } else if (power > 10 && power <= 20) {
      price = 1200.0;
    } else if (power > 20 && power <= 40) {
      price = 1500.0;
    } else if (power > 40 && power <= 75) {
      price = 2000.0;
    } else if (power > 75 && power <= 100) {
      price = power * 40;
    } else if (power > 100 && power <= 200) {
      price = power * 37;
    } else if (power > 200 && power <= 300) {
      price = power * 33;
    } else if (power > 300 && power <= 400) {
      price = power * 28;
    } else if (power > 400 && power <= 500) {
      price = power * 25;
    } else if (power > 500 && power <= 1000) {
      price = power * 24;
    }

    return price;
  };

  const handleChange = (e) => {
    setDados({ ...dados, note: e.target.value });
    props.onNote(e);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 99999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>Confirmar dados de crédito</h1>
                <span>
                  Verifique atentamente as informações abaixo e confirme os
                  dados.
                </span>
                <content>
                  <h4>Especificações</h4>
                  <div className="div__oneRow">
                    <p>
                      <label>Pot. total do projeto:</label>{' '}
                      {dados.project_power} kWp
                    </p>
                  </div>
                  <h4>Orçamento</h4>
                  <div className="div__oneRow">
                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                      <img src={Money01} alt="Money Image" />
                      <p
                        style={{
                          margin:
                            window.innerWidth > 675 ? '0rem 1rem' : '0rem',
                        }}
                      >
                        Acumule 1% de desconto a cada crédito contratado com
                        nossa empresa. Além de 1%, também, a cada mês
                        consecutivo de projetos submetidos.
                      </p>
                    </p>
                    <div style={{ flex: 1, flexDirection: 'column' }}>
                      <div className="div__table_price">
                        <b>Item</b>
                        <b>Preço</b>
                      </div>
                      <div className="div__table_price">
                        <span>
                          - Projeto fotovoltáico {dados.project_power} kWp
                        </span>
                        <span>
                          R$ {getValuePrice(props.dados.project_power)}
                        </span>
                      </div>
                      {points > 0 && (
                        <div className="div__table_price">
                          <span>- Desconto ({points}%)</span>
                          <span>
                            - R${' '}
                            {(getValuePrice(props.dados.project_power) *
                              points) /
                              100}
                          </span>
                        </div>
                      )}
                      <div className="div__table_price">
                        <div className="div__table_sub_total">
                          <b>Total:</b> R${' '}
                          {getValuePrice(props.dados.project_power) -
                            getValuePrice(props.dados.project_power) *
                              (points / 100)}
                        </div>
                      </div>

                      <div className="div__multiColumn">
                        {/* <div className="div__table_price">
                          <span>Pagamento</span>
                          <b>-</b>
                        </div> */}

                        {/* <select
                          required
                          name="state"
                          style={{ flex: 1 }}
                          value={dados.note}
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            Preferência no pagamento
                          </option>
                          <option value="Boleto">Boleto</option>
                          <option value="Cartão de crédito">
                            Cartão de crédito
                          </option>
                          <option value="Cartão de débito">
                            Cartão de débito
                          </option>
                          <option value="Transferência">Transferência</option>
                          <option value="PIX">PIX</option>
                          <option value="Outro">Outro</option>
                        </select> */}
                      </div>
                    </div>
                  </div>
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                // if (dados.note) {
                props.confirmar();
                // } else {
                //   toast.warn('Informe sua preferência no pagamento');
                // }
              }}
              className="btn__register"
            >
              <AddShoppingCartIcon /> Adicionar ao carrinho
            </button>
          </FielButtons>
        </DialogActions>
        {showbackdrop && <Backdrop />}
      </Dialog>
    </div>
  );
}
