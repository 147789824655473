import axios from 'axios';
import { addYears, eachYearOfInterval } from 'date-fns';
import { ReactNode, useRef, useState } from 'react';
import { mask } from 'remask';

import { Container } from './styles';

// images
import Imagecardcode from '../../assets/images/prints/image_card_code.png';

// pagarme
import pagarme from 'pagarme/browser';

// contexto
import { useUserData } from '../../context/UserDataContext';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Backdrop from '../Backdrop';

function Boletopaymentmethod(props) {
  let navigate = useNavigate();
  const idinterval = useRef();
  const [backdrop, setBackdrop] = useState(false);

  // consulta periódica
  const [synchronize, setSynchronize] = useState(false);

  const {
    addressPerson,
    loginData,
    numbersContact,
    person,
    company,
    getShoppingcart,
    getPoints,
  } = useUserData();

  const [dataCard, setDatacard] = useState({
    card_holder_name: '',
    buyer_name: '',
    card_number: '',
    validate_month: '01',
    validate_year: new Date().toLocaleString('pt-BR', { year: '2-digit' }),
    validate_code: '',
    number_installments: '1',
  });

  const handleSubmitPayment = async (event) => {
    // console.log('[adress person]=>', addressPerson);
    // console.log('[loginData]', loginData);
    // console.log('[number]', numbersContact);
    // console.log('[person]', person);
    // console.log('[adressCompany', company);
    // console.log('[selectedProjects]', props.selectedProjects);
    // setShowbackdrop(true);

    event.preventDefault();
    setBackdrop(true);
    let cardParams = {
      card_number: dataCard.card_number.replaceAll(' ', ''),
      card_holder_name: dataCard.card_holder_name,
      card_expiration_date:
        '' + dataCard.validate_month + ('' + dataCard.validate_year),
      card_cvv: dataCard.validate_code,
    };
    await pagarme.client
      .connect({ encryption_key: process.env.REACT_APP_ENCRYPTION_KEY })
      .then((client) => client.security.encrypt(cardParams))
      .then(async (card_hash) => {
        const paymentData = {
          receipts: props.selectedProjects,
          payment_type: 'credit_card',
          installments: dataCard.number_installments,
          customer_name: dataCard.buyer_name,
          customer_email: loginData.email,
          customer_mobile: numbersContact[0].number,
          customer_document: company.cnpj ? company.cnpj : person.cpf,
          billing_address: addressPerson.street,
          billing_number: addressPerson.number,
          billing_neighborhood: addressPerson.district,
          billing_state: addressPerson.state,
          billing_city: addressPerson.city,
          billing_zip_code: addressPerson.zip_code,
          credit_card_hash: card_hash,
          credits_discount: props.credits.value,
          coupon_code: props.cupons.code,
        };

        await api
          .post('/transactions', paymentData)
          .then(async (res) => {
            getDataEventSync(res.data.id);

            // getShoppingcart(company?.id);
            // getPoints(company?.id);
            // props.setPaymentSuccess(true);
            // setTimeout(() => (window.location = '/meusprojetos'), 5000);

            // setShowbackdrop(false);
            // id_project = res.data.id;
            // toast.success('Projeto cadastrado com sucesso!');
            // navigate('/meusprojetos');
          })
          .catch((error) => {
            console.log('Erro: ', error.response.data.message);
            // setShowbackdrop(false);
            toast.warn(error.response.data.message);
          });
      });
  };

  // buscar dados de forma periódica
  const getDataEventSync = (paymentID) => {
    var aux = setInterval(async () => {
      await api
        .get(`/transactions/${paymentID}`)
        .then((res) => {
          if (res.data.status === 'approved') {
            cancelDataEventSync();
            setBackdrop(false);

            getShoppingcart(company?.id);
            getPoints(company?.id);
            props.setPaymentSuccess(true);
            setTimeout(() => (window.location = '/meusprojetos'), 5000);
          } else if (
            res.data.status === 'refused' ||
            res.data.status === 'refunded'
          ) {
            cancelDataEventSync();

            getShoppingcart(company?.id);
            getPoints(company?.id);
            toast.error('Pagamento recusado, tente novamente');
            setTimeout(() => (window.location = '/comprovantes'), 10 * 1000);
          }
        })
        .catch((error) => {
          console.log('erro recibos:', error.response);
        });
    }, 15 * 1000);

    setSynchronize(true);
    idinterval.current = aux;
  };

  // cancela busca periódica
  const cancelDataEventSync = () => {
    setSynchronize(false);
    clearInterval(idinterval.current);
  };

  const handleInputCard = (event) => {
    switch (event.target.id) {
      case 'card_holder_name':
        setDatacard({
          ...dataCard,
          [event.target.id]: event.target.value.toUpperCase(),
        });
        break;

      case 'card_number':
        setDatacard({
          ...dataCard,
          [event.target.id]: mask(event.target.value, '9999 9999 9999 9999'),
        });
        break;
      case 'validate_code':
        setDatacard({
          ...dataCard,
          [event.target.id]: mask(event.target.value, '9999'),
        });
        break;
      case 'buyer_name':
        setDatacard({
          ...dataCard,
          [event.target.id]: event.target.value.toUpperCase(),
        });
        break;
      case 'number_installments':
        props.setInstallments(event.target.value);
        setDatacard({ ...dataCard, [event.target.id]: event.target.value });
      default:
        setDatacard({ ...dataCard, [event.target.id]: event.target.value });
    }
  };

  const getintervalformap = () => {
    return eachYearOfInterval({
      start: new Date(),
      end: addYears(new Date(), 20),
    });
  };

  return (
    <Container>
      <form
        data-pagarmecheckout-form
        onSubmit={handleSubmitPayment}
        method="POST"
      >
        <label htmlFor="card_number">
          Número do cartão*
          <input
            type="text"
            id="card_number"
            name="card-number"
            data-pagarmecheckout-element="number"
            value={dataCard.card_number}
            onChange={handleInputCard}
            required
          />
        </label>
        <label htmlFor="card_holder_name">
          Nome impresso no cartão*
          <input
            type="text"
            id="card_holder_name"
            name="holder-name"
            data-pagarmecheckout-element="holder_name"
            value={dataCard.card_holder_name}
            onChange={handleInputCard}
            required
          />
        </label>
        <span data-pagarmecheckout-element="brand"></span>
        <div className="div__oneRow">
          <label htmlFor="validate_month">
            Mês de validade*
            <select
              id="validate_month"
              name="card-exp-month"
              data-pagarmecheckout-element="exp_month"
              value={dataCard.validate_month}
              onChange={handleInputCard}
              required
            >
              <option value="01">Janeiro</option>
              <option value="02">Fevereiro</option>
              <option value="03">Março</option>
              <option value="04">Abril</option>
              <option value="05">Maio</option>
              <option value="06">Junho</option>
              <option value="07">Julho</option>
              <option value="08">Agosto</option>
              <option value="09">Setembro</option>
              <option value="10">Outubro</option>
              <option value="11">Novembro</option>
              <option value="12">Dezembro</option>
            </select>
          </label>
          <label htmlFor="validate_year">
            Ano de validade*
            <select
              id="validate_year"
              value={dataCard.validate_year}
              onChange={handleInputCard}
            >
              {getintervalformap().map((generated_year, idx) => (
                <option
                  key={idx}
                  value={new Date(generated_year).toLocaleString('pt-BR', {
                    year: '2-digit',
                  })}
                >
                  {new Date(generated_year).toLocaleString('pt-BR', {
                    year: 'numeric',
                  })}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="div__oneRow">
          <label htmlFor="validate_code" className="label__validate_code">
            Código de segurança*
            <input
              type="text"
              id="validate_code"
              name="cvv"
              data-pagarmecheckout-element="cvv"
              value={dataCard.validate_code}
              onChange={handleInputCard}
              required
            />
          </label>
          <div className="div__contains_card_code_image">
            <img
              src={Imagecardcode}
              alt="onde encontrar código de segurança"
              className="img__security_code"
            />
          </div>
        </div>

        <div className="div__oneRow">
          <label htmlFor="validate_month">
            Número de parcelas*
            <select
              id="number_installments"
              name="number_installments"
              value={dataCard.number_installments}
              onChange={handleInputCard}
              required
            >
              <option value="1">1 x Parcela</option>
              <option value="2">2 x Parcelas</option>
              <option value="3">3 x Parcelas</option>
              <option value="4">4 x Parcelas</option>
              <option value="5">5 x Parcelas</option>
              <option value="6">6 x Parcelas</option>
              <option value="7">7 x Parcelas</option>
              <option value="8">8 x Parcelas</option>
              <option value="9">9 x Parcelas</option>
              <option value="10">10 x Parcelas</option>
              <option value="11">11 x Parcelas</option>
              <option value="12">12 x Parcelas</option>
            </select>
          </label>
        </div>
        <div className="div__important_notes">
          <p>
            - Pagamentos parcelados estarão sujeitos às taxas do serviço de
            cobrança.
          </p>
        </div>

        <label htmlFor="validate_code">
          Nome do comprador*
          <input
            type="text"
            id="buyer_name"
            name="buyer-name"
            value={dataCard.buyer_name}
            onChange={handleInputCard}
            required
          />
        </label>
        <div className="div__important_notes">
          <p>* Campo obrigatório</p>
          <p>
            Seus dados pessoais referente as informações de pagamento não são
            armazenadas, ou trafegam, de nenhuma forma em nossa plataforma.
          </p>
        </div>
        <button type="submit" className="btn__confirm_payment">
          Fechar compra
        </button>
      </form>
      {backdrop && (
        <Backdrop
          message={'Sua solicitação está sendo processada, por favor aguarde.'}
        />
      )}
    </Container>
  );
}

export default Boletopaymentmethod;
