import { useEffect, useState } from "react";

import { Container, Page, Containerdica } from "./styles";

import { mask } from "remask";

import Popover from "@material-ui/core/Popover";

import Dialogoconfirmacao from "../../../components/Dialogoconfirmacao";

import Dialogocompletardados from "../../../components/Dialogocompletardados";

import Dialogoencontrarlocalizacao from "../../../components/Dialogoencontrarlocalizacao";

import { useNavigate } from "react-router-dom";

// icones
import BackupIcon from "@material-ui/icons/Backup";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import LabelIcon from "@material-ui/icons/Label";
import SearchIcon from "@material-ui/icons/Search";

// imagens
import Imgcoordinate from "../../../assets/images/prints/help_coodinate.png";
import Imgcoordinate2 from "../../../assets/images/prints/help_coodinate_2.png";
import Imgcoordinate3 from "../../../assets/images/prints/help_coodinate_3.png";

// notificação
import { toast } from "react-toastify";
import Dialogoconfirmarproject from "../../../components/Dialogoconfirmarproject";

// Backdrop
import Backdrop from "../../../components/Backdrop";

// api
import API from "../../../services/api";

import { useUserData } from "../../../context/UserDataContext";
// render props
import { WithAuthenticatedUser } from "../../../components/WithAuthenticatedUser";

function Newproject() {
  const {
    company,
    completedData,
    getPoints,
    getDadosUsuario,
    getShoppingcart,
  } = useUserData();
  let navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const [qstate] = useState(query.get("state"));
  const [qcons] = useState(query.get("concessionaria"));
  const [qpower] = useState(query.get("total_project_power"));

  const [showbackdrop, setShowbackdrop] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [listConcess, setListConcess] = useState([]);

  const [credits, setCredits] = useState(0);

  const [finish, setFinish] = useState(false);
  const [showconf, setShowconf] = useState({
    status: false,
    width: "sm",
    title: "Confirmar",
    description: "Deseja realmente remover este arquivo?",
    btn_conf: "Sim",
    btn_cancel: "Não",
    delete: true,
    dados: "",
    temp: "",
  });

  const [descontos, setDescontos] = useState({
    coupon_code: "",
    coupon_percentage: "",
    credits_value: "",
    credits_used: "",
  });

  const [showcoord, setShowcoord] = useState(false);

  const [pfisic, setPfisic] = useState(true);
  const [listRateios, setListRateios] = useState([]);

  const [listAdditional, setListAdditional] = useState([
    // {
    //   type: '',
    //   other_type: '',
    //   document_additional: '',
    // },
  ]);

  const [listinverters, setListinverters] = useState([
    {
      project_id: "",
      power_kwp: "",
      model: "",
      amount: "",
    },
  ]);
  const [listdatasheetinvert, setListdatasheetinvert] = useState([]);

  const [dados, setDados] = useState({
    // person
    cpf: "",
    full_name: "",
    is_client: true,
    is_integrator: true,
    role: null,

    // company
    cnpj: "",
    social_reason: "",
    owner_id: null,
    fantasy_name: null,
    municipal_registration: null,
    state_registration: null,

    // person and company
    address_id: null,

    // new project
    dealer_id: "",
    client_person_id: "", //se for pessoa
    client_company_id: "", //se for empresa
    concessionaria: qcons ? qcons : "", //converter para dealer_id depois
    breaker_type: "",
    breaker_value: "", // que pode receber other_breaker_value caso valor seja 'other'
    other_breaker_value: "", //substituir breaker_value
    latitude: "",
    longitude: "",
    exchange_ownership: "",
    total_project_power: qpower ? qpower : "",
    has_apportionment: listRateios.length > 0 ? true : false,
    deadline: null,
    suspended: false,
    finished_date: null,
    has_order_material: "",
    amount_panels: "",
    panel_power: "",
    panel_model: "",

    // inverter
    qtd_inverters: "",
    inverters_model: "",
    power_inverters: "",

    // arquivos
    document_archive: "",
    document_energy: "",
    document_procuration: "",
    document_rateio: "",
    document_material: "",
    document_panel_datasheet: "",
    document_inverters_datasheet: [false],
    social_contract: "",

    state: qstate ? qstate : "",
  });

  const [checkeds, setCheckeds] = useState({
    check_panels: false,
    check_inverters: [false],
  });

  const [listPlacas] = useState([
    {
      id: 1,
      name: "Modelo 1",
    },
    {
      id: 2,
      name: "Modelo 2",
    },
    {
      id: 3,
      name: "Modelo 3",
    },
  ]);

  const [listdisjuntores] = useState([
    "2 A",
    "4 A",
    "6 A",
    "10 A",
    "16 A",
    "20 A",
    "25 A",
    "32 A",
    "40 A",
    "50 A",
    "63 A",
    "70 A",
    "80 A",
    "100 A",
    "125 A",
    "Outro",
  ]);
  const onInputChange = (e) => {
    // console.log('Nome: ', e.target.name, ' - Valor: ', e.target.value);
    if (e.target.name == "cpf") {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "999.999.999-99"),
      });
    } else if (e.target.name == "cnpj") {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "99.999.999/9999-­99"),
      });
    } else if (
      e.target.name === "amount_panels" ||
      e.target.name === "qtd_inverters"
    ) {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "99999999"),
      });
    } else if (
      e.target.name === "exchange_ownership" &&
      e.target.value === "false"
    ) {
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
        document_procuration: "",
      });
    } else if (e.target.name === "has_order_material") {
      if (e.target.value === "true") {
        setDados({
          ...dados,
          [e.target.name]: e.target.value,
          document_panel_datasheet: "",
          document_inverters_datasheet: "",
          amount_panels: "",
          qtd_inverters: "",
          panel_model: "",
          panel_power: "",
          inverters_model: "",
          document_material: "",
        });
        setListinverters([
          {
            project_id: "",
            power_kwp: "",
            model: "",
            amount: "",
          },
        ]);
        setListdatasheetinvert([]);
        setCheckeds({ check_panels: false, check_inverters: [false] });
      } else if (e.target.value === "false") {
        setDados({
          ...dados,
          [e.target.name]: e.target.value,
          document_materials: "",
        });
      }
    } else if (e.target.name === "total_project_power") {
      if (e.target.value > 1000) {
        return toast.warn("Valor máximo de 1000kWp");
      }

      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "other_breaker_value") {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "999"),
      });
    } else if (
      e.target.name === "panel_power" ||
      e.target.name === "power_inverters"
    ) {
      if (e.target.value > 10000) {
        return toast.warn("Valor máximo de 10000kWp");
      }

      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
      // old code
      // setDados({
      //   ...dados,
      //   [e.target.name]: mask(e.target.value, '9999'),
      // });
    } else if (e.target.name === "latitude" || e.target.name === "longitude") {
      if (e.target.value > 99 || e.target.value < -99) return;
      console.log("[lat and long]=> ", e.target.value);
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "state") {
      getConcess(e.target.value);
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
        concessionaria: "",
      });
    } else {
      setDados({ ...dados, [e.target.name]: e.target.value });
    }
  };

  const loadArchive = (event) => {
    // console.log(event.target.files[0]);

    if (event.target.files[0]) {
      if (
        event.target.files[0].type === "application/pdf" ||
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        setDados({ ...dados, [event.target.name]: event.target.files[0] });
      } else {
        toast.error("Tipo de arquivo inválido, insira .pdf, .png ou .jpeg");
      }
    }
  };

  const loaddatasheetinver = (event, idx) => {
    if (event.target.files[0]) {
      if (
        event.target.files[0].type === "application/pdf" ||
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        let aux = listdatasheetinvert;
        aux.push({
          id: idx,
          file: event.target.files[0],
        });
        setListdatasheetinvert([...aux]);
      } else {
        toast.error("Tipo de arquivo inválido, insira .pdf, .png ou .jpeg");
      }
    }
  };

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? "simple-popover" : undefined;

  const submeterForm = (event) => {
    event.preventDefault();
    // console.log('Dados', dados);

    if (dados.cpf && !validarCPF(dados.cpf.replace(/[^0-9]/g, ""))) {
      return toast.warn("CPF inválido");
    }

    if (!dados.document_archive) {
      return toast.warn("Anexar documento com foto é obrigatório");
    } else if (!dados.document_energy) {
      return toast.warn(
        "Anexar conta de energia da unidade consumidora é obrigatório",
      );
    } else if (dados.has_order_material === true && !dados.document_material) {
      return toast.warn(
        "Anexar documento referente ao pedido de materiais é obrigatório",
      );
    } else if (pfisic === false && !dados.social_contract) {
      return toast.warn("Anexar contrato social é obrigatório");
    } else if (
      dados.exchange_ownership === "true" &&
      !dados.document_procuration
    ) {
      return toast.warn("Anexar procuração é obrigatório");
    }

    setFinish(true);
  };

  // validar campos de cpf
  const validarCPF = (strCPF) => {
    var Soma;
    var Resto;
    var i;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  };

  const getConcess = async (value) => {
    setShowbackdrop(true);
    // const config = {
    //   headers: {
    //     Authorization: 'Bearer ' + props.user.token,
    //     'Content-Type': 'application/json',
    //   },
    // };

    await API.get(`/dealers/state/${value}`)
      .then((res) => {
        // console.log(res.data);
        setListConcess(res.data);
        setShowbackdrop(false);
      })
      .catch((error) => {
        console.log("Error: ", error.response.data);
        setShowbackdrop(false);
      });
  };

  const enviarProjeto = async (event) => {
    setShowbackdrop(true);
    setFinish(false);
    // cadastro de projetos

    let projetos = {
      client_name: dados.cpf ? dados.full_name : dados.social_reason,
      client_cpf: dados.cpf ? dados.cpf : null,
      client_cnpj: dados.cnpj ? dados.cnpj.replace("­", "") : null,
      dealer_id: listConcess.find((a) => a.name === dados.concessionaria).id,
      breaker_type: dados.breaker_type,
      breaker_value:
        dados.breaker_value === "Outro"
          ? dados.other_breaker_value
          : dados.breaker_value,
      latitude: dados.latitude,
      longitude: dados.longitude,
      exchange_ownership: dados.exchange_ownership,
      total_project_power: dados.total_project_power,
      has_apportionment: dados.has_apportionment,
      deadline: null,
      suspended: false,
      finished_date: null,
      has_order_material: dados.has_order_material,
      amount_panels:
        dados.has_order_material === "true" ? null : dados.amount_panels,
      panel_power:
        dados.has_order_material === "true" ? null : dados.panel_power,
      panel_model:
        dados.has_order_material === "true" ? null : dados.panel_model,
      company_id: company.id, ///lembrar de alterar para o contexto LUAN NUNES LINDO

      credits_discount: credits,
      coupon_code: descontos.coupon_code ? descontos.coupon_code : null,
      note: "",
    };

    let id_project;

    await API.post("/projects", projetos)
      .then((res) => {
        id_project = res.data.project.id;
        // toast.success('Projeto cadastrado com sucesso!');
        // navigate('/meusprojetos');
      })
      .catch((error) => {
        // console.log(error.response.data.message);
        setShowbackdrop(false);
        toast.warn(error.response.data.message);
        return true;
      });

    dados.has_order_material === "false" &&
      listinverters.map(async (inverter, idx) => {
        let auxInvert = { ...inverter, project_id: id_project };

        await API.post("/inverters", auxInvert)
          .then((res) => {
            // setShowbackdrop(false);
            // id_project = res.data.id;
            // toast.success('Projeto cadastrado com sucesso!');
            // navigate('/meusprojetos');
          })
          .catch((error) => {
            console.log("Erro: ", error.response.data.message);
            setShowbackdrop(false);
            toast.warn(error.response.data.message + " - inversores");
          });
      });

    dados.document_archive &&
      (await submitArchive(
        dados.document_archive,
        "Documento com foto",
        id_project,
      ));
    dados.social_contract &&
      (await submitArchive(
        dados.social_contract,
        "Contrato social",
        id_project,
      ));
    dados.document_energy &&
      (await submitArchive(
        dados.document_energy,
        "Conta de energia da unidade geradora",
        id_project,
      ));
    dados.document_material &&
      (await submitArchive(
        dados.document_material,
        "Pedido de materiais",
        id_project,
      ));
    dados.document_procuration &&
      (await submitArchive(
        dados.document_procuration,
        "Procuração",
        id_project,
      ));
    dados.document_panel_datasheet &&
      (await submitArchive(
        dados.document_panel_datasheet,
        "Datasheet dos painéis",
        id_project,
      ));

    listRateios.map((rateio, idx) =>
      submitArchive(rateio.document_rateio, `Rateio ${idx + 1}`, id_project),
    );

    listAdditional.map((archiveAdit, idx) =>
      submitArchive(
        archiveAdit.document_additional,
        archiveAdit.other_type ? archiveAdit.other_type : archiveAdit.type,
        id_project,
      ),
    );

    listdatasheetinvert.length > 0 &&
      listdatasheetinvert.map(
        async (archiveAdit, idx) =>
          await submitArchive(
            archiveAdit.file,
            "Datasheet do inversor",
            id_project,
          ),
      );

    toast.success("Projeto adicionado ao carrinho com sucesso");
    getShoppingcart(company.id);

    getPoints(company.id);
    getDadosUsuario();
    setTimeout(() => navigate("/meusprojetos"), 200);
  };

  const submitArchive = async (archive, type, id_project) => {
    const config = {
      headers: {
        // Authorization: 'Bearer ' + props.user.token,
        "Content-type": "multipart/form-data",
      },
    };

    let formData = new FormData();

    formData.append("file", archive);
    formData.append("title", type);
    formData.append("project_id", id_project);

    await API.post("/documents", formData, config)
      .then((res) => {
        // setShowbackdrop(false);
        // id_projetct = res.data.id;
        // toast.success('Projeto cadastrado com sucesso!');
        // navigate('/meusprojetos');
      })
      .catch((error) => {
        console.log("Erro: ", error.response.data.message);
        // setShowbackdrop(false);
        toast.warn(error.response.data.message + " - " + type);
        return true;
      });
  };

  useEffect(() => {
    if (qstate) getConcess(qstate);
  }, [qstate]);

  return (
    <Container>
      <Page>
        <header>
          <h1>+ Novo projeto</h1>
          <span>Adicione um novo projeto de forma rápida e prática.</span>
        </header>
        <content>
          <form onSubmit={submeterForm}>
            <h4>1 - Informações da Concessionária</h4>
            <div className="div__multiColumn">
              <select
                required
                name="state"
                style={{ flex: 1 }}
                value={dados.state}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Estado
                </option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>
              <select
                required
                disabled={dados.state ? false : true}
                name="concessionaria"
                style={{ flex: 3 }}
                value={dados.concessionaria}
                onChange={onInputChange}
              >
                {!dados.concessionaria && (
                  <option value="" disabled selected>
                    Nome da concessionária
                  </option>
                )}

                {dados.concessionaria && (
                  <option
                    value={dados.concessionaria}
                    disabled
                    selected
                    style={{ textTransform: "cap" }}
                  >
                    {dados.concessionaria}
                  </option>
                )}
                {dados.state && listConcess.length === 0 && (
                  <option value={""} selected style={{ textTransform: "cap" }}>
                    Nenhuma concessionária cadastrada
                  </option>
                )}
                {listConcess.length > 0 &&
                  listConcess.map((concess, idx) => (
                    <option key={idx + concess.name} value={concess.name}>
                      {concess.name}
                    </option>
                  ))}
              </select>
            </div>
            <h4>2 - Informações do Cliente</h4>
            <div className="div__select">
              <span
                className={pfisic ? "s__select" : ""}
                onClick={() => {
                  setPfisic(true);
                  setDados({
                    ...dados,
                    cpf: "",
                    cnpj: "",
                    name: "",
                    social_reason: "",
                    social_contract: "",
                  });
                }}
              >
                Pessoa Física
              </span>
              <span
                className={!pfisic ? "s__select" : ""}
                onClick={() => {
                  setPfisic(false);
                  setDados({
                    ...dados,
                    cpf: "",
                    cnpj: "",
                    name: "",
                    social_reason: "",
                    social_contract: "",
                  });
                }}
              >
                Pessoa Jurídica
              </span>
            </div>
            <div
              className="div__multiColumn"
              style={{ maxWidth: pfisic ? "" : " " }}
            >
              <input
                type="text"
                placeholder={pfisic ? "CPF" : "CNPJ"}
                value={pfisic ? dados.cpf : dados.cnpj}
                onChange={onInputChange}
                name={pfisic ? "cpf" : "cnpj"}
                style={{ flex: 1 }}
                required
              />

              <input
                type="text"
                placeholder={pfisic ? "Nome completo" : "Razão social"}
                value={pfisic ? dados.full_name : dados.social_reason}
                onChange={onInputChange}
                name={pfisic ? "full_name" : "social_reason"}
                style={{ flex: 3 }}
                required
              />
            </div>
            {!pfisic && (
              <>
                {!dados.social_contract && (
                  <input
                    type="file"
                    id="social_contract"
                    name="social_contract"
                    onChange={loadArchive}
                  />
                )}
                <label
                  className={
                    dados.social_contract
                      ? "div__newfieldarchive div__selectedFieldArchive"
                      : "div__newfieldarchive"
                  }
                  id="social_contract"
                  name="social_contract"
                  htmlFor={dados.social_contract ? "" : "social_contract"}
                  onClick={(event) => {
                    dados.social_contract &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: dados.social_contract,
                        temp: "social_contract",
                      });
                  }}

                  // onClick={() =>
                  //   dados.social_contract
                  //     ? setTimeout(
                  //         () => setDados({ ...dados, social_contract: '' }),
                  //         100
                  //       )
                  //     : true
                  // }
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: "1rem",
                              marginBottom: "-0.2rem",
                            }}
                          />{" "}
                          ANEXAR CONTRATO SOCIAL
                        </p>
                        <p>
                          <b>Nome: </b>
                          {dados.social_contract
                            ? dados.social_contract.name
                            : "..."}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {dados.social_contract
                            ? (
                                dados.social_contract.size /
                                (1024 * 1024)
                              ).toFixed(2) + "Mb"
                            : "..."}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {dados.social_contract
                            ? dados.social_contract.type.split("/")[1]
                            : "..."}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {dados.social_contract ? (
                        <div>
                          <DeleteIcon style={{ fontSize: "1.5rem" }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon /> Clique para anexar o contrato social
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            {!dados.document_archive && (
              <input
                type="file"
                id="document_archive"
                name="document_archive"
                onChange={loadArchive}
              />
            )}
            <label
              className={
                dados.document_archive
                  ? "div__newfieldarchive div__selectedFieldArchive"
                  : "div__newfieldarchive"
              }
              htmlFor={dados.document_archive ? "" : "document_archive"}
              onClick={(event) => {
                dados.document_archive &&
                  setShowconf({
                    ...showconf,
                    status: true,
                    dados: dados.document_archive,
                    temp: "document_archive",
                  });
              }}
            >
              <div className="div__newfieldarchive_in">
                <div className="div__dataArchive">
                  <AttachFileIcon />
                  <div className="div__info">
                    <p>
                      <LabelIcon
                        style={{ fontSize: "1rem", marginBottom: "-0.2rem" }}
                      />{" "}
                      DOCUMENTO COM FOTO{" "}
                      {pfisic ? "" : "DO REPRESENTANTE LEGAL"}
                    </p>
                    <p>
                      <b>Nome: </b>
                      {dados.document_archive
                        ? dados.document_archive.name
                        : "..."}
                    </p>
                    <p>
                      <b>Tamanho: </b>
                      {dados.document_archive
                        ? (dados.document_archive.size / (1024 * 1024)).toFixed(
                            2,
                          ) + "Mb"
                        : "..."}
                    </p>
                    <p>
                      <b>Tipo de arquivo: </b>
                      {dados.document_archive
                        ? dados.document_archive.type.split("/")[1]
                        : "..."}
                    </p>
                  </div>
                </div>

                <div className="div__env_archive">
                  {dados.document_archive ? (
                    <div>
                      <DeleteIcon style={{ fontSize: "1.5rem" }} /> Remover
                      arquivo
                      <br /> selecionado
                    </div>
                  ) : (
                    <div>
                      <BackupIcon /> Clique para anexar um documento com foto
                      <br />
                      (.png .jpeg .pdf)
                    </div>
                  )}
                </div>
              </div>
            </label>
            <h4>
              3 - Informações da instalação - Unidade geradora
              <div className="div__moreOptions">
                <button
                  type="button"
                  className="btn__newDocument"
                  onClick={() => {
                    let aux = listRateios;
                    aux.push({ document_rateio: "" });
                    // console.log(aux);
                    setListRateios([...aux]);
                  }}
                >
                  + Documento de rateio
                </button>
              </div>
            </h4>
            <div className="div__multiColumn" style={{ maxWidth: "690px" }}>
              <select
                required
                name="breaker_type"
                style={{ flex: 1 }}
                value={dados.breaker_type}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Tipo de disjuntor
                </option>
                <option value="monofásico">Monofásico</option>
                <option value="bifásico">Bifásico</option>
                <option value="trifásico">Trifásico</option>
              </select>

              <select
                required
                name="breaker_value"
                style={{ flex: 2 }}
                value={dados.breaker_value}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Valor do disjuntor
                </option>
                {listdisjuntores.map((disj, idx) => (
                  <option value={disj} key={idx}>
                    {disj}
                  </option>
                ))}
              </select>

              <input
                type="text"
                name="other_breaker_value"
                value={dados.other_breaker_value}
                placeholder="Valor do disjuntor (ex. 16 A)"
                style={{
                  flex: 2,
                  visibility:
                    dados.breaker_value === "Outro" ? "visible" : "hidden",
                }}
                onChange={onInputChange}
                required={dados.breaker_value === "Outro" ? true : false}
              />
              <div
                className="pos_field"
                style={{
                  visibility:
                    dados.breaker_value === "Outro" ? "visible" : "hidden",
                }}
              >
                A
              </div>
            </div>
            <div className="div__multiColumn" style={{ maxWidth: "530px" }}>
              <input
                type="number"
                placeholder={"Latitude do projeto"}
                value={dados.latitude}
                // step={'0.000001'}
                onChange={onInputChange}
                name={"latitude"}
                style={{ flex: 1 }}
                required
              />
              <input
                type="number"
                placeholder={"Longitude do projeto"}
                value={dados.longitude}
                onChange={onInputChange}
                // step={'0.000001'}
                name={"longitude"}
                style={{ flex: 1 }}
                required
              />

              <div className="div__moreOptions">
                <button
                  type="button"
                  className="btn__newDocument"
                  style={{
                    alignItems: "center",
                    display: "flex",
                  }}
                  onClick={() => setShowcoord(!showcoord)}
                >
                  <SearchIcon /> Buscar
                </button>
              </div>
              {/* <div className="div__icon_info">
                <InfoOutlinedIcon
                  aria-describedby={'dica__coordenada'}
                  onClick={handleClickPopover}
                />
              </div> */}
            </div>
            <div className="div__multiColumn" style={{ maxWidth: "505px" }}>
              <select
                required
                name="exchange_ownership"
                style={{ flex: 1 }}
                value={dados.exchange_ownership}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Trocar titularidade?
                </option>
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
              </select>
            </div>
            {dados.exchange_ownership === "true" && (
              <>
                {!dados.document_procuration && (
                  <input
                    type="file"
                    id="document_procuration"
                    name="document_procuration"
                    onChange={loadArchive}
                  />
                )}
                <label
                  className={
                    dados.document_procuration
                      ? "div__newfieldarchive div__selectedFieldArchive"
                      : "div__newfieldarchive"
                  }
                  htmlFor={
                    dados.document_procuration ? "" : "document_procuration"
                  }
                  onClick={(event) => {
                    dados.document_procuration &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: dados.document_procuration,
                        temp: "document_procuration",
                      });
                  }}
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: "1rem",
                              marginBottom: "-0.2rem",
                            }}
                          />{" "}
                          ANEXAR PROCURAÇÃO PARA TROCA DE TITULARIDADE
                        </p>
                        <p>
                          <b>Nome: </b>
                          {dados.document_procuration
                            ? dados.document_procuration.name
                            : "..."}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {dados.document_procuration
                            ? (
                                dados.document_procuration.size /
                                (1024 * 1024)
                              ).toFixed(2) + "Mb"
                            : "..."}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {dados.document_procuration
                            ? dados.document_procuration.type.split("/")[1]
                            : "..."}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {dados.document_procuration ? (
                        <div>
                          <DeleteIcon style={{ fontSize: "1.5rem" }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon /> Clique para anexar a procuração
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            {!dados.document_energy && (
              <input
                type="file"
                id="document_energy"
                name="document_energy"
                onChange={loadArchive}
              />
            )}
            <label
              className={
                dados.document_energy
                  ? "div__newfieldarchive div__selectedFieldArchive"
                  : "div__newfieldarchive"
              }
              htmlFor={dados.document_energy ? "" : "document_energy"}
              onClick={(event) => {
                dados.document_energy &&
                  setShowconf({
                    ...showconf,
                    status: true,
                    dados: dados.document_energy,
                    temp: "document_energy",
                  });
              }}
            >
              <div className="div__newfieldarchive_in">
                <div className="div__dataArchive">
                  <AttachFileIcon />
                  <div className="div__info">
                    <p>
                      <LabelIcon
                        style={{
                          fontSize: "1rem",
                          marginBottom: "-0.2rem",
                        }}
                      />{" "}
                      CONTA DE ENERGIA DA UNIDADE GERADORA
                    </p>
                    <p>
                      <b>Nome: </b>
                      {dados.document_energy
                        ? dados.document_energy.name
                        : "..."}
                    </p>
                    <p>
                      <b>Tamanho: </b>
                      {dados.document_energy
                        ? (dados.document_energy.size / (1024 * 1024)).toFixed(
                            2,
                          ) + "Mb"
                        : "..."}
                    </p>
                    <p>
                      <b>Tipo de arquivo: </b>
                      {dados.document_energy
                        ? dados.document_energy.type.split("/")[1]
                        : "..."}
                    </p>
                  </div>
                </div>

                <div className="div__env_archive">
                  {dados.document_energy ? (
                    <div>
                      <DeleteIcon style={{ fontSize: "1.5rem" }} /> Remover
                      arquivo
                      <br /> selecionado
                    </div>
                  ) : (
                    <div>
                      <BackupIcon /> Clique para anexar a conta de energia
                      <br />
                      (.png .jpeg .pdf)
                    </div>
                  )}
                </div>
              </div>
            </label>
            {listRateios.map((rateio, idx) => (
              <div
                key={idx}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {!rateio.document_rateio && (
                  <input
                    type="file"
                    id={`document_rateio_${idx}`}
                    name={`document_rateio_${idx}`}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        if (
                          event.target.files[0].type === "application/pdf" ||
                          event.target.files[0].type === "image/png" ||
                          event.target.files[0].type === "image/jpeg"
                        ) {
                          let aux = listRateios;
                          aux[idx].document_rateio = event.target.files[0];
                          setListRateios([...aux]);
                        } else {
                          toast.error(
                            "Tipo de arquivo inválido, insira .pdf, .png ou .jpeg",
                          );
                        }
                      }
                    }}
                  />
                )}

                {(idx !== 0 || true) && (
                  <div className="div__remove_field_arch">
                    <button
                      type="button"
                      className={
                        rateio.document_rateio
                          ? "btn__remove_field btn__remove_field_disabled"
                          : "btn__remove_field"
                      }
                      onClick={() => {
                        let aux = listRateios;
                        aux.splice(idx, 1);
                        setListRateios([...aux]);
                      }}
                    >
                      Remover campo
                    </button>
                  </div>
                )}

                <label
                  className={
                    rateio.document_rateio
                      ? "div__newfieldarchive div__selectedFieldArchive"
                      : "div__newfieldarchive"
                  }
                  htmlFor={
                    rateio.document_rateio ? "" : `document_rateio_${idx}`
                  }
                  onClick={(event) => {
                    listRateios[idx].document_rateio &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: listRateios[idx].document_rateio,
                        temp: `lista_${idx}`,
                      });
                  }}
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: "1rem",
                              marginBottom: "-0.2rem",
                            }}
                          />{" "}
                          ANEXAR DOCUMENTO PARA RATEIO {idx != 0 ? idx + 1 : ""}
                        </p>
                        <p>
                          <b>Nome: </b>
                          {rateio.document_rateio
                            ? rateio.document_rateio.name
                            : "..."}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {rateio.document_rateio
                            ? (
                                rateio.document_rateio.size /
                                (1024 * 1024)
                              ).toFixed(2) + "Mb"
                            : "..."}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {rateio.document_rateio
                            ? rateio.document_rateio.type.split("/")[1]
                            : "..."}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {rateio.document_rateio ? (
                        <div>
                          <DeleteIcon style={{ fontSize: "1.5rem" }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon /> Clique para anexar os arquivos de
                          rateio
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </div>
            ))}

            <h4>
              4 - Especificações do projeto
              {dados.has_order_material === "false" && (
                <div className="div__moreOptions">
                  <button
                    type="button"
                    className="btn__newDocument"
                    onClick={() => {
                      let aux = listinverters;
                      aux.push({
                        project_id: "",
                        power_kwp: "",
                        model: "",
                        amount: "",
                      });

                      setListinverters([...aux]);

                      let aux2 = checkeds;
                      aux2.check_inverters.push(false);
                      setCheckeds({ ...aux2 });
                    }}
                  >
                    + Inversor
                  </button>
                </div>
              )}
            </h4>
            <div
              className="div__multiColumn"
              style={{ maxWidth: "700px", width: "100%" }}
            >
              <input
                type="number"
                name="total_project_power"
                value={dados.total_project_power}
                min="0"
                step="0.01"
                placeholder="Potência total do projeto em kWp"
                style={{
                  flex: 1,
                  maxWidth: window.innerWidth > 675 && "280px",
                }}
                onChange={onInputChange}
                required
              />
              <div className="pos_field">kWp</div>
            </div>
            <div className="div__multiColumn" style={{ maxWidth: "505px" }}>
              <select
                required
                name="has_order_material"
                style={{ flex: 1 }}
                value={dados.has_order_material}
                onChange={onInputChange}
              >
                <option value="" disabled selected>
                  Sobre os materiais ...
                </option>
                <option value={true}>Possuo pedido dos materiais</option>
                <option value={false}>
                  Não tenho pedido dos materiais realizado
                </option>
              </select>
            </div>
            {dados.has_order_material === "false" && (
              <div className="div__multiColumn" style={{ maxWidth: "100%" }}>
                <input
                  type="text"
                  name="amount_panels"
                  value={dados.amount_panels}
                  placeholder="Qtd. de placas"
                  style={{ flex: 1.19 }}
                  onChange={onInputChange}
                  required
                />
                <div className="pos_field">Placa(s)</div>

                <input
                  type="number"
                  min="0"
                  step="0.01"
                  name="panel_power"
                  value={dados.panel_power}
                  placeholder="Potência"
                  style={{ flex: 1 }}
                  onChange={onInputChange}
                  required
                />

                <div className="pos_field">Wp</div>

                <input
                  type="text"
                  name="panel_model"
                  value={dados.panel_model}
                  placeholder="Modelo das placas"
                  style={{ flex: 2 }}
                  onChange={onInputChange}
                />

                <div className="div__field_check">
                  <input
                    type="checkbox"
                    id="check_panels"
                    name="check_panels"
                    className="check_panels"
                    checked={checkeds.check_panels}
                    onChange={() =>
                      setCheckeds({
                        ...checkeds,
                        check_panels: !checkeds.check_panels,
                      })
                    }
                  />
                  <label htmlFor="check_panels">Anexar datasheet</label>
                </div>
              </div>
            )}
            {checkeds.check_panels && (
              <>
                {!dados.document_panel_datasheet && (
                  <input
                    type="file"
                    id="document_panel_datasheet"
                    name="document_panel_datasheet"
                    onChange={loadArchive}
                  />
                )}
                <label
                  className={
                    dados.document_panel_datasheet
                      ? "div__newfieldarchive div__selectedFieldArchive"
                      : "div__newfieldarchive"
                  }
                  htmlFor={
                    dados.document_panel_datasheet
                      ? ""
                      : "document_panel_datasheet"
                  }
                  onClick={(event) => {
                    dados.document_panel_datasheet &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: dados.document_panel_datasheet,
                        temp: "document_panel_datasheet",
                      });
                  }}
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: "1rem",
                              marginBottom: "-0.2rem",
                            }}
                          />{" "}
                          DATASHEET DAS PLACAS
                        </p>
                        <p>
                          <b>Nome: </b>
                          {dados.document_panel_datasheet
                            ? dados.document_panel_datasheet.name
                            : "..."}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {dados.document_panel_datasheet
                            ? (
                                dados.document_panel_datasheet.size /
                                (1024 * 1024)
                              ).toFixed(2) + "Mb"
                            : "..."}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {dados.document_panel_datasheet
                            ? dados.document_panel_datasheet.type.split("/")[1]
                            : "..."}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {dados.document_panel_datasheet ? (
                        <div>
                          <DeleteIcon style={{ fontSize: "1.5rem" }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon />
                          Clique para anexar datasheet das placas
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            {dados.has_order_material === "false" &&
              listinverters.map((invert, idx) => (
                <>
                  <div
                    className="div__multiColumn"
                    style={{ maxWidth: "100%" }}
                  >
                    <input
                      type="text"
                      name="qtd_inverters"
                      value={invert.amount}
                      placeholder="Qtd. de inversores"
                      style={{
                        flex: 1,
                        maxWidth: window.innerWidth > 675 && "202px",
                      }}
                      onChange={(e) => {
                        // console.log(listinverters);
                        let aux = listinverters;
                        aux[idx].amount = mask(e.target.value, "9999999");
                        setListinverters([...aux]);
                      }}
                      required
                    />
                    <div className="pos_field">Inversor(es)</div>

                    <input
                      type="number"
                      min="0"
                      step="0.01"
                      name="power_inverters"
                      value={invert.power_kwp}
                      placeholder="Potência"
                      style={{
                        flex: 1,
                        maxWidth: window.innerWidth > 675 && "202px",
                      }}
                      onChange={(e) => {
                        let aux = listinverters;
                        setListinverters([...aux]);
                        aux[idx].power_kwp = e.target.value;
                        // old code
                        // aux[idx].power_kwp = mask(e.target.value, '9999999');
                      }}
                      required
                    />
                    <div className="pos_field">kWp</div>

                    <input
                      type="text"
                      name="inverters_model"
                      value={invert.model}
                      placeholder="Modelo dos inversores"
                      style={{ flex: 2 }}
                      onChange={(e) => {
                        // console.log(listinverters);
                        let aux = listinverters;
                        aux[idx].model = e.target.value;
                        setListinverters([...aux]);
                      }}
                    />

                    <div className="div__field_check">
                      <input
                        type="checkbox"
                        id={`check_inverters-${idx}`}
                        name={`check_inverters-${idx}`}
                        className="check_panels"
                        checked={checkeds.check_inverters[idx]}
                        onChange={() => {
                          let aux = checkeds;
                          aux.check_inverters[idx] =
                            !checkeds.check_inverters[idx];
                          setCheckeds({ ...aux });
                        }}
                      />
                      <label htmlFor={`check_inverters+${idx}`}>
                        Anexar datasheet
                      </label>
                    </div>
                  </div>
                  {checkeds.check_inverters[idx] && (
                    <>
                      {!listdatasheetinvert.find((a) => a.id === idx) && (
                        <input
                          type="file"
                          id={`document_inverters_datasheet-` + idx}
                          name={`document_inverters_datasheet-` + idx}
                          onChange={(event) => {
                            loaddatasheetinver(event, idx);
                          }}
                        />
                      )}

                      <label
                        className={
                          listdatasheetinvert.find((a) => a.id === idx)
                            ? "div__newfieldarchive div__selectedFieldArchive"
                            : "div__newfieldarchive "
                        }
                        htmlFor={
                          listdatasheetinvert.find((a) => a.id === idx)
                            ? ""
                            : `document_inverters_datasheet-` + idx
                        }
                        onClick={(event) => {
                          listdatasheetinvert.find((a) => a.id === idx) &&
                            setShowconf({
                              ...showconf,
                              status: true,
                              dados: listdatasheetinvert.find(
                                (a) => a.id === idx,
                              ).file,
                              temp: `lista3_${idx}`,
                            });
                        }}
                      >
                        <div className="div__newfieldarchive_in">
                          <div className="div__dataArchive">
                            <AttachFileIcon />
                            <div className="div__info">
                              <p>
                                <LabelIcon
                                  style={{
                                    fontSize: "1rem",
                                    marginBottom: "-0.2rem",
                                  }}
                                />{" "}
                                DATASHEET DO INVERSOR
                              </p>
                              <p>
                                <b>Nome: </b>
                                {listdatasheetinvert.find((a) => a.id === idx)
                                  ? listdatasheetinvert.find(
                                      (a) => a.id === idx,
                                    ).file.name
                                  : "..."}
                              </p>
                              <p>
                                <b>Tamanho: </b>
                                {listdatasheetinvert.find((a) => a.id === idx)
                                  ? (
                                      listdatasheetinvert.find(
                                        (a) => a.id === idx,
                                      ).file.size /
                                      (1024 * 1024)
                                    ).toFixed(2) + "Mb"
                                  : "..."}
                              </p>
                              <p>
                                <b>Tipo de arquivo: </b>
                                {listdatasheetinvert.find((a) => a.id === idx)
                                  ? listdatasheetinvert
                                      .find((a) => a.id === idx)
                                      .file.type.split("/")[1]
                                  : "..."}
                              </p>
                            </div>
                          </div>

                          <div className="div__env_archive">
                            {listdatasheetinvert.find((a) => a.id === idx) ? (
                              <div>
                                <DeleteIcon style={{ fontSize: "1.5rem" }} />{" "}
                                Remover arquivo
                                <br /> selecionado
                              </div>
                            ) : (
                              <div>
                                <BackupIcon />
                                Clique para anexar datasheet dos inversores
                                <br />
                                (.png .jpeg .pdf)
                              </div>
                            )}
                          </div>
                        </div>
                      </label>
                    </>
                  )}
                </>
              ))}

            {dados.has_order_material === "true" && (
              <>
                {!dados.document_material && (
                  <input
                    type="file"
                    id="document_material"
                    name="document_material"
                    onChange={loadArchive}
                  />
                )}
                <label
                  className={
                    dados.document_material
                      ? "div__newfieldarchive div__selectedFieldArchive"
                      : "div__newfieldarchive"
                  }
                  for="document_material"
                  onClick={(event) => {
                    dados.document_material &&
                      setShowconf({
                        ...showconf,
                        status: true,
                        dados: dados.document_material,
                        temp: "document_material",
                      });
                  }}
                >
                  <div className="div__newfieldarchive_in">
                    <div className="div__dataArchive">
                      <AttachFileIcon />
                      <div className="div__info">
                        <p>
                          <LabelIcon
                            style={{
                              fontSize: "1rem",
                              marginBottom: "-0.2rem",
                            }}
                          />{" "}
                          ANEXAR PEDIDO DOS MATERIAIS
                        </p>
                        <p>
                          <b>Nome: </b>
                          {dados.document_material
                            ? dados.document_material.name
                            : "..."}
                        </p>
                        <p>
                          <b>Tamanho: </b>
                          {dados.document_material
                            ? (
                                dados.document_material.size /
                                (1024 * 1024)
                              ).toFixed(2) + "Mb"
                            : "..."}
                        </p>
                        <p>
                          <b>Tipo de arquivo: </b>
                          {dados.document_material
                            ? dados.document_material.type.split("/")[1]
                            : "..."}
                        </p>
                      </div>
                    </div>

                    <div className="div__env_archive">
                      {dados.document_material ? (
                        <div>
                          <DeleteIcon style={{ fontSize: "1.5rem" }} /> Remover
                          arquivo
                          <br /> selecionado
                        </div>
                      ) : (
                        <div>
                          <BackupIcon />
                          Clique para anexar o pedido dos materiais
                          <br />
                          (.png .jpeg .pdf)
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              </>
            )}
            <br />
            <h4>
              5 - Documentos complementares (opcional){" "}
              <div className="div__moreOptions">
                <button
                  type="button"
                  className="btn__newDocument"
                  onClick={() => {
                    let aux = listAdditional;
                    aux.push({
                      type: "",
                      other_type: "",
                      document_additional: "",
                    });

                    setListAdditional([...aux]);
                  }}
                >
                  + Documento
                </button>
              </div>
            </h4>

            {listAdditional.map((document, idx) => (
              <div
                style={{ display: "flex", flexDirection: "column" }}
                key={idx}
              >
                <div
                  style={{
                    // flex: 1,
                    // width: '100%',
                    textAlign: "left",
                    margin: "0rem 2rem",
                  }}
                >
                  5.{idx + 1} - Documento {idx + 1}
                </div>
                <div className="div__multiColumn" style={{ maxWidth: "505px" }}>
                  <select
                    name="type_additional"
                    style={{ flex: 1 }}
                    value={document.type}
                    onChange={(a) => {
                      let aux = listAdditional;
                      aux[idx].type = a.target.value;
                      if (a.target.value !== "outro") aux[idx].other_type = "";
                      setListAdditional([...aux]);
                    }}
                    required
                  >
                    <option value="" disabled selected>
                      Tipo de documento
                    </option>
                    <option value="procuração">Procuração</option>
                    <option value="documento com foto">
                      Documento com foto
                    </option>
                    <option value="outro">Outro</option>
                  </select>

                  <input
                    type="text"
                    name="other_type"
                    value={document.other_type}
                    placeholder="Qual?"
                    style={{
                      flex: 1,
                      maxWidth: window.innerWidth > 675 && "202px",
                      visibility:
                        document.type === "outro" ? "visible" : "hidden",
                    }}
                    onChange={(a) => {
                      let aux = listAdditional;
                      aux[idx].other_type = a.target.value;
                      setListAdditional([...aux]);
                    }}
                    required={document.type === "outro" ? true : false}
                  />
                </div>

                {!document.document_additional && (
                  <input
                    type="file"
                    id={`document_additional-${idx}`}
                    name={`document_additional-${idx}`}
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        if (
                          event.target.files[0].type === "application/pdf" ||
                          event.target.files[0].type === "image/png" ||
                          event.target.files[0].type === "image/jpeg"
                        ) {
                          let aux = listAdditional;
                          aux[idx].document_additional = event.target.files[0];
                          setListAdditional([...listAdditional]);
                        } else {
                          toast.error(
                            "Tipo de arquivo inválido, insira .pdf, .png ou .jpeg",
                          );
                        }
                      }
                    }}
                  />
                )}
                {document.type && (
                  <div className="div__remove_field_arch">
                    <button
                      type="button"
                      className={
                        document.document_additional
                          ? "btn__remove_field btn__remove_field_disabled"
                          : "btn__remove_field"
                      }
                      onClick={() => {
                        let aux = listAdditional;
                        aux.splice(idx, 1);
                        setListAdditional([...aux]);
                      }}
                    >
                      Remover campo
                    </button>
                  </div>
                )}
                {document.type && (
                  <label
                    className={
                      document.document_additional
                        ? "div__newfieldarchive div__selectedFieldArchive"
                        : "div__newfieldarchive"
                    }
                    htmlFor={
                      document.document_additional
                        ? ""
                        : `document_additional-${idx}`
                    }
                    onClick={(event) => {
                      document.document_additional &&
                        setShowconf({
                          ...showconf,
                          status: true,
                          dados: listAdditional[idx].document_additional,
                          temp: `lista2_${idx}`,
                        });
                    }}

                    // onClick={() =>
                    //   document.document_additional
                    //     ? setTimeout(() => {
                    //         let aux = listAdditional;
                    //         aux[idx].document_additional = '';
                    //         setListAdditional([...listAdditional]);
                    //       }, 100)
                    //     : true
                    // }
                  >
                    <div className="div__newfieldarchive_in">
                      <div className="div__dataArchive">
                        <AttachFileIcon />
                        <div className="div__info">
                          <p style={{ textTransform: "uppercase" }}>
                            <LabelIcon
                              style={{
                                fontSize: "1rem",
                                marginBottom: "-0.2rem",
                              }}
                            />{" "}
                            {document.type ? document.type : " . . . "}{" "}
                            {document.other_type
                              ? `(${document.other_type})`
                              : ""}
                          </p>
                          <p>
                            <b>Nome: </b>
                            {document.document_additional
                              ? document.document_additional.name
                              : "..."}
                          </p>
                          <p>
                            <b>Tamanho: </b>
                            {document.document_additional
                              ? (
                                  document.document_additional.size /
                                  (1024 * 1024)
                                ).toFixed(2) + "Mb"
                              : "..."}
                          </p>
                          <p>
                            <b>Tipo de arquivo: </b>
                            {document.document_additional
                              ? document.document_additional.type.split("/")[1]
                              : "..."}
                          </p>
                        </div>
                      </div>

                      <div className="div__env_archive">
                        {document.document_additional ? (
                          <div>
                            <DeleteIcon style={{ fontSize: "1.5rem" }} />{" "}
                            Remover arquivo
                            <br /> selecionado
                          </div>
                        ) : (
                          <div>
                            <BackupIcon /> Clique para anexar a conta de energia
                            <br />
                            (.png .jpeg .pdf)
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                )}
              </div>
            ))}

            <button type="submit" className="btn__finish">
              Prosseguir
            </button>
            <br />
          </form>
        </content>
      </Page>

      {/* campos de dicas */}
      <Popover
        id={"dica__coordenada"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "2rem", maxWidth: "450px" }}>
          <Containerdica>
            <h3>
              Como encontrar a coordenada do projeto <EmojiObjectsIcon />
            </h3>
            <h4>1º - Acesso o site do Google Maps</h4>
            <span>
              Link:{" "}
              <a
                href="https://www.google.com.br/maps"
                target="_blank"
                rel="noreferrer"
              >
                https://www.google.com.br/maps
              </a>
            </span>
            <h4>2º Busque pela localização desejada no campo abaixo</h4>
            <img src={Imgcoordinate} />
            <h4>
              3º Demarque o ponto no mapa cliando com botão esquerdo do{" "}
              <i>mouse</i>
            </h4>
            <img src={Imgcoordinate2} />
            <h4>
              4º Clique com botão direito do <i>mouse</i> e em seguida nas{" "}
              coordenadas para serem automaticamente copiadas
            </h4>
            <img src={Imgcoordinate3} />
          </Containerdica>
        </div>
      </Popover>

      {showconf.status && (
        <Dialogoconfirmacao
          status={showconf.status}
          width={showconf.width}
          delete={showconf.delete}
          description={showconf.description}
          fechar={() =>
            setShowconf({ ...showconf, dados: "", status: false, temp: "" })
          }
          confirmar={
            () => {
              let aux = showconf.temp.split("_");
              // console.log('chegando aqui:', aux);

              if (aux[0] === "lista") {
                if (listRateios[aux[1]].document_rateio) {
                  toast.success(
                    `Arquivo ${showconf.dados.name} removido com sucesso`,
                  );
                  setTimeout(() => {
                    let aux2 = listRateios;
                    aux2[aux[1]].document_rateio = "";
                    setListRateios([...aux2]);
                  }, 100);
                  setShowconf({
                    ...showconf,
                    dados: "",
                    status: false,
                    temp: "",
                  });
                }
              } else if (aux[0] === "lista2") {
                if (listAdditional[aux[1]].document_additional) {
                  toast.success(
                    `Arquivo ${showconf.dados.name} removido com sucesso`,
                  );

                  setTimeout(() => {
                    let aux2 = listAdditional;
                    aux2[aux[1]].document_additional = "";
                    setListAdditional([...aux2]);
                  }, 100);

                  setShowconf({
                    ...showconf,
                    dados: "",
                    status: false,
                    temp: "",
                  });
                }
              } else if (aux[0] === "lista3") {
                let indice = listdatasheetinvert.indexOf(
                  listdatasheetinvert.find((a) => a.id === aux[1]),
                );

                if (
                  listdatasheetinvert.find(
                    (valor) => "" + valor.id === "" + aux[1],
                  )
                ) {
                  toast.success(
                    `Arquivo ${showconf.dados.name} removido com sucesso`,
                  );

                  setTimeout(() => {
                    let aux2 = listdatasheetinvert;
                    aux2.splice(indice, 1);
                    setListdatasheetinvert([...aux2]);
                  }, 100);

                  setShowconf({
                    ...showconf,
                    dados: "",
                    status: false,
                    temp: "",
                  });
                }
              } else {
                if (showconf.dados) {
                  setTimeout(() => {
                    toast.success(
                      `Arquivo ${showconf.dados.name} removido com sucesso`,
                    );
                    setDados({ ...dados, [showconf.temp]: "" });
                    setShowconf({
                      ...showconf,
                      dados: "",
                      status: false,
                      temp: "",
                    });
                  }, 100);
                }
              }
            }

            // toast.success(
            //   `Arquivo ${showconf.dados.name} removido com sucesso`
            // )
          }
        />
      )}
      {finish && (
        <Dialogoconfirmarproject
          dados={dados}
          credits={credits}
          setCredits={(credit_erd) => setCredits(credit_erd)}
          listinverters={listinverters}
          fechar={() => setFinish(false)}
          listRateios={listRateios}
          listAdditional={listAdditional}
          setDescontos={setDescontos}
          confirmar={() => enviarProjeto()}
        />
      )}
      {showcoord && (
        <Dialogoencontrarlocalizacao
          fechar={() => setShowcoord(false)}
          dados={dados}
          confirmar={(a) =>
            setDados({
              ...dados,
              longitude: a.lng,
              latitude: a.lat,
            })
          }
        />
      )}

      {!completedData && <Dialogocompletardados />}
      {showbackdrop && <Backdrop />}
    </Container>
  );
}

function NewProjectLogged() {
  return <WithAuthenticatedUser render={() => <Newproject />} />;
}

export default NewProjectLogged;
