import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Container, FielButtons, DivButtons, Conteudo } from './styles';

// busca por geolocalização
import { geosearch, arcgisOnlineProvider } from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import { BsFillPinFill, BsPinAngleFill } from 'react-icons/bs';

var localizacao;

function DisplayPosition({ map, provider, setLocaleSelected }) {
  const [position, setPosition] = useState(map.getCenter());

  // console.log('ZOOM: ', map.getZoom());

  const onClick = useCallback(
    ({ latitude, longitude, zoom }) => {
      map.setView([latitude, longitude], zoom);
    },
    [map]
  );

  const onMove = useCallback(() => {
    setPosition(map.getCenter());
  }, [map]);

  useEffect(() => {
    map.on('move', onMove);

    return () => {
      map.off('move', onMove);
    };
  }, [map, onMove]);

  useEffect(() => {
    localizacao = map.getCenter();
    // console.log('Centro: ', map.getCenter(), '- zoom:', map.getZoom());
  }, [position]);

  useEffect(() => {
    if (!map) return;

    const control = geosearch({
      position: 'topright',
      placeholder: 'Buscar localização',
      useMapBounds: false,
      providers: [
        arcgisOnlineProvider({
          apikey:
            'AAPK64e86b8a7b0345da8f94014704c5bddb4kWKfa94A0zSZhJxzOImphcHIjnmSOjcWttyYZ_jH5EjUvoz9NgxB3IhXduA7B2Q', // replace with your api key - https://developers.arcgis.com
        }),
      ],
    });
    control.addTo(map);
  }, []);

  return <DivButtons></DivButtons>;
}

function Mapa(props) {
  const [map, setMap] = useState(null);
  const [localeSelected, setLocaleSelected] = useState(null);

  const [provider, setProvider] = useState({
    id: 1,
    latitude: props.dados.latitude ? props.dados.latitude : -14.234813609898792,
    longitude: props.dados.longitude
      ? props.dados.longitude
      : -51.9253484068225,
    zoom: props.dados.longitude && props.dados.latitude ? 18 : 3,
    state: 'CE',
    city: 'Sobral',
    // locales: [
    //   {
    //     id: 1,
    //     latitude: -3.6871101,
    //     longitude: -40.3549084,
    //     zoom: 16,
    //     principal: true,
    //     type: 'Brisanet Centro',
    //     street: 'R. Conselheiro José Júlio',
    //     number: '877',
    //     district: 'Centro',
    //     zip_code: '62010-820',
    //   },
    //   {
    //     id: 2,
    //     latitude: -3.6814711,
    //     longitude: -40.3397704,
    //     zoom: 16,
    //     principal: false,
    //     type: 'Container',
    //     street: 'Av. Dr. Guarani',
    //     number: '231',
    //     district: 'Derby Clube',
    //     zip_code: '62042-030',
    //   },
    //   {
    //     id: 3,
    //     latitude: -3.6976312,
    //     longitude: -40.3481958,
    //     zoom: 16,
    //     principal: false,
    //     type: 'Brisanet Shopping',
    //     street: 'Shopping - Av. Monsenhor Aloísio Pinto',
    //     number: '300',
    //     district: 'Dom Expedito',
    //     zip_code: '62050-255',
    //   },
    //   {
    //     id: 4,
    //     latitude: -3.7040957,
    //     longitude: -40.3334114,
    //     zoom: 16,
    //     principal: false,
    //     type: 'Casa do Luan',
    //     street: 'Rua Professora Artemísia',
    //     number: '550',
    //     district: 'Sinhá Saboia',
    //     zip_code: '62050-410',
    //   },
    // ],
  });

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={[provider.latitude, provider.longitude]}
        zoom={provider.zoom}
        scrollWheelZoom={true}
        whenCreated={setMap}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* {provider.locales.map((locale) => (
          <Marker
            // icon={locationMartkerSolidIcon}
            position={[locale.latitude, locale.longitude]}
          >
            <Popup>
              {locale.type} <br /> {locale.principal ? 'Principal' : 'Filial'}
            </Popup>
          </Marker>
        ))} */}
      </MapContainer>
    ),
    []
  );

  return (
    <Container BsPin={BsFillPinFill}>
      {map ? (
        <DisplayPosition
          map={map}
          provider={provider}
          setLocaleSelected={setLocaleSelected}
        />
      ) : null}
      {displayMap}
      <div className="div__mapa">
        <BsFillPinFill className="icon__marker" />
        <BsPinAngleFill className="icon__shadow" />
      </div>
    </Container>
  );
}

export default function Dialogoconfirmacao(props) {
  const [open, setOpen] = React.useState(props.status ? props.status : true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={props.width ? props.width : 'sm'}
        fullWidth
        // fullScreen={window.innerWidth > 675 ? false : true}
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9999999999 }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontFamily: "'Poppins', sans-serif",
            color: 'var(--primary2)',
          }}
        >
          Selecionar localização do projeto
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontFamily: "'Poppins', sans-serif" }}
          >
            <Mapa dados={props.dados} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons delete={props.delete}>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                props.confirmar(localizacao);
                handleClose();
              }}
              className="btn__register"
            >
              Selecionar
            </button>
          </FielButtons>
        </DialogActions>
      </Dialog>
    </div>
  );
}
