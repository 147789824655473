import styled from 'styled-components';

export const Container = styled.div`
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */

  .div__oneColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    .div__data_copy {
      display: flex;
      flex-direction: row;
      border: 1px solid lightgray;
      padding: 0rem;
      border-radius: 1rem;
      margin: 0.5rem 0rem;

      input[type='text'] {
        border: 0;
        margin: 0rem;
        padding: 0.75rem 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      svg[name='copy'] {
        font-size: 1rem;
        margin-bottom: -0.1rem;
        margin-right: 0rem;
        color: gray;

        cursor: pointer;
        :hover {
          color: var(--primary2);
        }
      }
    }
  }

  .div__field_qrcode_image {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 280px;
      height: 280px;
    }

    .div__await_qrcode {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.05);
      width: 230px;
      height: 230px;
      margin: auto;
    }
  }

  .div__page_body_method_slip {
    /* border: 2px solid var(--primary2); */
    display: flex;
    flex-direction: column;
    border-radius: 0rem 0rem 1rem 1rem;
    padding: 2rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    gap: 1rem;
    input {
      width: auto;
    }
    select {
      width: auto;
      height: 40px;
    }

    label {
      display: flex;
      flex-direction: row;
      flex: 1;
      width: auto;
      font-weight: 400;
      align-items: center;

      svg {
        margin: 0rem 1rem;
      }
    }
    .label__validate_code {
      max-width: 150px;
    }
    .div__contains_card_code_image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
    .img__security_code {
      max-width: 100px;
      height: auto;
    }

    .div__important_notes {
      padding: 1rem;
      p {
        font-size: 0.75rem;
      }
    }

    .btn__confirm_payment {
      font-family: 'Poppins', sans-serif;
      text-transform: uppercase;
      margin: auto;
      width: 250px;
      height: 45px;
      background-color: var(--primary2);
      border: 0px;
      border-radius: 1.4rem;
      color: var(--white);
      /* margin-top: 1rem; */
      /* margin-bottom: 0.75rem; */
      cursor: pointer;
      :hover,
      :focus {
        box-shadow: 0px 0px 20px rgba(var(--black_rgb), 0.4);
        filter: brightness(1.4);
      }
    }
    .btn__confirm_payment_disabled {
      pointer-events: none !important;
      cursor: default !important;
      background-color: gray !important;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    /* Firefox */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes fadein {
    /* Opera */
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
