import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  margin: 0rem 1.25rem 1rem 0rem;
  right: 0;
  bottom: 0;

  z-index: 99;

  .chat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: linear-gradient(#4df38a, #25d366);
    border-radius: 50%;
    box-shadow: 0 0 1em #808080;
    height: 4rem;
    width: 4rem;

    transition: ease-in-out 0.3s;
    animation: chat 0.8s forwards ease-in-out;

    cursor: pointer;

    svg {
      color: #fff;
      height: 50%;
      width: 50%;
    }
  }

  .chat:hover {
    height: 4.5rem;
    width: 4.5rem;
  }
`;
