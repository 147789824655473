import React from 'react';
import './App.css';

// notificações
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RoutesApp } from './routes';

// context
import { AuthProvider } from './context/AuthContext';
import { UserDataProvider } from './context/UserDataContext';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <UserDataProvider>
          <RoutesApp />
        </UserDataProvider>
      </AuthProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          zIndex: 999999,
        }}
      />
    </div>
  );
}

export default App;
