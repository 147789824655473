import React, { ReactNode } from 'react';

import { Container } from './styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Imgtrocarsenha from '../../assets/images/icons/trocar_senha.png';

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';

import { Conteudo } from './styles';

function Dialogotrocarsenha({ negar, confirmar, loginData }) {
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = React.useState(false);

  const [dados, setDados] = React.useState({ password: '', re_password: '' });
  const handleClose = () => {
    setOpen(false);
    negar();
  };

  const validarSenhas = (e) => {
    if (dados.password.length > 0) {
      if (dados.password.length > 5) {
        if (dados.password === dados.re_password) {
          confirmar(dados.password);
        } else {
          toast.error('Suas senhas não correspondem.');
          return false;
        }
      } else {
        toast.error('Sua senha deve conter, no mínimo, 6 caractesres.');
        return false;
      }
    } else {
      toast.error('Preencha corretamente o campo indicado com sua nova senha.');
      return false;
    }
  };

  return (
    <Dialog
      // fullWidth={fullWidth}
      // maxWidth={maxWidth}
      fullWidth={window.innerWidth < 675 ? true : false}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      style={{
        zIndex: 9999,
      }}
    >
      <DialogTitle
        id="max-width-dialog-title"
        style={{ fontFamily: "'Poppins', sans-serif !important" }}
      >
        Trocar Senha{' '}
        {dados.name ? dados.name : dados.full_name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Conteudo>
            <img src={Imgtrocarsenha} />
            <div className="div__one_row">
              <input
                autoFocus
                type={show ? 'text' : 'password'}
                placeholder="Digite a nova senha"
                value={dados.password}
                onChange={(a) =>
                  setDados({ ...dados, password: a.target.value })
                }
              />{' '}
              <button onClick={() => setShow(!show)}>
                {!show ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
              </button>
            </div>
            <div className="div__one_row">
              <input
                type="password"
                placeholder="Confirme a senha"
                value={dados.re_password}
                onChange={(a) =>
                  setDados({ ...dados, re_password: a.target.value })
                }
              />{' '}
            </div>
            <ul>
              <li>Senha deve conter no mínimo 6 caracteres</li>
              <li>
                Para uma senha forte, é aconselhável que contenha números,
                letras e caracteres especiais
              </li>
              <li>
                Sua senha e credenciais de acesso são de uso pessoal, evite
                informar a terceiros
              </li>
            </ul>
          </Conteudo>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => negar()} color="" variant="contained">
          Cancelar
        </Button>
        <Button
          onClick={() => validarSenhas()}
          // color="secondary"
          variant="contained"
          style={{
            backgroundColor: 'var(--primary2)',
            color: '#fff',
          }}
        >
          Substituir senha
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dialogotrocarsenha;
