import { Container } from './styles';

// icones
import PrintIcon from '@material-ui/icons/Print';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';

// api
import api from '../../services/api';
// context
import { useUserData } from '../../context/UserDataContext';
// toast
import { toast } from 'react-toastify';
import { useState } from 'react';
import Backdrop from '../Backdrop';

function Creditcardpaymentmethod(props) {
  const {
    addressPerson,
    loginData,
    numbersContact,
    person,
    company,
    getShoppingcart,
    getPoints,
  } = useUserData();

  const [showbackdrop, setShowbackdrop] = useState(false);

  const [dataBoleto, setDataBoleto] = useState({
    boletoUrl: '',
    idPayment: '',
  });

  const handleSubmitPayment = async () => {
    setShowbackdrop(true);

    const paymentData = {
      receipts: props.selectedProjects,
      payment_type: 'boleto',
      installments: '1',
      customer_name: person.full_name,
      customer_email: loginData.email,
      customer_mobile: numbersContact[0].number,
      customer_document: company.cnpj ? company.cnpj : person.cpf,
      billing_address: addressPerson.street,
      billing_number: addressPerson.number,
      billing_neighborhood: addressPerson.district,
      billing_state: addressPerson.state,
      billing_city: addressPerson.city,
      billing_zip_code: addressPerson.zip_code,
      credits_discount: props.credits.value,
      coupon_code: props.cupons.code,
    };

    await api
      .post('/transactions', paymentData)
      .then(async (res) => {
        props.setPaymentInitiated(true);
        props.setPaymentBoleto(true);
        let auxData = JSON.parse(res.data.processor_response);
        setDataBoleto({
          ...dataBoleto,
          boletoUrl: auxData.boleto_url,
          idPayment: res.data.id,
        });
        getShoppingcart(company?.id);
        getPoints(company?.id);
      })
      .catch((error) => {
        console.log('Erro: ', error.response.data.message);
        setShowbackdrop(false);
        toast.warn(error.response.data.message);
      })
      .finally(() => setShowbackdrop(false));
  };

  return (
    <Container>
      <div className="div__page_body_method_slip">
        <div className="div__field_download_boleto">
          <a
            target="_blank"
            href={dataBoleto.boletoUrl}
            className={
              dataBoleto.boletoUrl
                ? 'btn__download_boleto'
                : 'btn__download_boleto btn__confirm_payment_disabled'
            }
            rel="noreferrer"
          >
            <PrintIcon /> Baixar boleto
          </a>

          <label>Feche a compra para liberar o download.</label>
        </div>
        <label>
          {' '}
          <PrintIcon /> Imprima o boleto e pague no banco de sua preferência
        </label>
        <label>
          <AspectRatioIcon />
          Ou se preferir, também é possível pagar pela internet utilizando
          código de barras do boleto
        </label>
        <label>
          <CalendarTodayIcon /> E lembre-se, o boleto gerado possui validade de
          1 dia útil
        </label>
        <label>
          <AccessAlarmsIcon /> A confirmação pode levar até 72h após a
          realização do pagamento
        </label>

        <div className="div__important_notes">
          <p>
            <b>Importante</b>
          </p>
          <p>- não faça transferência ou depósito entre contas;</p>
          <p>- O boleto não é enviado pelos correios;</p>
          <p>- Imprima-o e pague-o no banco ou pela internet;</p>
          <p>
            - Se o boleto não for pago até a data de vencimento, o boleto será
            automaticamente cancelado;
          </p>
        </div>
        <button
          type="submit"
          onClick={() => handleSubmitPayment()}
          className={
            dataBoleto.boletoUrl
              ? 'btn__confirm_payment btn__confirm_payment_disabled'
              : 'btn__confirm_payment'
          }
        >
          Fechar compra
        </button>
      </div>
      {showbackdrop && (
        <Backdrop
          message={'Sua solicitação está sendo processada, por favor aguarde.'}
        />
      )}
    </Container>
  );
}

export default Creditcardpaymentmethod;
