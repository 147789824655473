import { ReactNode, useState } from "react";
import Dialogotrocarsenha from "../../../components/Dialogotrocarsenha";
import Dialogoalterardados from "../../../components/Dialogoalterardados";
import { mask } from "remask";
import Dialogonewuser from "../../../components/Dialogonewuser";

import { Container, Page, Card, FieldButtons } from "./styles";

import Image04 from "../../../assets/images/prints/money_04.png";
import solarImage2 from "../../../assets/images/prints/solar-image2.png";

// icones
import { BsSortAlphaDown, BsSortAlphaUpAlt } from "react-icons/bs";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

// confirmacao
import Dialogonovocredito from "../../../components/Dialogonovocredito";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import Backdrop from "../../../components/Backdrop";

import { useUserData } from "../../../context/UserDataContext";
// render props
import { WithAuthenticatedUser } from "../../../components/WithAuthenticatedUser";

function NewCredit(props) {
  const navigate = useNavigate();
  const { company, points, getShoppingcart } = useUserData();

  const [listConcess, setListConcess] = useState([]);
  const [shownovocredito, setShownovocredito] = useState(false);
  const [showbackdrop, setShowbackdrop] = useState(false);

  const [dados, setDados] = useState({
    project_power: "",
    note: "",
    points: 10,
  });

  const getConcess = async (value) => {
    setShowbackdrop(true);
    // const config = {
    //   headers: {
    //     Authorization: 'Bearer ' + props.user.token,
    //     'Content-Type': 'application/json',
    //   },
    // };

    await api
      .get(`/dealers/state/${value}`)
      .then((res) => {
        // console.log(res.data);
        setListConcess(res.data);
        setShowbackdrop(false);
      })
      .catch((error) => {
        console.log("Error: ", error.response.data);
        setShowbackdrop(false);
      });
  };

  const submitInfor = async (event) => {
    event.preventDefault();
    setShownovocredito(true);
  };

  const onInputChange = (e) => {
    if (e.target.name == "cpf") {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "999.999.999-99"),
      });
    } else if (e.target.name == "cnpj") {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "99.999.999/9999-­99"),
      });
    } else if (
      e.target.name === "qtd_panel" ||
      e.target.name === "qtd_inverters"
    ) {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "99999999"),
      });
    } else if (e.target.name === "client_count" && e.target.value === "está") {
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
        document_procuration: "",
      });
    } else if (e.target.name === "have_list_materials") {
      if (e.target.value === "have") {
        setDados({
          ...dados,
          [e.target.name]: e.target.value,
          document_panel_datasheet: "",
          document_inverters_datasheet: "",
          qtd_panel: "",
          qtd_inverters: "",
          panel_model: "",
          inverters_model: "",
        });
      } else if (e.target.value === "not_have") {
        setDados({
          ...dados,
          [e.target.name]: e.target.value,
          document_materials: "",
        });
      }
    } else if (e.target.name === "project_power") {
      if (e.target.value > 1000) {
        return toast.warn("Valor máximo de 1000kWp");
      }

      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "other_value_disjuntor") {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "999"),
      });
    } else if (
      e.target.name === "power_panel" ||
      e.target.name === "power_inverters"
    ) {
      setDados({
        ...dados,
        [e.target.name]: mask(e.target.value, "9999"),
      });
    } else if (e.target.name === "latitude" || e.target.name === "longitude") {
      setDados({
        ...dados,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "state") {
      getConcess(e.target.value);
      setDados({ ...dados, [e.target.name]: e.target.value });
    } else {
      setDados({ ...dados, [e.target.name]: e.target.value });
    }
  };

  const submeterCredito = (e) => {
    setShowbackdrop(true);

    const config = {
      headers: {
        // Authorization: 'Bearer ' + props.user.token,
        "Content-Type": "application/json",
      },
    };

    let aux = {
      company_id: company.id,
      project_power: dados.project_power,
      note: dados.note,
    };

    api
      .post("/receipts/credit", aux, config)
      .then((res) => {
        toast.success("Crédito adicionado ao carrinho com sucesso");
        setShowbackdrop(false);
        getShoppingcart(company.id);
        navigate("/comprovantes");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <Container>
      <Page>
        <header>
          <h1>Novo crédito</h1>
          <span>
            <b>Por que comprar crédio?</b> <br />
            No nosso sistema você acumula pontos que são convertidos em desconto
            a cada projeto contratado em meses consecutivos, e para manter seu
            saldo de pontos acumulados mesmo sem contratar projetos, ofertamos a
            possibilidade de comprar crédito.
            <br />
            <b>Como funciona?</b> <br />
            Você pode comprar créditos que poderão ser usados em projetos
            futuros e ainda contará para o acúmulo de pontos.
          </span>
        </header>
        <content>
          <div
            className="div__multiColumn"
            style={{ margin: "auto", maxWidth: "1100px", flexWrap: "wrap" }}
          >
            <div className="div__multiColumn">
              <img
                src={Image04}
                alt="img_orcamento"
                className="img_orcamento"
              />
            </div>
            <div className="div__oneRow" style={{ flex: 1 }}>
              <form onSubmit={submitInfor}>
                <div style={{ maxWidth: "400px" }}>
                  <div className="div__multiColumn">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        name="project_power"
                        value={dados.project_power}
                        placeholder="Potência total do projeto"
                        style={{
                          flex: 1,
                          width: "100%",
                          // maxWidth: window.innerWidth > 675 && '280px',
                        }}
                        onChange={onInputChange}
                        required
                      />
                      <div className="pos_field">kWp</div>
                    </div>
                  </div>

                  <button type="submit" className="btn__finish">
                    Conferir valor
                  </button>
                </div>
              </form>
            </div>
            {points > 0 && (
              <div className="div__multiColumn" style={{ flex: 1 }}>
                <div className="div__desconto_tag">
                  <p> Desconto acumulado para utilizar em seus créditos!</p>
                  <span>{points}%</span>
                </div>
              </div>
            )}
          </div>
          <br />
        </content>
      </Page>
      {showbackdrop && <Backdrop />}
      {shownovocredito && (
        <Dialogonovocredito
          dados={dados}
          onNote={(a) => setDados({ ...dados, note: a.target.value })}
          confirmar={() => submeterCredito()}
          fechar={() => {
            setShownovocredito(false);
            setDados({ ...dados, note: "" });
          }}
        />
      )}
    </Container>
  );
}

function NewCreditLogged() {
  return <WithAuthenticatedUser render={() => <NewCredit />} />;
}

export default NewCreditLogged;
