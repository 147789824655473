import { ReactNode, useState, useEffect } from 'react';

import { Container } from './styles';

// images e ícones
import Logo1 from '../../../assets/images/logo/logo1.png';
import Logo2 from '../../../assets/images/logo/logo2.png';
import Bg1 from '../../../assets/images/bgs/bg1.png';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// mensagem
import { toast } from 'react-toastify';

// api
import API from '../../../services/api';
import apiIBGE from '../../../services/apiIBGECidades';
import apiCNPJ from '../../../services/apiCNPJ';
import apiCEP from '../../../services/apiCEP';

import { TOKEN_KEY } from '../../../services/auth';
import { Link } from 'react-router-dom';

// maskara
import { mask } from 'remask';

// confirmação
import Dialogoconfirm from './Dialogoconfirm';

import Modalpdf from '../../../components/ModalTermsOfUse';

function Login(props) {
  const [dados, setDados] = useState({
    full_name: '',
    cpf: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    zip_code: '',
    complement: '',
    telephone: '',
    landline: '', //telefone fixo
    email: '',

    fantasy_name: '',
    social_reason: '',
    cnpj: '',
    municipal_registration: '',
    state_registration: '',

    company_street: '',
    company_number: '',
    company_district: '',
    company_city: '',
    company_state: '',
    company_zip_code: '',
    company_complement: '',
    role: '',

    password: '',
    confirm_password: '',
  });

  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    confirm_password: '',
  });
  const [addressDataPerson, setAddressDataPerson] = useState({
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    zip_code: '',
    complement: '',
  });
  const [personData, setPersonData] = useState({
    cpf: '',
    full_name: '',
    role: '',
    is_client: true,
    is_integrator: true,
  });
  const [companyData, setCompanyData] = useState({
    social_reason: '',
    fantasy_name: '',
    cnpj: '',
    municipal_registration: '',
    state_registration: '',
  });
  const [addressDataCompany, setAddressDataCompany] = useState({
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    zip_code: '',
    complement: '',
  });

  const handleChangeLoginData = (e) => {
    if (e.target.name === 'email') {
      setLoginData({
        ...loginData,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    } else {
      setLoginData({
        ...loginData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangePersonData = (e) => {
    if (e.target.name === 'cpf') {
      setPersonData({
        ...personData,
        cpf: mask(e.target.value, '999.999.999-99'),
      });
    } else {
      setPersonData({
        ...personData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeAddressDataPerson = (e) => {
    if ([e.target.name][0] === 'zip_code') {
      setAddressDataPerson({
        ...addressDataPerson,
        zip_code: mask(e.target.value, '99999-999'),
      });
    } else {
      setAddressDataPerson({
        ...addressDataPerson,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeCompanyData = (e) => {
    if (e.target.name === 'cnpj') {
      setCompanyData({
        ...companyData,
        [e.target.name]: mask(e.target.value, '99.999.999/9999-99'),
      });
    } else {
      setCompanyData({
        ...companyData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeAddressDataCompany = (e) => {
    if ([e.target.name][0] === 'zip_code') {
      setAddressDataCompany({
        ...addressDataCompany,
        zip_code: mask(e.target.value, '99999-999'),
      });
    } else {
      setAddressDataCompany({
        ...addressDataCompany,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeHasCompany = () => {
    if (hascompany) {
      setCompanyData({
        ...companyData,
        social_reason: '',
        fantasy_name: '',
        cnpj: '',
        municipal_registration: '',
        state_registration: '',
      });
      setAddressDataCompany({
        ...addressDataCompany,
        street: '',
        number: '',
        district: '',
        city: '',
        state: '',
        zip_code: '',
        complement: '',
      });
      setHascompany(false);
      setFindcnpj(false);
    } else {
      setHascompany(true);
    }
  };

  // MUDANÇAS FIM
  const [listcities, setListcities] = useState([]);
  const [listcitiescompany, setListcitiescompany] = useState([]);
  const [hascompany, setHascompany] = useState(true);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [findcnpj, setFindcnpj] = useState(false);
  const [showdialog, setShowdialog] = useState(false);
  const [show, setShow] = useState(false);
  const [showPDF, setShowPDF] = useState(false);

  const [etapa, setEtapa] = useState(1);

  const getCitiesPerson = async (e) => {
    await apiIBGE
      .get(`${addressDataPerson.state}/distritos`)
      .then((response) => {
        setListcities(
          response.data.sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          })
        );
      })
      .catch((error) => {
        console.log('Erro: ', error);
      });
  };

  const getCitiesCompany = async (e) => {
    await apiIBGE
      .get(`${addressDataCompany.state}/municipios`)
      .then((response) => {
        setListcitiescompany(
          response.data.sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          })
        );
      })
      .catch((error) => {
        console.log('Erro: ', error);
      });
  };

  useEffect(() => {
    if (addressDataPerson.state) {
      getCitiesPerson();
    }
  }, [addressDataPerson.state]);

  useEffect(() => {
    if (addressDataCompany.state) {
      getCitiesCompany();
    }
  }, [addressDataCompany.state]);

  const getCnpjData = async (e) => {
    let aux = window
      .encodeURIComponent(
        e.target.value
          .replace('.', '')
          .replace('.', '')
          .replace('/', '')
          .replace('-', '')
      )
      .replace('%C2%AD', '');
    // console.log(aux);

    if (aux.length > 13) {
      await apiCNPJ
        .get(aux)
        .then((response) => {
          setCompanyData({
            ...companyData,
            fantasy_name: response.data.estabelecimento.nome_fantasia,
            social_reason: response.data.razao_social,
          });

          setAddressDataCompany({
            ...addressDataCompany,
            street: response.data.estabelecimento.logradouro,
            number: response.data.estabelecimento.numero,
            district: response.data.estabelecimento.bairro,
            city: response.data.estabelecimento.cidade.nome,
            state: response.data.estabelecimento.estado.sigla,
            zip_code: mask(response.data.estabelecimento.cep, '99999-999'),
            complement: response.data.estabelecimento.complemento,
          });

          setFindcnpj(true);
        })
        .catch((error) => {
          console.log('Erro: ', error);
          toast.error(
            'Falha ao validar CNPJ. Mas você pode preencher os dados abaixo normalmente'
          );
          setFindcnpj(true);
        });
    }
  };

  const etapavalidacao = (e) => {
    if (etapa === 1) {
      if (personData.full_name) {
        if (personData.cpf && personData.cpf.length === 14) {
          setEtapa(etapa + 1);
        } else {
          return toast.error(
            'Preencha corretamente o seu CPF no campo indicado.'
          );
        }
      } else {
        return toast.error(
          'Preencha corretamente o seu nome no campo indicado.'
        );
      }
    }
    if (etapa === 2) {
      if (
        addressDataPerson.zip_code ||
        addressDataPerson.street ||
        addressDataPerson.number ||
        addressDataPerson.district ||
        addressDataPerson.city ||
        addressDataPerson.state ||
        addressDataPerson.complement
      ) {
        if (addressDataPerson.state) {
          if (addressDataPerson.city) {
            if (addressDataPerson.street) {
              if (addressDataPerson.number) {
                if (addressDataPerson.district) {
                  setEtapa(etapa + 1);
                } else {
                  return toast.error(
                    'Preencha corretamente o bairro no campo indicado.'
                  );
                }
              } else {
                return toast.error(
                  'Preencha corretamente o número no campo indicado.'
                );
              }
            } else {
              return toast.error(
                'Preencha corretamente a sua rua no campo indicado.'
              );
            }
          } else {
            return toast.error(
              'Preencha corretamente a sua cidade no campo indicado.'
            );
          }
        } else {
          return toast.error(
            'Preencha corretamente o seu estado no campo indicado.'
          );
        }
      }
      setEtapa(etapa + 1);
    }
    if (etapa === 3) {
      if (hascompany) {
        if (companyData.cnpj && companyData.cnpj.length === 18) {
          if (companyData.fantasy_name) {
            if (companyData.social_reason) {
              if (addressDataCompany.state) {
                if (addressDataCompany.city) {
                  if (addressDataCompany.street) {
                    if (addressDataCompany.number) {
                      if (addressDataCompany.district) {
                        if (personData.role) {
                          setEtapa(etapa + 1);
                        } else {
                          return toast.error(
                            'Preencha corretamente o seu cargo na empresa no campo indicado.'
                          );
                        }
                      } else {
                        return toast.error(
                          'Preencha corretamente o bairro no campo indicado.'
                        );
                      }
                    } else {
                      return toast.error(
                        'Preencha corretamente o número no campo indicado.'
                      );
                    }
                  } else {
                    return toast.error(
                      'Preencha corretamente a rua no campo indicado.'
                    );
                  }
                } else {
                  return toast.error(
                    'Preencha corretamente a cidade no campo indicado.'
                  );
                }
              } else {
                return toast.error(
                  'Preencha corretamente o estado no campo indicado.'
                );
              }
            } else {
              return toast.error(
                'Preencha corretamente a razão social no campo indicado.'
              );
            }
          } else {
            return toast.error(
              'Preencha corretamente o nome fantasia no campo indicado.'
            );
          }
        } else {
          return toast.error('Preencha corretamente o CNPJ no campo indicado.');
        }
      } else {
        if (companyData.social_reason) {
          if (personData.role) {
            setEtapa(etapa + 1);
          } else {
            return toast.error(
              'Preencha corretamente o seu cargo na empresa no campo indicado.'
            );
          }
        } else {
          return toast.error('Preencha o nome da empresa corretamente.');
        }
        // setEtapa(etapa + 1);
      }
    }
    if (etapa === 4) {
      if (
        loginData.email &&
        (loginData.email.indexOf('@') > -1 ? true : false) &&
        (loginData.email.indexOf('.') > -1 ? true : false)
      ) {
        if (loginData.password) {
          if (loginData.password.length > 5) {
            if (loginData.confirm_password) {
              if (loginData.password === loginData.confirm_password) {
                setShowdialog(true);
              } else {
                return toast.error('Senhas não correspondem');
              }
            } else {
              return toast.error(
                'Confirme sua senha preenchendo corretamente o local indicado'
              );
            }
          } else {
            return toast.error('Senha deve conter no mínimo 6 caracteres');
          }
        } else {
          return toast.error(
            'Preencha corretamente sua senha no campo indicado'
          );
        }
      } else {
        return toast.error(
          'Preencha corretamente o seu e-mail no campo indicado'
        );
      }
    }
    createUserAccount();
  };

  const createUserAccount = async () => {
    let login_data = loginData;
    delete login_data.confirm_password;

    const dadosReq = {
      loginData: login_data,
      addressDataPerson: addressDataPerson.zip_code ? addressDataPerson : null,
      personData,
      companyData,
      addressDataCompany: addressDataCompany.zip_code
        ? addressDataCompany
        : null,
      is_tellar: false,
      is_tellar_admin: false,
    };
  };

  const getZipcode = async (e, type) => {
    if (e.target.value.length <= 8) {
      return;
    }

    await apiCEP
      .get(`/${e.target.value.replace(/\D/g, '')}/json`)
      .then((res) => {
        if (type === 'company') {
          setAddressDataCompany({
            ...addressDataCompany,
            street: res.data.logradouro.toLowerCase(),
            district: res.data.bairro.toLowerCase(),
            city: res.data.localidade.toLowerCase(),
            state: res.data.uf.toUpperCase(),
            complement: res.data.complemento.toLowerCase(),
          });
        } else {
          setAddressDataPerson({
            ...addressDataPerson,
            street: res.data.logradouro.toLowerCase(),
            district: res.data.bairro.toLowerCase(),
            city: res.data.localidade.toLowerCase(),
            state: res.data.uf.toUpperCase(),
            complement: res.data.complemento.toLowerCase(),
          });
        }
      })
      .catch((error) => {
        if (type === 'company') {
          setAddressDataCompany({
            ...addressDataCompany,
            street: '',
            district: '',
            city: '',
            state: '',
            complement: '',
          });
        } else {
          setAddressDataPerson({
            ...addressDataPerson,
            street: '',
            district: '',
            city: '',
            state: '',
            complement: '',
          });
        }
        return toast.warn('CEP inválido!');
      });
  };

  return (
    <Container bg1={Bg1}>
      <div className="div__gradient">
        <div className="div__corpo">
          <div className="div__part1">
            {/* <h3>Seja bem vindo!</h3> */}
            {/* <img src={Logo1} title="Logo Tellar" alt="Logo Tellar" /> */}
            {/* <p>
              Especialista em elaboração e homologação de projeto de energia
              solar fotovoltaica.
            </p> */}
          </div>
          <div className="div__part2">
            <form>
              <img
                src={Logo2}
                title="Logo Tellar"
                className="img__logo_mobile"
                alt=""
              />
              <h1>Cadastro de Usuário</h1>
              <span>
                Preencha corretamente os campos abaixo para efetuar seu cadastro
              </span>
              <div className="div__etapas">
                <span className={etapa === 1 ? 'etp_atual' : 'etp_pass'}>
                  1
                </span>
                <span
                  className={
                    etapa > 2
                      ? 'etp_pass'
                      : etapa === 2
                      ? 'etp_atual'
                      : 'etp_fut'
                  }
                >
                  2
                </span>
                <span
                  className={
                    etapa > 3
                      ? 'etp_pass'
                      : etapa === 3
                      ? 'etp_atual'
                      : 'etp_fut'
                  }
                >
                  3
                </span>
                <span
                  className={
                    etapa > 4
                      ? 'etp_pass'
                      : etapa === 4
                      ? 'etp_atual'
                      : 'etp_fut'
                  }
                >
                  4
                </span>
              </div>
              {etapa === 1 && (
                <div className="div__session1">
                  <h4>Dados Pessoais</h4>
                  <input
                    type="text"
                    placeholder="Nome completo*"
                    autoFocus
                    name="full_name"
                    value={personData.full_name}
                    onChange={handleChangePersonData}
                  />
                  <input
                    type="text"
                    placeholder="CPF*"
                    name="cpf"
                    value={personData.cpf}
                    onChange={handleChangePersonData}
                  />

                  {/* <input
                    type="text"
                    placeholder="Celular/Whatsapp*"
                    name="telephone"
                    value={dados.telephone}
                    required
                    onChange={handleChange}
                  />

                  <input
                    type="text"
                    placeholder="Telefone fixo (opcional)"
                    name="landline"
                    value={dados.landline}
                    onChange={handleChange}
                  /> */}

                  <div className="div__navigators" style={{ margin: 'auto' }}>
                    <button
                      className={
                        etapa === 1
                          ? 'btn__login btn__voltar btn__disable'
                          : 'btn__login btn__voltar'
                      }
                      type="button"
                      onClick={() => setEtapa(etapa - 1)}
                    >
                      Voltar
                    </button>
                    <button
                      className={
                        etapa === 4
                          ? 'btn__login btn__avancar btn__disable'
                          : 'btn__login btn__avancar'
                      }
                      onClick={() => etapavalidacao()}
                      type="button"
                    >
                      Avançar
                    </button>
                  </div>
                </div>
              )}
              {etapa === 2 && (
                <div className="div__session1">
                  <h4>Endereço</h4>

                  <input
                    type="text"
                    placeholder="CEP*"
                    name="zip_code"
                    value={addressDataPerson.zip_code}
                    onChange={handleChangeAddressDataPerson}
                    onKeyUp={(e) => getZipcode(e, 'person')}
                  />

                  <select
                    required
                    name="state"
                    style={{ flex: 1 }}
                    value={addressDataPerson.state}
                    onChange={(e) => {
                      handleChangeAddressDataPerson(e);
                    }}
                    autoFocus
                  >
                    <option value="" disabled selected>
                      Estado*
                    </option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>

                  <select
                    required
                    name="city"
                    style={{ flex: 1 }}
                    value={addressDataPerson.city}
                    onChange={handleChangeAddressDataPerson}
                  >
                    <option value="" disabled selected>
                      Cidade*
                    </option>
                    {addressDataPerson.city && (
                      <option value={addressDataPerson.city} disabled>
                        {addressDataPerson.city}
                      </option>
                    )}
                    {listcities.map((city, idx) => (
                      <option value={city.nome} key={idx}>
                        {city.nome}
                      </option>
                    ))}
                  </select>

                  <input
                    type="text"
                    placeholder="Rua*"
                    name="street"
                    onChange={handleChangeAddressDataPerson}
                    value={addressDataPerson.street}
                  />
                  <input
                    type="text"
                    placeholder="Número*"
                    name="number"
                    value={addressDataPerson.number}
                    onChange={handleChangeAddressDataPerson}
                  />

                  <input
                    type="text"
                    placeholder="Bairro*"
                    name="district"
                    value={addressDataPerson.district}
                    onChange={handleChangeAddressDataPerson}
                  />
                  <input
                    type="text"
                    placeholder="Complemento (opcional)"
                    name="complement"
                    value={addressDataPerson.complement}
                    onChange={handleChangeAddressDataPerson}
                  />

                  <div className="div__navigators" style={{ margin: 'auto' }}>
                    <button
                      className={
                        etapa === 1
                          ? 'btn__login btn__voltar btn__disable'
                          : 'btn__login btn__voltar'
                      }
                      type="button"
                      onClick={() => setEtapa(etapa - 1)}
                    >
                      Voltar
                    </button>
                    <button
                      className={
                        etapa === 4
                          ? 'btn__login btn__avancar btn__disable'
                          : 'btn__login btn__avancar'
                      }
                      onClick={() => etapavalidacao()}
                      type="button"
                    >
                      {addressDataPerson.zip_code ||
                      addressDataPerson.street ||
                      addressDataPerson.number ||
                      addressDataPerson.district ||
                      addressDataPerson.city ||
                      addressDataPerson.state ||
                      addressDataPerson.complement
                        ? 'Avançar'
                        : 'Pular'}
                    </button>
                  </div>
                </div>
              )}

              {etapa === 3 && (
                <div className="div__session1">
                  <h4>Dados da Empresa</h4>
                  <label>
                    <input
                      type="checkbox"
                      checked={!hascompany}
                      onChange={handleChangeHasCompany}
                    />{' '}
                    Não tenho CNPJ
                  </label>
                  {hascompany ? (
                    <>
                      <input
                        type="text"
                        placeholder="CNPJ*"
                        autoFocus
                        name="cnpj"
                        value={companyData.cnpj}
                        onChange={handleChangeCompanyData}
                        onKeyUp={getCnpjData}
                      />
                      <input
                        type="text"
                        disabled={!findcnpj}
                        placeholder="Nome Fantasia*"
                        name="fantasy_name"
                        value={companyData.fantasy_name}
                        onChange={handleChangeCompanyData}
                      />
                      <input
                        type="text"
                        disabled={!findcnpj}
                        placeholder="Razão Social*"
                        name="social_reason"
                        value={companyData.social_reason}
                        onChange={handleChangeCompanyData}
                      />
                      <input
                        type="text"
                        disabled={!findcnpj}
                        placeholder="Registro Municipal (opcional)"
                        name="municipal_registration"
                        value={companyData.municipal_registration}
                        onChange={handleChangeCompanyData}
                      />
                      <input
                        type="text"
                        disabled={!findcnpj}
                        placeholder="Registro Estadual (opcional)"
                        name="state_registration"
                        value={companyData.state_registration}
                        onChange={handleChangeCompanyData}
                      />

                      <input
                        type="text"
                        placeholder="CEP*"
                        name="zip_code"
                        disabled={!findcnpj}
                        value={addressDataCompany.zip_code}
                        onChange={handleChangeAddressDataCompany}
                        onKeyUp={(e) => getZipcode(e, 'company')}
                      />

                      <select
                        required
                        name="state"
                        style={{ flex: 1 }}
                        value={addressDataCompany.state}
                        onChange={handleChangeAddressDataCompany}
                        disabled={!findcnpj}
                      >
                        <option value="" disabled selected>
                          Estado*
                        </option>
                        <option value="AC">AC</option>
                        <option value="AL">AL</option>
                        <option value="AP">AP</option>
                        <option value="AM">AM</option>
                        <option value="BA">BA</option>
                        <option value="CE">CE</option>
                        <option value="DF">DF</option>
                        <option value="ES">ES</option>
                        <option value="GO">GO</option>
                        <option value="MA">MA</option>
                        <option value="MT">MT</option>
                        <option value="MS">MS</option>
                        <option value="MG">MG</option>
                        <option value="PA">PA</option>
                        <option value="PB">PB</option>
                        <option value="PR">PR</option>
                        <option value="PE">PE</option>
                        <option value="PI">PI</option>
                        <option value="RJ">RJ</option>
                        <option value="RN">RN</option>
                        <option value="RS">RS</option>
                        <option value="RO">RO</option>
                        <option value="RR">RR</option>
                        <option value="SC">SC</option>
                        <option value="SP">SP</option>
                        <option value="SE">SE</option>
                        <option value="TO">TO</option>
                      </select>
                      <select
                        required
                        name="city"
                        disabled={!findcnpj}
                        style={{ flex: 1 }}
                        value={addressDataCompany.city}
                        onChange={handleChangeAddressDataCompany}
                      >
                        <option value="" disabled selected>
                          Cidade*
                        </option>
                        {addressDataCompany.city && (
                          <option value={addressDataCompany.city} disabled>
                            {addressDataCompany.city.toUpperCase()}
                          </option>
                        )}
                        {listcitiescompany.map((city, idx) => (
                          <option value={city.nome} key={idx}>
                            {city.nome}
                          </option>
                        ))}
                      </select>

                      <input
                        type="text"
                        disabled={!findcnpj}
                        placeholder="Rua*"
                        name="street"
                        value={addressDataCompany.street}
                        onChange={handleChangeAddressDataCompany}
                      />
                      <input
                        type="text"
                        disabled={!findcnpj}
                        placeholder="Número*"
                        name="number"
                        value={addressDataCompany.number}
                        onChange={handleChangeAddressDataCompany}
                      />
                      <input
                        type="text"
                        disabled={!findcnpj}
                        placeholder="Bairro*"
                        name="district"
                        value={addressDataCompany.district}
                        onChange={handleChangeAddressDataCompany}
                      />

                      <input
                        type="text"
                        disabled={!findcnpj}
                        placeholder="Complemento (opcional)"
                        name="complement"
                        value={addressDataCompany.complement}
                        onChange={handleChangeAddressDataCompany}
                      />
                      <input
                        type="text"
                        disabled={!findcnpj}
                        placeholder="Cargo na empresa*"
                        name="role"
                        value={personData.role}
                        onChange={handleChangePersonData}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        type="text"
                        placeholder="Nome da empresa"
                        name="social_reason"
                        value={companyData.social_reason}
                        onChange={handleChangeCompanyData}
                      />{' '}
                      <input
                        type="text"
                        placeholder="Cargo na empresa*"
                        name="role"
                        value={personData.role}
                        onChange={handleChangePersonData}
                      />
                    </>
                  )}

                  <div className="div__navigators" style={{ margin: 'auto' }}>
                    <button
                      className={
                        etapa === 1
                          ? 'btn__login btn__voltar btn__disable'
                          : 'btn__login btn__voltar'
                      }
                      type="button"
                      onClick={() => setEtapa(etapa - 1)}
                    >
                      Voltar
                    </button>
                    <button
                      className={
                        etapa === 4
                          ? 'btn__login btn__avancar btn__disable'
                          : 'btn__login btn__avancar'
                      }
                      onClick={() => etapavalidacao()}
                      type="button"
                    >
                      Avançar
                    </button>
                  </div>
                </div>
              )}

              {etapa === 4 && (
                <div className="div__session1">
                  <h4>Informações de acesso</h4>

                  <input
                    type="email"
                    placeholder="E-mail*"
                    autoFocus
                    name="email"
                    value={loginData.email}
                    onChange={handleChangeLoginData}
                  />

                  <div className="div__one_row">
                    <input
                      autoFocus
                      type={show ? 'text' : 'password'}
                      placeholder="Senha*"
                      name="password"
                      value={loginData.password}
                      onChange={handleChangeLoginData}
                    />{' '}
                    <button onClick={() => setShow(!show)} type="button">
                      {!show ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </button>
                  </div>

                  <input
                    type="password"
                    placeholder="Confirmar senha*"
                    name="confirm_password"
                    value={loginData.confirm_password}
                    onChange={handleChangeLoginData}
                  />

                  <ul>
                    <li>Senha deve conter no mínimo 6 caracteres;</li>
                    <li>
                      Para uma senha forte, é aconselhável que contenha números,
                      letras e caracteres especiais;
                    </li>
                    <li>
                      Sua senha e credenciais de acesso são de uso pessoal,
                      evite informar a terceiros.
                    </li>
                  </ul>

                  <div style={{ display: 'flex', fontSize: '0.8rem' }}>
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      checked={confirmTerms}
                      onChange={() => {
                        setConfirmTerms((confirm) => !confirm);
                        // console.log(confirmTerms);
                      }}
                      style={{
                        width: '1.2rem',
                        margin: 0,
                        marginLeft: '2rem',
                      }}
                    />
                    <span
                      htmlFor="terms"
                      style={{
                        marginBottom: '0',
                        paddingLeft: '1rem',
                        textAlign: 'left',
                      }}
                    >
                      DECLARO QUE LI E CONCORDO COM AS{' '}
                      <a
                        // href="../../../termsofuse/terms.pdf"
                        style={{
                          textDecoration: 'underline',
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          //   window.open(
                          //     'https://drive.google.com/file/d/1WGpvcydTO1pdCynvkssDC1sLSmZ2JB4Z/view?usp=sharing',
                          //     // 'http://localhost:3000/termsofuse/terms.pdf',
                          //     // '../../../assets/logo/logo1.png',
                          //   )
                          setShowPDF(true)
                        }
                      >
                        POLÍTICAS DE PRIVACIDADE
                      </a>
                    </span>
                  </div>

                  <div className="div__navigators" style={{ margin: 'auto' }}>
                    <button
                      className={
                        etapa === 1
                          ? 'btn__login btn__voltar btn__disable'
                          : 'btn__login btn__voltar'
                      }
                      type="button"
                      onClick={() => setEtapa(etapa - 1)}
                    >
                      Voltar
                    </button>
                    <button
                      className={
                        etapa === 4 && confirmTerms
                          ? 'btn__login btn__avancar'
                          : 'btn__login btn__avancar btn__disable'
                      }
                      onClick={() => etapavalidacao()}
                      type="button"
                      // disabled={!confirmTerms}
                    >
                      Finalizar
                    </button>
                  </div>
                </div>
              )}

              <div className="div__line" />
              <div className="div__subtext">
                <label>Já possui conta? Realize login</label>
              </div>
              <Link to="/login" style={{ width: '100%' }}>
                <button className="btn__register" type="button">
                  Login
                </button>
              </Link>
            </form>
          </div>
        </div>
      </div>
      {showdialog && (
        <Dialogoconfirm
          loginData={loginData}
          addressDataPerson={addressDataPerson}
          personData={personData}
          companyData={companyData}
          addressDataCompany={addressDataCompany}
          fechar={() => setShowdialog(false)}
        />
      )}

      {showPDF && <Modalpdf fechar={() => setShowPDF(false)} />}
    </Container>
  );
}

export default Login;
