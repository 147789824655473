import React, { useState } from 'react';

import { Container } from './styles';

import { Outlet } from 'react-router-dom';

import Menu from '../../components/Menu';
import Header from '../../components/Header';
import FloatingButton from '../../components/FloatingButton';

function Layout() {
  const [open, setOpen] = useState(true);

  const handleChangeMenu = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <Menu open={open} handleChangeMenu={handleChangeMenu} />
      <div className="conteudo">
        <Header open={open} />
        <Outlet />
      </div>
      <FloatingButton />
    </Container>
  );
}

export { Layout };
