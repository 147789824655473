import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
  padding-right: 2rem;
`;

export const Page = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  min-height: 200px;

  content {
    .div__final_price {
      display: flex;
      align-items: center;
      svg {
        font-size: 1.2rem;
        cursor: pointer;
        color: var(--toastify-color-info);
      }
    }

    .div__viewMore {
      border: 1px solid rgba(var(--black_rgb), 0.1);
      text-align: center;
      padding: 1rem;
      margin-top: 1rem;
      border-radius: 0rem 0rem 0.5rem 0.5rem;
      cursor: pointer;
      font-weight: 500;
      color: rgba(var(--black_rgb), 0.4);

      :hover {
        box-shadow: 0px -10px 20px rgba(var(--black_rgb), 0.1);
      }
    }

    .div__is_paid {
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        border-radius: 1rem;
        padding: 0.2rem 0.5rem;
        text-align: center;
        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
        font-size: 0.7rem;
        svg {
          margin: auto;
          font-size: 1.5rem;
        }
        .icon__cart {
          font-size: 1.3rem;
        }
      }
      .is_paid {
        background: lightgreen;
        color: green;
      }
      .is_waiting {
        background: lightyellow;
        color: brown;
      }
      .is_pending {
        color: white;
        background-color: rgba(255, 100, 0, 0.7);
      }
      .is_refused {
        color: red;
        background-color: pink;
      }
    }

    .div__field_actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0rem 2rem;
    }

    .div__fieldFilter {
      margin: 0rem 2rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      .div__filters {
        display: flex;
        justify-content: flex-start;
        margin: auto 0px;
        width: 100%;
        input,
        select {
          outline-color: var(--primary2);
          padding: 0.75rem 0.5rem;
          border: 1px solid rgba(var(--black_rgb), 0.3);
          border-radius: 0.5rem;
          margin: 0rem 0.25rem;
          width: 100%;
          /* margin: auto; */
          max-width: 350px;
          z-index: 0;
        }
      }

      .div__order {
        /* background: red; */
        margin: auto;
        display: flex;
        button {
          border: 1px solid rgba(var(--black_rgb), 0.2);
          padding: 0.2rem 0.5rem;
          color: var(--primary2);
          cursor: pointer;

          :hover {
            background-color: var(--primary2) !important;
            color: var(--white);
          }
        }
        .btn__esq {
          background-color: transparent !important;
          border-radius: 0.5rem 0rem 0rem 0.5rem;
        }
        .btn__dir {
          background-color: transparent !important;
          border-radius: 0rem 0.5rem 0.5rem 0rem;
          border-left: 0px;
        }

        .btn__ativ {
          background-color: var(--primary2) !important;
          color: var(--white);
        }
        .btn_meio {
          background: var(--white);
          border-left: 0px;
        }
      }
    }
  }

  header {
    padding: 1rem 2rem;
    text-align: left;
    font-family: 'Poppins', sans-serif;

    h1 {
      font-weight: 800;
      font-size: 1.5rem;
      color: var(--primary2);
      text-transform: uppercase;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      opacity: 1;
    }
    span {
      opacity: 0.9;
    }

    ::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-top: 1rem;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    }
  }

  .containerButton {
    margin: 0rem 2rem;
    margin-bottom: 2rem;
    /* background: red; */

    .div__row {
      display: flex;
      flex-direction: row;

      strong {
        margin-right: 0.5rem;
      }
      small {
        margin: 0rem 0.5rem;
      }
    }

    h4 {
      /* background-color: red; */
      text-transform: uppercase;
      margin: 1rem 0rem;
      font-size: 0.8rem;
      color: rgba(var(--black_rgb), 0.5);
      font-weight: 400;
      width: 100%;
      /* padding: 0rem 2rem; */
      flex: 1;
      /* margin-left: 0rem; */
      text-align: left;

      ::after {
        display: block;
        content: '';
        margin: 0.5rem 0rem;
        border-bottom: 1px dotted rgba(var(--black_rgb), 0.5);
      }
    }

    p {
      flex: 1;
      margin: 0rem;
      font-size: 0.9rem;
    }
    label {
      font-weight: 600;
    }
    .div__oneRow {
      display: flex;
      text-align: left;
      justify-content: space-between;
      /* background: red; */
    }

    .btn__finish {
      background-image: linear-gradient(
        to bottom right,
        var(--primary2),
        var(--primary)
      );
      padding: 0.5rem 2rem;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      border: 0rem;
      font-size: 0.8rem;
      float: left;
      margin: 1rem 0rem;
      cursor: pointer;
      border-radius: 2rem;

      :hover {
        background-image: linear-gradient(
          to bottom left,
          var(--primary2),
          var(--primary)
        );
      }
    }
  }
  @media screen and (max-width: 675px) {
    .div__oneRow {
      display: flex;
      flex-direction: column;
    }
    .div__deck {
      max-width: 100% !important;
      overflow: scroll !important;
    }
    .div__fieldFilter {
      flex-direction: column;
      justify-content: center !important;
      align-items: center !important;
    }
    .div__filters {
      margin: auto;
      flex-direction: column;

      input,
      select {
        font-size: 1.2rem;
        width: 200px !important;
        max-width: 600px !important;
        margin: 0.25rem auto !important;
      }
    }
  }

  .div__deck {
    .head_list {
      display: flex;
      margin: 0.1rem 1rem;
      padding: 0.5rem 1rem;
      justify-content: space-between;
      gap: 1rem;
      font-size: 0.8rem;
      align-items: flex-end;
      div {
        color: rgba(var(--black_rgb), 0.5);
        text-align: left;
        flex: 1;
        font-weight: 600;
      }
    }
  }
`;

export const Card = styled.div`
  display: flex;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  margin: 0.1rem 1rem;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  font-size: 0.8rem;

  div {
    flex: 1;
    text-align: left;
  }

  .div__password {
    font-size: 12px;
  }

  .div__options {
    display: flex;
    gap: 0.5rem;
    button {
      padding: 0.5rem;
    }
    .btn_delete {
      :hover {
        background: var(--delete);
        color: var(--white);
      }
    }
  }

  @media screen and (max-width: 675px) {
    min-width: 650px;
  }
`;
