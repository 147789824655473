import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mask } from 'remask';

import { toast } from 'react-toastify';

import { Container, FielButtons, CardProjects } from './styles';

import Backdrop from '../Backdrop';

// imagem
import Money01 from '../../assets/images/prints/money_02.png';

// api
import API from '../../services/api';
// contexto;
import { useUserData } from '../../context/UserDataContext';

// icones
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DoneAllIcon from '@material-ui/icons/DoneAll';

// modal payment
import Dialogoshoppingcartpayment from '../Dialogoshoppingcartpayment';
import Dialogocompletardados from '../Dialogocompletardados';

export default function Dialogoshoppingcart(props) {
  let { points, company } = useUserData();
  const [open, setOpen] = React.useState(true);
  const [dados, setDados] = React.useState(props.dados);
  const [totalProjectPrice, setTotalProjectPrice] = React.useState(0);
  const [showbackdrop, setShowbackdrop] = useState(false);
  const [descontos, setDescontos] = React.useState({
    pontos: 0,
    cupons: 0,
  });
  const [showPaymentMethods, setShowPaymentMethods] = React.useState(false);

  const [projectsAwaitingPayment, setProjectsAwaitingPayment] = React.useState(
    props.listshopping
  );
  const [selectedProjects, setSelectedProjects] = React.useState([]);

  const [cupons, setCupons] = React.useState({
    valido: false,
    show: false,
    message: '',
    code: '',
    percentage: '',
  });

  const [credits, setCredits] = React.useState({
    value: props.credits ? props.credits : 0,
    show: props.credits ? false : true,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => props.fechar(), 100);
  };

  const getValuePrice = (power) => {
    // let power = dados.total_project_power;
    let price = 0;

    if (power > 0 && power <= 5) {
      price = 750.0;
    } else if (power > 5 && power <= 10) {
      price = 900.0;
    } else if (power > 10 && power <= 20) {
      price = 1200.0;
    } else if (power > 20 && power <= 40) {
      price = 1500.0;
    } else if (power > 40 && power <= 75) {
      price = 2000.0;
    } else if (power > 75 && power <= 100) {
      price = power * 40;
    } else if (power > 100 && power <= 200) {
      price = power * 37;
    } else if (power > 200 && power <= 300) {
      price = power * 33;
    } else if (power > 300 && power <= 400) {
      price = power * 28;
    } else if (power > 400 && power <= 500) {
      price = power * 25;
    } else if (power > 500 && power <= 1000) {
      price = power * 24;
    }

    return price;
  };

  const validarCupom = async (cupom) => {
    setShowbackdrop(true);
    if (!cupom) {
      setShowbackdrop(false);
      return true;
    }
    await API.get(`/coupons/check/${cupom}`)
      .then((res) => {
        setCupons({
          ...cupons,
          code: res.data.code,
          show: true,
          valido: true,
          message: '',
          percentage: Number(res.data.percentage),
        });
      })
      .catch((error) => {
        console.log('Error: ', error.response);

        setCupons({
          ...cupons,
          show: true,
          valido: false,
          message: error?.response?.data?.message,
          percentage: 0,
        });

        setShowbackdrop(false);
      })
      .finally(() => setShowbackdrop(false));
  };

  const currency = function (number) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(number);
  };

  // select and deselect project
  const handleSelectProject = (project_id) => {
    setTotalProjectPrice(
      totalProjectPrice +
        Number(
          projectsAwaitingPayment.find((proj) => proj.id === project_id)
            .original_price
        )
    );
    let aux = selectedProjects;
    aux.push(project_id);
    setSelectedProjects([...aux]);
  };
  const handleDeselectProject = (project_id) => {
    setTotalProjectPrice(
      totalProjectPrice -
        Number(
          projectsAwaitingPayment.find((proj) => proj.id === project_id)
            .original_price
        )
    );
    let aux = selectedProjects;
    aux.splice(aux.indexOf(project_id), 1);
    setSelectedProjects([...aux]);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="body"
        fullScreen={window.innerWidth > 675 ? false : true}
        fullWidth
        style={{
          zIndex: 99999,
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Container>
              <header>
                <h1>
                  <ShoppingCartIcon /> Carrinho de projetos
                </h1>
                <span>
                  Verifique atentamente seus projetos solicitados e selecione os
                  quais deseja prosseguir para o pagamento.
                </span>
                <content>
                  <h4>Meus projetos aguardando pagamento</h4>
                  <div className="div__oneRow">
                    {/* <p style={{ display: 'flex', flexDirection: 'column' }}>
                      <img src={Money01} alt="Money Image" />
                      <p
                        style={{
                          margin:
                            window.innerWidth > 675 ? '0rem 1rem' : '0rem',
                        }}
                      >
                        Acumule 1% de desconto a cada projeto realizado com
                        nossa empresa. Além de 1%, também, a cada mês
                        consecutivo de projetos submetidos. Não fique de fora
                        dessa!
                      </p>
                    </p> */}
                    <div style={{ flex: 1, flexDirection: 'column' }}>
                      <small>
                        Selecione os projetos abaixo: <br /> <br />
                      </small>
                      <div className="div__deck">
                        {projectsAwaitingPayment
                          .sort(
                            (a, b) =>
                              new Date(b.created_at) - new Date(a.created_at)
                          )
                          .map((project, idx) => (
                            <CardProjects
                              selected={selectedProjects.includes(project.id)}
                            >
                              <label htmlFor={idx}>
                                <div className="div__oneRow">
                                  <div
                                    className="div__oneColumn"
                                    style={{ flex: 1 }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={idx}
                                      name="selected_project"
                                      checked={selectedProjects.includes(
                                        project.id
                                      )}
                                      onChange={() => {
                                        if (
                                          selectedProjects.includes(project.id)
                                        ) {
                                          handleDeselectProject(project.id);
                                        } else {
                                          handleSelectProject(project.id);
                                        }
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="div__oneColumn"
                                    style={{ flex: 10 }}
                                  >
                                    {project?.project ? (
                                      <h4>
                                        Projeto Fotovoltáico{' '}
                                        {project?.project?.total_project_power}{' '}
                                        kWp
                                      </h4>
                                    ) : (
                                      <h4>Crédito</h4>
                                    )}
                                    <div>
                                      <div className="div__text_line">
                                        {project?.project?.client_name}
                                      </div>
                                      <div className="div__text_line">
                                        {project.project &&
                                          (project?.project?.client_cpf
                                            ? 'CPF:'
                                            : 'CNPJ:')}{' '}
                                        {project?.project &&
                                        project?.project?.client_cpf
                                          ? project?.project?.client_cpf
                                          : project?.project?.client_cnpj}
                                      </div>

                                      {project?.project && (
                                        <div className="div__text_line">
                                          {project?.project?.dealer?.name} -{' '}
                                          {project?.project?.dealer?.state}
                                        </div>
                                      )}
                                      <div className="div__created_at">
                                        Solicitado em{' '}
                                        {new Date(
                                          project.created_at
                                        ).toLocaleString('pt-BR', {
                                          day: '2-digit',
                                          month: 'long',
                                          year: 'numeric',
                                        })}
                                      </div>
                                      <div className="div__price_line">
                                        Valor:{' '}
                                        <b>
                                          {currency(project.original_price)}
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="div__oneColumn selectedIcon">
                                    <DoneAllIcon />
                                  </div>
                                </div>
                              </label>
                            </CardProjects>
                          ))}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        flexDirection: 'column',
                        padding: '0.5rem 1.25rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div className="div__oneColumn">
                        <div
                          className="div__table_price"
                          style={{ marginTop: '1rem' }}
                        >
                          <b>Item</b>
                          <b>Preço</b>
                        </div>

                        {selectedProjects.length === 0 ? (
                          <div style={{ textAlign: 'center', width: '100%' }}>
                            <small>Selecione o(s) projeto(s)</small>
                          </div>
                        ) : (
                          selectedProjects.map((id_project, idx) =>
                            projectsAwaitingPayment.find(
                              (proj) => proj.id === id_project
                            ).project ? (
                              <div className="div__table_price">
                                <span>
                                  - Projeto fotovoltáico{' '}
                                  {
                                    projectsAwaitingPayment.find(
                                      (proj) => proj.id === id_project
                                    ).project.total_project_power
                                  }{' '}
                                  kWp
                                </span>
                                <span>
                                  {currency(
                                    Number(
                                      projectsAwaitingPayment.find(
                                        (proj) => proj.id === id_project
                                      ).original_price
                                    )
                                  )}
                                </span>
                              </div>
                            ) : (
                              <div className="div__table_price">
                                <span>- Crédito</span>
                                <span>
                                  {currency(
                                    projectsAwaitingPayment.find(
                                      (proj) => proj.id === id_project
                                    ).original_price
                                  )}
                                </span>
                              </div>
                            )
                          )
                        )}

                        {credits?.value > 0 && credits?.show === false && (
                          <div className="div__table_price">
                            <span>- Créditos </span>
                            <span>-{currency(credits?.value)}</span>
                          </div>
                        )}

                        {points > 0 && (
                          <div className="div__table_price">
                            <span>- Desconto ({points}%)</span>
                            <span>
                              -
                              {currency(
                                (totalProjectPrice -
                                  (credits?.show === false && credits?.value)) *
                                  (points / 100)
                              )}
                            </span>
                          </div>
                        )}
                        {cupons.percentage > 0 && (
                          <div className="div__table_price">
                            <span>
                              - Cupom (
                              {cupons.code + ' ' + cupons.percentage + '%'})
                            </span>
                            <span>
                              -
                              {currency(
                                (cupons.percentage *
                                  (totalProjectPrice -
                                    (points *
                                      (totalProjectPrice -
                                        (credits?.show === false
                                          ? parseFloat(credits?.value)
                                          : 0))) /
                                      100 -
                                    (credits?.show === false &&
                                      credits?.value))) /
                                  100
                              )}
                            </span>
                          </div>
                        )}
                        <div className="div__table_price">
                          <div className="div__table_sub_total">
                            Total:{' '}
                            <b>
                              {currency(
                                totalProjectPrice -
                                  (credits?.show === false
                                    ? parseFloat(credits.value)
                                    : 0) -
                                  (cupons?.percentage *
                                    (totalProjectPrice -
                                      (points *
                                        (totalProjectPrice -
                                          (credits?.show === false
                                            ? parseFloat(credits?.value)
                                            : 0))) /
                                        100 -
                                      (credits?.show === false
                                        ? parseFloat(credits?.value)
                                        : 0))) /
                                    100 -
                                  (points *
                                    (totalProjectPrice -
                                      (credits?.show === false
                                        ? parseFloat(credits?.value)
                                        : 0))) /
                                    100
                              )}
                            </b>
                          </div>
                        </div>
                      </div>

                      {/* ----------------------  */}

                      <div className="div__oneColumn">
                        <div className="field__cupom">
                          <label htmlFor="text_cupom">Cupom:</label>
                          <input
                            type="text"
                            id="text_cupom"
                            name="text_cupom"
                            value={cupons?.code}
                            disabled={cupons?.show ? true : false}
                            onChange={(event) =>
                              setCupons({
                                ...cupons,
                                code: event?.target?.value?.toUpperCase(),
                              })
                            }
                          />
                          <buttom
                            type="buttom"
                            className="btn__inserir"
                            onClick={() =>
                              cupons?.show
                                ? setCupons({
                                    ...cupons,
                                    valido: false,
                                    show: false,
                                    message: false,
                                    code: '',
                                    percentage: false,
                                  })
                                : validarCupom(cupons?.code)
                            }
                          >
                            {cupons?.show ? 'Remover' : 'Inserir'}
                          </buttom>
                        </div>
                        {cupons?.show && !cupons?.valido && (
                          <span style={{ margin: ' 0rem 1rem' }}>
                            {cupons?.message}
                          </span>
                        )}
                        <div
                          className="field__cupom"
                          style={{ marginTop: '0.5rem' }}
                        >
                          <label htmlFor="number_credits">Créditos:</label>
                          <input
                            type="number"
                            step="0.01"
                            min="0"
                            id="number_credits"
                            name="number_credits"
                            value={credits?.value}
                            placeholder="Insira o valor que deseja utilizar"
                            disabled={!credits?.show ? true : false}
                            onChange={(event) => {
                              let aux = event?.target?.value;
                              if (!event?.target?.value) {
                                setCredits({
                                  ...credits,
                                  value: 0,
                                });

                                return;
                              }
                              if (aux >= 0) {
                                if (company?.credits !== 0) {
                                  if (
                                    parseFloat(aux) <=
                                    parseFloat(company?.credits)
                                  ) {
                                    if (aux <= totalProjectPrice) {
                                      setCredits({
                                        ...credits,
                                        value: event?.target?.value,
                                      });
                                    } else {
                                      toast.warn(
                                        `Valor máximo do projeto é  ${currency(
                                          totalProjectPrice
                                        )}.`
                                      );
                                    }
                                  } else {
                                    toast.warn(
                                      `Seu limite de crédito é ${currency(
                                        company?.credits
                                      )}.`
                                    );
                                  }
                                } else {
                                  toast.warn(
                                    'Você não possui créditos para utilizar no momento.'
                                  );
                                }
                              } else {
                                toast.warn(
                                  'Valor deve ser maior ou igual a 0.'
                                );
                              }
                            }}
                          />
                          <buttom
                            type="buttom"
                            className="btn__inserir"
                            onClick={() => {
                              if (credits.show) {
                                setCredits({
                                  ...credits,
                                  value: credits?.value,
                                });
                                setCredits({ ...credits, show: false });
                              } else {
                                setCredits({
                                  ...credits,
                                  value: 0,
                                  show: true,
                                });
                              }
                            }}
                          >
                            {!credits.show ? 'Remover' : 'Inserir'}
                          </buttom>
                        </div>
                        <span style={{ margin: ' 0rem 1rem' }}>
                          Você tem {currency(company?.credits)} em créditos
                          disponíveis.
                        </span>
                        <br />
                        <br />
                        <span>
                          - Para dois ou mais projetos/créditos, ao serem
                          utilizados, os créditos serão distribuidos
                          proporcionalmente.
                        </span>
                      </div>
                    </div>
                  </div>
                </content>
              </header>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FielButtons>
            <button
              onClick={handleClose}
              className="btn__retornar"
              color="primary"
            >
              Cancelar
            </button>
            <button
              onClick={() => setShowPaymentMethods(true)}
              className="btn__register"
              disabled={!totalProjectPrice}
            >
              Seguir para pagamento
            </button>
          </FielButtons>
        </DialogActions>

        {showbackdrop && <Backdrop />}
        {showPaymentMethods && (
          <Dialogoshoppingcartpayment
            setClose={() => setShowPaymentMethods(false)}
            data={{
              cupons: cupons,
              points: points,
              credits: credits,
              selectedProjects: selectedProjects,
              projectsAwaitingPayment: projectsAwaitingPayment,
              totalProjectPrice: totalProjectPrice,
            }}
          />
        )}
      </Dialog>
    </div>
  );
}
